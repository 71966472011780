import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { from, Observable, of, zip } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { AppPages } from "../shared/models/app.model";
import { RouterStates } from "../shared/consts";
import { StorageService } from "../shared/services/storage.service";
import { AuthService } from "../shared/services/auth.service";
import { UserService } from "../shared/services/user.service";
import { User } from "../shared/models/user.model";

@Injectable({
  providedIn: "root",
})
export class AuthLocalGuard  {
  routerStates = RouterStates;
  constructor(
    private router: Router,
    private storageService: StorageService,
    private auth: AuthService,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (route.params && route.params["promo-code"]) {
      this.storageService.promoCodeSubject.next(route.params["promo-code"]);
      this.storageService.set("promoCode", route.params["promo-code"]);
    }

    const checkFirebaseUser$ = this.auth.getFireAuthUser();
    const user$ = from(this.storageService.get("user") as Promise<User>);

    return zip([checkFirebaseUser$, user$]).pipe(
      switchMap(([firebaseUser, userData]) => {
        if (
          !firebaseUser ||
          !firebaseUser.uid ||
          !userData ||
          !userData.userState
        ) {
          const userState = {
            page: AppPages.onboardingIntro,
            status: true,
          };

          this.storageService.set("user", {
            userState,
            promoCode: route.params["promo-code"] || "",
          });

          const isIntroPage = state.url.includes("onboarding-intro");
          if (isIntroPage) {
            return of(true);
          }

          const redirectTo = this.getRouterState(AppPages.onboardingIntro);
          this.router.navigate([`/${redirectTo}`], {
            queryParams: route.queryParams,
          });

          return of(false);
        }

        const url = state.url.split("?")[0];

        return this.userService.getUserState().pipe(
          map((userState) => {
            const targetRouterState = this.getRouterState(userState.page);
            if (url === `/${targetRouterState}`) {
              return true;
            } else {
              this.router.navigate([`/${targetRouterState}`], {
                queryParams: route.queryParams,
              });
              return false;
            }
          }),
          catchError(() => of(false))
        );
      })
    );
  }

  getRouterState(page: AppPages): string {
    return this.routerStates.get(page).redirectTo;
  }
}
