import { Environment } from "./environment.type";

export const environment: Environment = {
  production: false,
  env: "staging",
  firebaseConfig: {
    apiKey: "AIzaSyCshvNyMhpj7nz5fZXcNAQm18gZ5KnlYnQ",
    authDomain: "ewa-services.firebaseapp.com",
    projectId: "ewa-services",
    storageBucket: "ewa-services.appspot.com",
    messagingSenderId: "710191514957",
    appId: "1:710191514957:web:cf86980f5e24c34d04a304",
    measurementId: "G-7END3QV6S2",
    databaseURL: "https://ewa-services.firebaseio.com",
  },
  requestUrl: "https://firebase-api-dev.finn-app.com",
  defaultLang: "en",
  experimentID: "7DztyP9qROy8QwxsJdulQQ",
  tagManagerId: "GTM-KPWXZ3F",
  domain: "app-dev.finn-app.com",
  translationHash: new Date().toISOString().replace(/\.|:|-/g, ""),
  vwo_id_verification_campaign: "2",
  androidAppLink: "https://play.google.com/store/apps/details?id=com.finn.app",
  dsn: "",
  bpiURL:
    "https://oauth-uat.bpi.com.ph/bpi/api/oauthWithOtp/v7/authorize?response_type=code&client_id=8c4232fba80bddc7a58557dd4a73f75e&redirect_uri=https://app-dev.finn-app.com/link-bank-account/link-account-waiting&scope=transactionalAccountsforRecurringDebits%20recurringDebits",
  minimumFetchIntervalMillis: 18000,
  fetchTimeoutMillis: 60000,
  preloadableDomains: ["https://d.finn-app.com", "https://tr.line.me"],
};
