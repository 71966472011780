import { Component, Input } from "@angular/core";

@Component({
  selector: "app-transaction-status-icon",
  templateUrl: "./transaction-status-icon.component.html",
  styleUrls: ["./transaction-status-icon.component.scss"],
})
export class TransactionStatusIconComponent {
  @Input() status: string;

  constructor() {}
}
