import { Injectable } from "@angular/core";
import { from, map, of, switchMap, take, tap } from "rxjs";

import { GTM_EVENT_NAMES, GTM_FUNNEL_VERSION } from "../consts";
import { StorageService } from "./storage.service";
import { LoginMethod } from "../models/login.model";
import { AuthService } from "./auth.service";
import { DateService } from "./date.service";

declare const dataLayer: any;

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(
    private readonly storageService: StorageService,
    private readonly authService: AuthService,
    private readonly dateService: DateService
  ) {}

  trackVariable(variable: any): void {
    dataLayer.push(variable);
  }

  trackStep(
    step: number,
    eventName: string,
    stepName: string,
    funnel_version: number = GTM_FUNNEL_VERSION
  ) {
    dataLayer.push({
      event: eventName,
      step,
      step_name: stepName,
      funnel_version,
    });
  }

  /**
   * Tracks the user's session activity by triggering an event whenever the user logs in or returns to the app after being away.
   * The event is triggered if the user logs in or if they revisit the app after 30 minutes have passed since the last session update.
   *
   * @param {LoginMethod} [logInMethod] - Optional identifier for the login method used, included in tracking data if available.
   *
   * @remarks
   * This function:
   * - Checks if the user is logged in with Firebase authentication.
   * - Retrieves the last session update timestamp from storage.
   * - Triggers an event if more than 30 minutes have elapsed since the last recorded session update or if this is a fresh login.
   * - Updates the session timestamp in storage when the event is triggered.
   *
   *
   * @returns {void}
   */
  trackSessionUpdate(logInMethod?: LoginMethod) {
    this.authService
      .getFireAuthUser()
      .pipe(
        take(1),
        map((firebaseUser) => !!firebaseUser?.uid),
        switchMap((isLoggedIn) => {
          if (!isLoggedIn) {
            return of(null);
          }

          return from(this.storageService.get("lastSessionUpdateDate")).pipe(
            map(
              (lastSessionDate) =>
                !lastSessionDate ||
                this.dateService.hasElapsedMinutes(lastSessionDate, 30)
            )
          );
        }),
        switchMap((shouldUpdate) => {
          if (!shouldUpdate) {
            return of(null);
          }

          return from(
            this.storageService.set(
              "lastSessionUpdateDate",
              new Date().getTime()
            )
          ).pipe(
            tap(() => {
              this.trackVariable({
                event: GTM_EVENT_NAMES.userLoggedIn,
                ...(logInMethod && { login_method: logInMethod }),
              });
            })
          );
        })
      )
      .subscribe();
  }
}
