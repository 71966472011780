import { Component, Inject, Renderer2, OnInit, OnDestroy } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
  Event,
} from "@angular/router";
import { AlertController, Platform } from "@ionic/angular";
import { register } from "swiper/element/bundle";

import { FirebaseDynamicLinks } from "@pantrist/capacitor-firebase-dynamic-links";
import { TranslateService } from "@ngx-translate/core";

import { from, interval, Observable, of, Subscription } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  takeWhile,
  tap,
} from "rxjs/operators";

import {
  CHAT_ALLOWED_PATHS,
  CHAT_ALLOWED_PATHS_BY_COUNTRY,
  CHAT_BUBBLE_OVERLAP_ADJUSTED_PATHS,
  CountryChatMap,
  RouterStates,
} from "./shared/consts";

import { OnDestroyComponent } from "./shared/components/on-destroy/on-destroy.component";
import {
  extractReferralCode,
  isPlatformNative,
  openLink,
} from "./shared/utils/helpers";

import { AuthService } from "./shared/services/auth.service";
import { LoggerService } from "./shared/services/logger.service";
import { StorageService } from "./shared/services/storage.service";
import { UserService } from "./shared/services/user.service";
import { environment } from "src/environments/environment";
import { DomainService } from "./shared/services/domain.service";
import { AnalyticsService } from "./shared/services/analytics.service";
import { RemoteConfigService } from "./shared/services/remote-config.service";
import { DateService } from "./shared/services/date.service";
import { CookieConsentService } from "./shared/services/cookie-consent.service";
import {
  CountryCode,
  CountryCodeEnum,
  CountryInfo,
} from "./shared/models/country.model";
import { LanguageEnum } from "functions/src/models/country.model";
import { Environment } from "src/environments/environment.type";
import { ChatType } from "./shared/models/chat.model";
import { ChatService } from "./shared/services/chat.service";
import { PlatformService } from "./shared/services/platform.service";
import { VersionService } from "./shared/services/native-app-version.service";
import { CleverTapService } from "./shared/services/clevertap.service";
import { UserAgentService } from "./shared/services/user-agent.service";

// Swiper's register function to globally register Swiper's custom elements.
// This should only be done once
register();

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent
  extends OnDestroyComponent
  implements OnInit, OnDestroy
{
  routerStates = RouterStates;

  chatType: ChatType;
  showChat: boolean;
  adjustChatBubblePosition: boolean = false;
  country: CountryCodeEnum;
  private globalRouterEventsSubscription!: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private loggerService: LoggerService,
    private storageService: StorageService,
    private domainService: DomainService,
    private analyticsService: AnalyticsService,
    private platform: Platform,
    private remoteConfigService: RemoteConfigService,
    private dateService: DateService,
    private translateService: TranslateService,
    private cookieConsentService: CookieConsentService,
    private renderer: Renderer2,
    private chatService: ChatService,
    private nativeAppVersionService: VersionService,
    private platformService: PlatformService,
    private cleverTapService: CleverTapService,
    private agentService: UserAgentService
  ) {
    super();

    this.addPreconnect(environment);

    this.platform
      .ready()
      .then(() => {
        return this.initApp();
      })
      .then(() => {
        FirebaseDynamicLinks.addListener("deepLinkOpen", (data) => {
          // handle referral link
          if (data && data.url && data.url.includes("onboarding-intro")) {
            const referralCode = extractReferralCode(data.url);
            if (referralCode) {
              this.storageService.promoCodeSubject.next(referralCode);
              this.storageService.set("promoCode", referralCode);
            }
          }
        });
      })
      .then(() => this.handleNewVersion());

    this.subscriptions.browserBackButton = this.domainService
      .disableBrowserBackButton()
      .subscribe();

    this.subscriptions.hardwareBackButton = this.domainService
      .getHardwareBackButton()
      .subscribeWithPriority(99, () => {});

    this.subscriptions.supportChat = this.userService.userCountryWatch$
      .pipe(
        switchMap((countryInfo: CountryInfo) => {
          if (countryInfo) {
            this.setChat(countryInfo); // init the chat
            this.setGTMCountry(countryInfo);
            this.country = countryInfo?.country;

            return this.router.events.pipe(
              map((routerEvent) => {
                if (routerEvent instanceof NavigationEnd) {
                  this.setChat(countryInfo); // check the chat availability on the navigation events
                }

                return countryInfo;
              })
            );
          }

          return of(countryInfo);
        })
      )
      .subscribe();

    this.subscriptions.langSwitch =
      this.translateService.onLangChange.subscribe(() => {
        const currentLang = this.translateService.currentLang;

        let pageLang;
        switch (currentLang) {
          case LanguageEnum.ph:
            pageLang = "ph";
            break;
          case LanguageEnum.id:
            pageLang = "id";
            break;
          default:
            pageLang = currentLang;
            break;
        }

        this.document.documentElement.lang = pageLang;
        this.cookieConsentService.changeLanguage(pageLang);
      });
  }

  ngOnInit(): void {
    this.analyticsService.trackSessionUpdate();
    this.trackAppVisibility();
    this.subscribeToRouterEvents();
    if (this.platformService.isAndroid()) {
      this.subscriptions.pollForCookiePopup = this.pollForElement().subscribe();
    }

    this.globalRouterEventsSubscription = this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationStart) {
          if (event.url !== "/line-success") {
            const urlParams = new URLSearchParams(window.location.search);
            const loginSuccess = urlParams.get("loginSuccess");
            const userInitiatedBrowserName = urlParams.get("browser");
            const currentBrowser = this.agentService.getBrowser().name;

            if (
              loginSuccess === "true" &&
              userInitiatedBrowserName?.length &&
              currentBrowser?.trim() !== userInitiatedBrowserName?.trim()
            ) {
              this.router.navigate(["/line-success"]);
            }
          }
        }
      }
    );
  }

  pollForElement(): Observable<void> {
    return new Observable<void>((observer) => {
      const polling$ = interval(100).pipe(
        takeWhile(
          () => !document.getElementById("cookiescript_injected_wrapper"),
          true
        ),
        switchMap(() => {
          const element = document.getElementById(
            "cookiescript_injected_wrapper"
          );
          if (element) {
            this.renderer.setStyle(element, "display", "none");
            observer.next();
            observer.complete();
          }
          return [];
        })
      );

      polling$.subscribe({
        complete: () => observer.complete(),
      });

      // stop polling after 2 seconds
      setTimeout(() => {
        observer.complete();
      }, 2000);
    });
  }

  ngOnDestroy(): void {
    this.cleverTapService.cleanup();
    if (this.globalRouterEventsSubscription) {
      this.globalRouterEventsSubscription.unsubscribe();
    }
  }

  private setChat(countryInfo: CountryInfo): void {
    const currentPath = window.location.pathname;

    this.showChat =
      CHAT_ALLOWED_PATHS.includes(currentPath) ||
      this.isPathAllowedForCountry(countryInfo?.country, currentPath);

    this.chatType = CountryChatMap[countryInfo?.country] || ChatType.LINE;

    // Adjust position if the content overlaps
    this.adjustChatBubblePosition =
      CHAT_BUBBLE_OVERLAP_ADJUSTED_PATHS.includes(currentPath);
  }

  private isPathAllowedForCountry(
    countryCode: CountryCodeEnum,
    path: string
  ): boolean {
    const allowedPaths = CHAT_ALLOWED_PATHS_BY_COUNTRY[countryCode];
    return allowedPaths ? allowedPaths.includes(path) : false;
  }

  doRefresh(event: any) {
    setTimeout(() => {
      event.target.complete();
      window.location.reload();
    }, 2000);
  }

  private checkDomain(): Promise<void> {
    if (!location || !location.hostname) {
      return Promise.resolve();
    }

    // check if proper domain
    if (!location.hostname.includes(environment.firebaseConfig.projectId)) {
      return Promise.resolve();
    }

    // redirect to the custom domain
    const path = location.pathname
      ? `${environment.domain}${location.pathname}`
      : environment.domain;
    window.location.href = `https://${path}`;

    return Promise.resolve();
  }

  private async initApp(): Promise<void> {
    this.cleverTapService.init();
    await this.remoteConfigService.init();

    this.authService
      .getFireAuthUser()
      .pipe(
        switchMap((firebaseUser) => {
          if (firebaseUser && firebaseUser.uid) {
            return this.userService.getUserById();
          }

          return of(null);
        }),
        switchMap((userData) => {
          // sync user cache
          if (userData && Object.keys(userData).length) {
            Object.keys(userData).forEach((key) =>
              this.storageService.set(key, userData[key])
            );

            if (userData.user?.email) {
              this.analyticsService.trackVariable({
                email: userData.user.email,
              });
            }

            if (userData.user?.phone) {
              this.analyticsService.trackVariable({
                phone: userData.user.phone,
              });
            }

            if (userData.user?.userId) {
              this.analyticsService.trackVariable({
                ionic_user_id: userData.user.userId,
              });
            }

            this.loggerService.updateSentryScope(userData.user);
            this.userService.updateUserProfileSubject(userData);
          }

          const getCountry$ =
            userData &&
            userData.user &&
            userData.user.country &&
            Object.values(CountryCodeEnum).includes(userData.user.country)
              ? of({ country: userData.user.country, userData })
              : this.userService.getCountryInfo().pipe(
                  map((countryCode: CountryCode) => ({
                    country: countryCode.code,
                    userData: userData || null,
                  })),
                  catchError(() => {
                    // force opening of the country selection component no matter the error
                    this.userService.handleUnknownCountry();
                    return of(null);
                  })
                );

          return getCountry$;
        }),
        switchMap((result) => {
          if (!result) {
            return of(result);
          }

          const { country, userData } = result;
          const language = userData?.user?.language || "";
          const userCountry = userData?.user?.country;

          if (
            userCountry &&
            Object.values(CountryCodeEnum).includes(userCountry) &&
            this.userService.hasBetaAccess(userCountry)
          ) {
            return of(this.userService.setCountry(userCountry, language));
          }

          if (
            country &&
            Object.values(CountryCodeEnum).includes(country) &&
            this.userService.hasBetaAccess(country)
          ) {
            return of(this.userService.setCountry(country, language)).pipe(
              switchMap(() => this.userService.updateLocalUser({ country }))
            );
          }

          const userId = userData?.user?.userId || "";
          return of(this.userService.handleUnknownCountry(userId));
        }),
        take(1)
      )
      .subscribe();

    this.watchRefreshToken();

    // ltoken for lineUID
    this.route.queryParams.subscribe((params) => {
      const ltoken = params["ltoken"];
      if (ltoken) {
        this.authService
          .getFireAuthUser()
          .pipe(
            take(1),
            map((firebaseUser) => !!firebaseUser?.uid),
            // @ts-ignore
            switchMap((isLoggedIn) => {
              if (!isLoggedIn) this.storageService.set("ltoken", ltoken);
            })
          )
          .subscribe();
        this.analyticsService.trackVariable({
          lineUID: ltoken,
        });
      }
    });

    await this.nativeAppVersionService.loadAppInfo();
  }

  private watchRefreshToken() {
    // check token initially
    this.refreshToken().pipe(take(1)).subscribe();

    const tenMinutesInMs = 10 * 60 * 1000;
    this.subscriptions.watchUserAuthState = interval(tenMinutesInMs)
      .pipe(switchMap(() => this.refreshToken()))
      .subscribe();
  }

  private refreshToken(): Observable<any> {
    const idTokenResult$ = this.authService.getIdTokenResult();
    const firebaseUser$ = this.authService.getFireAuthUser();

    return idTokenResult$.pipe(
      switchMap((tokenResult) => {
        return firebaseUser$.pipe(
          map((firebaseUser) => ({ tokenResult, firebaseUser }))
        );
      }),
      switchMap(({ tokenResult, firebaseUser }) => {
        if (!firebaseUser || !tokenResult) {
          return of(null);
        }

        const fiveMinutesInMs = 5 * 60 * 1000;
        const currentTime = new Date().getTime();
        const expireTime = new Date(tokenResult.expirationTime).getTime();
        const refresh =
          expireTime && expireTime - fiveMinutesInMs < currentTime;

        // convert to ms
        const authTime = this.dateService.convertSecondsToMilliseconds(
          tokenResult.claims.auth_time
        );
        const iat = this.dateService.convertSecondsToMilliseconds(
          tokenResult.claims.iat
        );

        const activeDays = this.dateService.getDifferenceInDays(iat, authTime);
        if (activeDays && activeDays >= 30) {
          return from(this.authService.logout()).pipe(
            tap(() => {
              this.router.navigate(["/login"]);
            })
          );
        }

        /** Returns the current token if it has not expired. Otherwise, this will
         * refresh the token and return a new one */
        return firebaseUser.getIdToken(refresh);
      }),
      catchError((error) => {
        this.loggerService.logError(error);
        return of(null);
      })
    );
  }

  private async handleNewVersion() {
    this.nativeAppVersionService.displayUpdatePrompt();
  }

  private setGTMCountry(countryInfo: CountryInfo): void {
    window.gtm_country_code = countryInfo?.country ?? "";
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "country_code_ready",
    });
  }

  openChat(): void {
    this.chatService
      .getContactLink()
      .pipe(
        take(1),
        tap((link) => {
          openLink(link);
        })
      )
      .subscribe();
  }

  /**
   * Sets up visibility tracking for the app, detecting when the app moves to and from the background
   * on both native and web platforms
   *
   * @returns {void}
   */
  private trackAppVisibility() {
    if (isPlatformNative()) {
      this.platform.resume.subscribe(() => {
        console.log("App resumed from background (Ionic native platform)");
        this.analyticsService.trackSessionUpdate();
      });
      return;
    }

    // Web app
    this.document.addEventListener("visibilitychange", () => {
      if (this.document.visibilityState === "visible") {
        this.analyticsService.trackSessionUpdate();
      }
    });
  }

  private addPreconnect(env: Environment) {
    const addLink = (domain: string, crossorigin: boolean = false) => {
      if (domain) {
        const link = this.renderer.createElement("link");
        this.renderer.setAttribute(link, "rel", "preconnect");
        this.renderer.setAttribute(link, "href", domain);
        if (crossorigin) {
          this.renderer.setAttribute(link, "crossorigin", "anonymous");
        }

        const head = document.head;
        const firstChild = head.firstChild;
        if (firstChild) {
          this.renderer.insertBefore(head, link, firstChild);
        } else {
          this.renderer.appendChild(head, link);
        }
      }
    };

    env.preloadableDomains.forEach((domain) => addLink(domain));
    [env.requestUrl, env.firebaseConfig.databaseURL].forEach((domain) =>
      addLink(domain, true)
    );
  }

  /**
   * Subscribes to router events and triggers the saveEmployerCode method
   * whenever a navigation ends.
   */
  private subscribeToRouterEvents(): void {
    this.subscriptions.routerEvents = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => {
          this.saveEmployerCode();
        })
      )
      .subscribe();
  }

  /**
   * Extracts the employer code query parameter from the current route and saves it
   * to storage using the StorageService for later use
   */
  private saveEmployerCode(): void {
    const ecParam = this.route.snapshot.queryParamMap.get("ec");

    if (ecParam) {
      this.storageService.set("employerCode", ecParam);
    }
  }
}
