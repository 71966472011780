import { Directive, HostListener } from "@angular/core";
import { IonInput } from "@ionic/angular";

@Directive({
  selector: "[appCapitalizeInput]",
})
export class CapitalizeInputDirective {
  constructor(private ionInput: IonInput) {}

  @HostListener("input", ["$event"])
  onInput(event): void {
    const value = event?.target?.value;

    if (value) {
      const capitalizedValue = (value as string).toUpperCase();
      this.ionInput.value = capitalizedValue;
    }
  }
}
