import { Injectable, signal } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of, switchMap, take } from "rxjs";
import { REQUEST_API } from "src/app/shared/consts";

import {
  GetKnackTransactionResponse,
  GetKnackUserResponse,
} from "../models/knack.model";

import {
  TabDataSelector,
  TransactionGroup,
  TransactionPageCounts,
  TransactionPagination,
  TransactionResponse,
  TransactionTabData,
} from "../models/transaction.model";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class AmsService {
  private showCompositeTxn = signal<boolean>(false);

  getShowCompositeTxn(): boolean {
    return this.showCompositeTxn();
  }

  setShowCompositeTxn(useCompositeTxn: boolean): void {
    this.showCompositeTxn.set(useCompositeTxn);
  }

  constructor(
    private httpClient: HttpClient,
    private userService: UserService
  ) {}

  getOneUser(spinner = true): Observable<GetKnackUserResponse> {
    let headers = spinner
      ? new HttpHeaders()
      : new HttpHeaders().set("hideLoading", "true");

    return this.httpClient.get<GetKnackUserResponse>(
      `${REQUEST_API}/ams-users/`,
      { headers }
    );
  }

  getUserTransactions(): Observable<GetKnackTransactionResponse> {
    return this.httpClient.get<GetKnackTransactionResponse>(
      `${REQUEST_API}/ams-users/transactions`
    );
  }

  getTransactions(
    pagination?: TransactionPagination
  ): Observable<TransactionResponse> {
    return this.httpClient.post<TransactionResponse>(
      `${REQUEST_API}/transactions`,
      {
        pagination,
      }
    );
  }

  createTransactionPageCounts(
    transactionPagination: TransactionPagination
  ): TransactionPageCounts {
    if (!transactionPagination) {
      return {} as TransactionPagination;
    }

    return {
      membershipPageCount: transactionPagination.membershipPageCount,
      advancePageCount: transactionPagination.advancePageCount,
    };
  }

  createTransactionTabDataKey(
    advancePage: number,
    membershipPage: number
  ): string {
    const advanceTransactionsKey = advancePage || 1;
    const membershipTransactionsKey = membershipPage || 1;

    return `${advanceTransactionsKey}_${membershipTransactionsKey}`;
  }

  createTransactionTabData(
    transactions?: TransactionResponse
  ): Map<string, TransactionTabData> {
    let key = this.createTransactionTabDataKey(
      transactions?.pagination?.advancePage,
      transactions?.pagination?.membershipPage
    );

    return new Map<string, TransactionTabData>([
      [
        key,
        {
          pendingTransactions: transactions?.pendingTransactions || [],
          rejectedTransactions: transactions?.rejectedTransactions || [],
          paidBackTransactions: transactions?.paidBackTransactions || [],
        },
      ],
    ]);
  }

  getTransactionTabData(
    advancePage: number,
    membershipPage: number,
    tabDataSelector: TabDataSelector
  ): Observable<TransactionGroup[]> {
    return this.userService.userProfileWatch$.pipe(
      take(1),
      switchMap((userData) => {
        const tabDataKey = this.createTransactionTabDataKey(
          advancePage,
          membershipPage
        );

        const pageCounts = userData?.transactionPageCounts;
        const transactionData = userData?.transactionTabData?.get(tabDataKey);
        const transactions = tabDataSelector(transactionData);

        if (
          transactions?.length ||
          userData?.transactionTabData?.has(tabDataKey)
        ) {
          return of(transactions);
        }

        if (
          advancePage > pageCounts?.advancePageCount &&
          membershipPage > pageCounts?.membershipPageCount
        ) {
          return of([]);
        }

        const pagination: TransactionPagination = {
          advancePage,
          advancePageCount: pageCounts?.advancePageCount,
          membershipPage,
          membershipPageCount: pageCounts?.membershipPageCount,
        };

        return this.getTransactions(pagination).pipe(
          switchMap((transactionResponse: TransactionResponse) => {
            const transactionTabDataUpdate = this.getTransactionTabDataUpdate(
              transactionResponse,
              userData?.transactionTabData
            );

            this.userService.updateUserProfileSubject(transactionTabDataUpdate);

            return of(tabDataSelector(transactionResponse));
          })
        );
      })
    );
  }

  private getTransactionTabDataUpdate(
    transactions: TransactionResponse,
    currentTabData: Map<string, TransactionTabData>
  ) {
    const transactionPageCounts = this.createTransactionPageCounts(
      transactions?.pagination
    );

    const currentTransactionTabData = currentTabData || new Map();
    const newTransactionTabData = this.createTransactionTabData(transactions);
    const transactionTabData = new Map([
      ...currentTransactionTabData,
      ...newTransactionTabData,
    ]);

    return { transactionPageCounts, transactionTabData };
  }
}
