import { HttpErrorResponse } from "@angular/common/http";

export const isGloballyHandledError = (error: any): boolean => {
  // Conditions handled by the global error handler:
  const isConnectivityError =
    error instanceof HttpErrorResponse && error.status === 0;

  const isRemoteConfigError = error?.message?.includes(
    "Remote Config: Fetch client failed to connect"
  );

  return isConnectivityError || isRemoteConfigError;
};
