// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-transactions {
  width: 100%;
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.no-transactions ion-icon {
  font-size: 48px;
  color: var(--ion-color-previous, #888888);
}
.no-transactions p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--ion-color-previous, #888888);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/no-transaction/no-transaction.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,yCAAA;AACJ;AAEE;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yCAAA;AAAJ","sourcesContent":[".no-transactions {\n  width: 100%;\n  margin: 16px auto;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  align-items: center;\n  justify-content: center;\n\n  ion-icon {\n    font-size: 48px;\n    color: var(--ion-color-previous, #888888);\n  }\n\n  p {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 17px;\n    color: var(--ion-color-previous, #888888);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
