import {
  ChangeDetectorRef,
  Component,
  NgZone,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { trigger, style, animate, transition } from "@angular/animations";

import { AnimationOptions } from "ngx-lottie";
import { AnimationItem } from "lottie-web";

@Component({
  selector: "app-entry-animation",
  templateUrl: "./entry-animation.component.html",
  styleUrls: ["./entry-animation.component.scss"],
  animations: [
    trigger("slideAnimation", [
      transition("void => *", [
        style({ transform: "translateY(80%) scale(0.9)", opacity: 0 }),
        animate(
          "500ms ease-out",
          style({ transform: "translateY(0) scale(1)", opacity: 1 })
        ),
      ]),
    ]),
  ],
})
export class EntryAnimationComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() description: string;
  @Input() animUrl: string;
  @Input() animSpeed: number;
  @Input() animWidth: string;
  @Input() animHeight: string;
  @Output() onAnimComplete = new EventEmitter<boolean>();

  private animationItem: AnimationItem | null = null;

  animOptions: AnimationOptions;
  hideAnim: boolean;

  constructor(private ngZone: NgZone, private ref: ChangeDetectorRef) {
    this.hideAnim = false;
  }

  ngOnInit() {
    this.animOptions = {
      path: this.animUrl,
    };

    this.animHeight = this.animHeight ?? "240px";
    this.animWidth = this.animWidth ?? "240px";
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem?.stop();
      this.animationItem?.destroy();
      this.animationItem = null;
    });
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.animationItem?.setSpeed(this.animSpeed || 1);
  }

  onLoopComplete(): void {
    this.ngZone.run(() => {
      this.hideAnim = true;
      this.onAnimComplete.emit(true);
      this.ref.detectChanges();
    });
  }
}
