import { Injectable } from "@angular/core";
import { App, AppInfo } from "@capacitor/app";
import { RemoteConfigService } from "./remote-config.service";

import {
  ForceVersionReminderType,
  NativeAppVersioning,
  RemindVersionReminderType,
  SilentVersionReminderType,
  UpdatePoliciesType,
} from "../consts";

import { ToasterService } from "./toaster.service";
import { TranslatePipe } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";
import { environment } from "src/environments/environment";
import { PlatformService } from "./platform.service";

export const UNKNOWN_VERSION = "Unknown Version";

@Injectable({
  providedIn: "root",
})
export class VersionService {
  private appInfo: AppInfo;
  private remoteConfigInfo: string;

  constructor(
    private remoteConfigService: RemoteConfigService,
    private toasterService: ToasterService,
    private translatePipe: TranslatePipe,
    private alertController: AlertController,
    private platformService: PlatformService
  ) {}

  /**
   * Loads the application information asynchronously using Capacitor's App API.
   * Stores the fetched app information in the `appInfo` property.
   * Logs an error to the console if fetching fails.
   */
  async loadAppInfo(): Promise<void> {
    try {
      this.remoteConfigInfo = `{}`;
      if (this.platformService.isAndroid()) {
        this.appInfo = await App.getInfo();
        this.remoteConfigInfo =
          await this.remoteConfigService.fetchNativeAppVersions();
      }
    } catch (error) {
      console.error("Error fetching app info:", error);
    }
  }

  get versionName(): string {
    return this.appInfo?.version ?? UNKNOWN_VERSION;
  }

  get versionCode(): number | string {
    return this.appInfo?.build || 0;
  }

  /**
   * Determines the update policy for the given app version.
   * - Parses the remote configuration to retrieve versioning policies.
   * - Sorts the versions in ascending order for consistent checks.
   * - Compares the app version with the sorted versions to find the appropriate update policy.
   * - Returns a default silent reminder policy if no match is found.
   */
  checkVersionPolicy(appVersion: string): UpdatePoliciesType {
    const parsedConfig = JSON.parse(
      this.remoteConfigInfo
    ) as NativeAppVersioning;
    const policies = parsedConfig?.versioning?.updatePolicies || {};

    const sortedVersions = Object.keys(policies).sort((a, b) =>
      this.compareVersions(a, b)
    );

    for (const version of sortedVersions) {
      if (this.compareVersions(appVersion, version) <= 0) {
        return policies[version];
      }
    }
    return SilentVersionReminderType; // Default to silent if no policy matches
  }

  /**
   * Compares two semantic version strings (e.g., "1.2.0" and "1.3.1").
   * - Splits the versions into their respective components: major, minor, patch.
   * - Iteratively compares each component to determine their order.
   * - Returns:
   *   - Negative value if v1 < v2.
   *   - Positive value if v1 > v2.
   *   - Zero if v1 === v2.
   */
  compareVersions(v1: string, v2: string): number {
    const v1Parts = v1.split(".").map(Number);
    const v2Parts = v2.split(".").map(Number);
    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const diff = (v1Parts[i] || 0) - (v2Parts[i] || 0);
      if (diff !== 0) return diff;
    }
    return 0;
  }

  /**
   * Displays an update prompt to the user based on the determined policy.
   * - Force update: Shows a modal alert with a link to update the app. The user cannot dismiss this alert.
   * - Remind update: Displays a non-intrusive success message via the toaster service.
   * - Silent update: No action is taken, allowing the user to continue using the app.
   */
  async displayUpdatePrompt(): Promise<void> {
    const policy = this.checkVersionPolicy(this.versionName);

    if (policy === ForceVersionReminderType) {
      const alert = await this.alertController.create({
        header: this.translatePipe.transform("VERSION_UPDATE.TITLE"),
        message: this.translatePipe.transform("VERSION_UPDATE.DESCRIPTION"),
        cssClass: "app-new-version",
        backdropDismiss: false,
        buttons: [
          {
            text: this.translatePipe.transform("VERSION_UPDATE.ACTION"),
            handler: () => {
              window.open(environment.androidAppLink, "_blank");
              return false;
            },
          },
        ],
      });

      await alert.present();
    } else if (policy === RemindVersionReminderType) {
      this.toasterService.success(
        this.translatePipe.transform("VERSION_UPDATE.DESCRIPTION")
      );
    }
  }
}
