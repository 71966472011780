// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-transactions {
  width: 100%;
  margin: 0px auto 24px auto;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.recent-transactions .title {
  width: 100%;
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--ion-color-black, #000000);
}
.recent-transactions .transaction-list {
  width: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.no-transactions {
  width: 100%;
  margin: 24px auto;
  padding: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/recent-transactions/recent-transactions.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,WAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sCAAA;AACJ;AAEE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ;;AAIA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;AADF","sourcesContent":[".recent-transactions {\n  width: 100%;\n  margin: 0px auto 24px auto;\n  padding: 24px 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  .title {\n    width: 100%;\n    margin: 0px;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 22px;\n    color: var(--ion-color-black, #000000);\n  }\n\n  .transaction-list {\n    width: 100%;\n    margin: 0px auto;\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n  }\n}\n\n.no-transactions {\n  width: 100%;\n  margin: 24px auto;\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
