import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { defineCustomElements } from "@ionic/pwa-elements/loader";

import * as Sentry from "@sentry/angular-ivy";
import { httpClientIntegration } from "@sentry/integrations";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import packageJson from "../package.json";

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: environment.dsn,
    environment: environment.env,
    integrations: [
      Sentry.replayIntegration({
        maskAllInputs: false,
        blockAllMedia: false,
        maskAllText: false,
      }),
      httpClientIntegration(),
    ],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0, // Should not capture regular sessions and track them with errors only

    sendDefaultPii: true, // This is required for capturing headers and cookies.

    release: `finn@${packageJson.version}`,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

defineCustomElements(window);
