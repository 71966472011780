import { FB_CHAT_LINK_PH } from "../shared/consts";
import {
  CountryCodeEnum,
  CountryInfo,
  CountryLocale,
} from "../shared/models/country.model";

export const PH_VALIDATION_RULES = {
  phoneNumber: /^(0[89]{1})+([0-9]{8})+$/g,
};

export const PH_COUNTRY_INFO: CountryInfo = {
  country: CountryCodeEnum.ph,
  phone: "ph",
  language: "ph-en",
  phoneCode: "+63",
  flagIcon: "assets/countries/philippines_flag.svg",
  validationRules: {
    phoneNumber: /^(0[89]{1})+([0-9]{9})+$/g,
    bankAcount: /^\d{12}$/,
  },
  currency: {
    currencyCode: "PHP",
    digitsInfo: "1.2-2",
    currencySymbol: "₱",
  },
  phoneNumberPlaceholder: "09012345678",
  locale: CountryLocale.ph,
  contactUsLink: FB_CHAT_LINK_PH,
  timeZoneOffset: "UTC+8",
  timeZone: "Asia/Manila",
};

export interface PH_QUESTIONS {
  formallyEmployed: boolean;
  usePartnerBankUbp: boolean;
  accessToMobileBanking: boolean;
  passedProbationPeriod: boolean;
}
