// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
:host .icon-show-pin,
:host .icon-hide-pin {
  margin-left: 16px;
  font-size: 20px;
  color: #ccc;
  flex: none;
}

.hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/pin-toggle/pin-toggle.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAEE;;EAEE,iBAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;AAAJ;;AAIA;EACE,aAAA;AADF","sourcesContent":[":host {\n  width: 100%;\n  margin: auto;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .icon-show-pin,\n  .icon-hide-pin {\n    margin-left: 16px;\n    font-size: 20px;\n    color: #ccc;\n    flex: none;\n  }\n}\n\n.hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
