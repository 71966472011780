import { Injectable, isDevMode, NgZone } from "@angular/core";
import { filter, take, timer } from "rxjs";
import Branch, { BranchDeepLinks } from "capacitor-branch-deep-links";
import { Router } from "@angular/router";
import { AppPages } from "../models/app.model";
import { NavController } from "@ionic/angular";
import { extractReferralCode } from "../utils/helpers";
import { StorageService } from "./storage.service";
import {
  InAppBrowser,
  InAppBrowserObject,
  InAppBrowserOptions,
} from "@awesome-cordova-plugins/in-app-browser/ngx";
import { LoaderService } from "./loader.service";
import { PlatformService } from "./platform.service";

const ENV = isDevMode() ? "staging" : "production";

const DOMAINS = {
  production: "https://app.finn-app.com",
  staging: "https://app-dev.finn-app.com",
  development: "http://localhost:8100",
};

// Get Current Environment Configurations
const getDomain = () => DOMAINS[ENV as keyof typeof DOMAINS];

@Injectable({
  providedIn: "root",
})
export class BranchLinkService {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private storageService: StorageService,
    private iab: InAppBrowser,
    private loaderService: LoaderService,
    private navController: NavController,
    private platformService: PlatformService
  ) {}

  // Function to create a Branch short link
  createBranchShortLink({
    link,
    linkDescription = "Check this out!",
    stage = AppPages.onboardingIntro,
    campaign = "Default Campaign",
    ...rest
  }: {
    link: string;
    linkDescription?: string;
    stage?: string;
    campaign?: string;
    [key: string]: any;
  }): Promise<string> {
    const analytics = {
      channel: "web",
      feature: "sharing",
      campaign: campaign || "Default Campaign",
      stage: stage || "new user",
      tags: ["deep_link", "transactions"],
    };

    const properties = {
      $desktop_url: link,
      $android_url: link,
      $ios_url: link,
      $fallback_url: link,
      $og_title: "Finn App",
      $og_description: linkDescription,
      $og_image_url: "https://app.finn-app.com/assets/img/logo.png",
      ...rest,
    };

    return BranchDeepLinks.generateShortUrl({ analytics, properties })
      .then((response) => response.url)
      .catch((error) => {
        console.error("Error generating Branch link:", error);
        throw error;
      });
  }

  // Function to handle deep link navigation
  initiateAndHandleDeepLinks() {
    BranchDeepLinks.addListener("init", (event: any) => {
      if (
        event.referringParams &&
        event.referringParams["+clicked_branch_link"]
      ) {
        const deepLinkPath = event.referringParams["$android_url"];
        if (deepLinkPath) {
          this.ngZone.run(() => {
            this.router.navigate([`/${deepLinkPath.replace(getDomain(), "")}`]);
          });
          if (deepLinkPath.includes("onboarding-intro")) {
            const referralCode = extractReferralCode(deepLinkPath);
            if (referralCode) {
              this.storageService.promoCodeSubject.next(referralCode);
              this.storageService.set("promoCode", referralCode);
            }
          }
        } else {
          console.log("No deep link path found.");
        }
      } else {
        console.log("No Branch link clicked.");
      }
    });
    BranchDeepLinks.addListener("initError", (error: any) => {
      console.error(error);
    });

    // Handle direct link pasting in browser (for Android webviews)
    window.onload = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const matchId = urlParams.get("_branch_match_id");
      const referrer = urlParams.get("_branch_referrer");
      if (matchId || referrer) {
        const path = this.router.url.replace(getDomain(), "");
        if (isDevMode()) {
          window.location.href = `finndev://${path}`;
        } else {
          window.location.href = `finn://${path}`;
        }
      }
    };
  }
  // Function to handle account linkages - TODO
  startAccountLinkingFlow(url: string, responseUrl: string): void {
    if (this.platformService.isWeb()) {
      window.location.href = url;
      return;
    }

    const options: InAppBrowserOptions = {
      hidden: "no",
      location: "no",
      clearsessioncache: "yes",
      clearcache: "yes",
    };

    const browserRef: InAppBrowserObject = this.iab.create(
      url,
      "_blank",
      options
    );

    browserRef
      .on("loadstart")
      .pipe(
        filter((event: any) => {
          const eventUrl = new URL(event?.url);

          try {
            const redirectUrlParam = eventUrl.searchParams.get("redirect_url");

            if (redirectUrlParam) {
              const decodedRedirectUrl = decodeURIComponent(redirectUrlParam);
              const responseUrlObj = new URL(responseUrl);
              const responseRelativePath =
                responseUrlObj.pathname + responseUrlObj.search;
              return (
                decodedRedirectUrl.startsWith(responseRelativePath) ||
                decodedRedirectUrl.includes(responseRelativePath)
              );
            }
          } catch (error) {
            console.error("Invalid URL:", event?.url);
          }

          return (
            event?.url.includes(responseUrl) || eventUrl.href === responseUrl
          );
        })
      )
      .subscribe(() => {
        this.loaderService.show();

        timer(2000).subscribe(() => {
          this.loaderService.hide();
          browserRef.close();
          this.navController.navigateRoot(
            "link-bank-account/link-account-waiting"
          );
        });
      });
  }
}
