import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { CleverTapStorageService } from "./clevertap-storage.service";
import {
  ClevertapEventData,
  DEFAULT_EXPIRATION_TIME_MS,
} from "../models/clevertap.model";
import { DateService } from "./date.service";

@Injectable({
  providedIn: "root",
})
export class CleverTapService {
  private readonly renderer: Renderer2;
  private ctWebNativeDisplayListener: () => void;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly cleverTapStorage: CleverTapStorageService,
    private readonly dateService: DateService
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Initializes CleverTap listener
   */
  public init(): void {
    this.listenForWebNativeDisplay();
  }

  /**
   * Listens for the 'CT_web_native_display' event and handles key-value pairs
   */
  private listenForWebNativeDisplay(): void {
    this.ctWebNativeDisplayListener = this.renderer.listen(
      "document",
      "CT_web_native_display",
      (event: CustomEvent) => {
        if (event?.detail) {
          this.handleKeyValuePairs(event.detail);
        }
      }
    );
  }

  /**
   * Handles incoming key-value pairs
   */
  private async handleKeyValuePairs(
    eventData: ClevertapEventData
  ): Promise<void> {
    const { kv } = eventData;
    if (!kv?.topic) {
      return;
    }

    const expirationTimestamp = this.calculateExpirationTimestamp(
      kv.campaign_ends
    );

    // If the campaign is expired, ignore the event data
    if (this.dateService.isBefore(expirationTimestamp, Date.now())) {
      return;
    }

    const campaignTopic = kv.topic.toString();
    await this.cleverTapStorage.set(campaignTopic, {
      ...eventData,
      expirationTimestamp,
    });
  }

  /**
   * Calculates the expiration timestamp based on the campaign runtime
   */
  private calculateExpirationTimestamp(runtime?: number): number {
    const parsedRuntime = Number(runtime);
    if (!isNaN(parsedRuntime) && parsedRuntime > 0) {
      return this.dateService.getTimestamp(parsedRuntime);
    }

    // Default to 60 minutes if not provided
    return Date.now() + DEFAULT_EXPIRATION_TIME_MS;
  }

  /**
   * Retrieves key-value pairs for a specific topic from if they exist and are not expired
   */
  async getKeyValuePairs(topic: string): Promise<any> {
    try {
      const data = await this.cleverTapStorage.get(topic);
      if (!data) {
        return null;
      }

      const isExpired = data.expirationTimestamp
        ? this.dateService.isBefore(data.expirationTimestamp, Date.now())
        : true;

      if (isExpired) {
        await this.cleverTapStorage.remove(topic);
        return null;
      }

      return data;
    } catch (error) {
      return null;
    }
  }

  /**
   * Cleans up all event listeners to avoid memory leaks
   */
  cleanup(): void {
    if (this.ctWebNativeDisplayListener) {
      this.ctWebNativeDisplayListener();
    }
  }
}
