import { Component, Input, OnInit } from "@angular/core";
import { VerificationStep } from "src/app/shared/models/verification-step";

@Component({
  selector: "app-verification-step",
  templateUrl: "./verification-step.component.html",
  styleUrls: ["./verification-step.component.scss"],
})
export class VerificationStepComponent implements OnInit {
  @Input() currentStep: number;
  @Input() verificationStep: VerificationStep;

  isCurrent: boolean;
  isCompleted: boolean;

  constructor() {}

  ngOnInit(): void {
    this.isCurrent = this.currentStep === this.verificationStep.step;
    this.isCompleted = this.currentStep > this.verificationStep.step;
  }
}
