import { FB_CHAT_LINK_ZA } from "../shared/consts";
import {
  CountryCodeEnum,
  CountryInfo,
  CountryLocale,
} from "../shared/models/country.model";

export const ZA_VALIDATION_RULES = {
  phoneNumber: /^0[678]\d{8}$/,
};

export const ZA_COUNTRY_INFO: CountryInfo = {
  country: CountryCodeEnum.za,
  phone: "za",
  language: "za-en",
  phoneCode: "+27",
  flagIcon: "assets/countries/za_flag.svg",
  validationRules: {
    phoneNumber: /^0[678]\d{8}$/,
    bankAccount: /^\d{7,11}$/, // or /^\d{10,11}$/ for Capitec Bank-specific rule
  },
  currency: {
    currencyCode: "ZAR",
    digitsInfo: "1.2-2",
    currencySymbol: "R",
  },
  phoneNumberPlaceholder: "0721234567",
  locale: CountryLocale.za,
  contactUsLink: FB_CHAT_LINK_ZA,
  zipCodeLength: 4,
  timeZoneOffset: "UTC+2",
  timeZone: "Africa/Johannesburg",
  statementRequestMonths: 5, // count starts from 0
};
