import { LINE_ID } from "../shared/consts";
import {
  CountryCodeEnum,
  CountryInfo,
  CountryLocale,
} from "../shared/models/country.model";

export const TH_VALIDATION_RULES = {
  phoneNumber: /^(0[689]{1})+([0-9]{8})+$/g,
};

export const TH_COUNTRY_INFO: CountryInfo = {
  country: CountryCodeEnum.th,
  phone: "th",
  language: "th",
  phoneCode: "+66",
  flagIcon: "assets/countries/thai_flag.svg",
  validationRules: {
    phoneNumber: /^(0[689]{1})+([0-9]{8})+$/g,
    bankAcount: /^\d{10}$/,
  },
  currency: {
    currencyCode: "BAHT",
    digitsInfo: "1.2-2",
    currencySymbol: "฿",
  },
  phoneNumberPlaceholder: "0812345678",
  locale: CountryLocale.th,
  contactUsLink: LINE_ID,
  zipCodeLength: 5,
  timeZoneOffset: "UTC+7",
  timeZone: "Asia/Bangkok",
};

export interface TH_QUESTIONS {
  formallyEmployed: boolean;
  usePartnerBankScbBblKasKtbBay: boolean;
  accessToMobileBanking: boolean;
  passedProbationPeriod: boolean;
}
