import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-on-destroy",
  template: "",
})
export abstract class OnDestroyComponent implements OnDestroy {
  protected subscriptions: { [key: string]: Subscription } = {};

  ngOnDestroy(): void {
    const values = Object.values(this.subscriptions);
    if (values.length > 0) {
      values.forEach((value) => value.unsubscribe());
    }
  }
}
