import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NavController } from "@ionic/angular";
import { StorageService } from "../../services/storage.service";

@Component({
  selector: "app-membership-status",
  templateUrl: "./membership-status.component.html",
  styleUrls: ["./membership-status.component.scss"],
})
export class MembershipStatusComponent implements OnInit {
  @Input() renewalDate: string;
  @Input() userName: string;
  @Input() userProfileImage: string;
  @Input() isCanceled: boolean;
  @Input() hasMembership: boolean;
  @Input() settings: boolean;
  @Input() showDetails: boolean;
  @Input() membershipFee: number;

  @Output() getPlan = new EventEmitter<boolean>();

  statusBackground: string;
  finnPlusLogo: string;

  constructor(
    private navConroller: NavController,
    private storageService: StorageService
  ) {
    this.showDetails = true;
    this.hasMembership = true;
  }

  ngOnInit() {
    this.setAssetPaths();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isCanceled || changes.hasMembership) {
      this.setAssetPaths();
    }
  }

  private setAssetPaths(): void {
    this.statusBackground =
      this.isCanceled && this.hasMembership
        ? "assets/icon/background-membership-canceled.svg"
        : "assets/icon/background-membership-renews.svg";

    this.finnPlusLogo = this.isCanceled
      ? "assets/icon/finn-plus-red.svg"
      : "assets/icon/finn-plus.svg";
  }

  async manage(): Promise<void> {
    await this.storageService.set("membership", {
      renewalDate: this.renewalDate,
      membershipFee: this.membershipFee,
      hasMembership: this.hasMembership,
      isCanceled: this.isCanceled,
    });

    const path = this.settings
      ? "main/profile/settings/manage-membership"
      : "main/membership/manage-membership";

    this.navConroller.navigateForward(path);
  }

  onGetPlan(): void {
    this.getPlan.emit(true);
  }
}
