import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { Bank } from "src/app/shared/models/bank.model";
import { TEMPORARILY_DISABLED_BANKS } from "../../consts";
import { CountryCodeEnum } from "../../models/country.model";
import { bankUtil } from "../../utils/bank-util";
import { RemoteConfigService } from "../../services/remote-config.service";

@Component({
  selector: "app-bank-list-modal",
  templateUrl: "./bank-list-modal.component.html",
  styleUrls: ["./bank-list-modal.component.scss"],
})
export class BankListModalComponent {
  @Input() page: string;
  @Input() questionnaireBankList: string;
  @Input() country: CountryCodeEnum;

  banks: Bank[];

  constructor(
    private modalController: ModalController,
    private remoteConfigService: RemoteConfigService
  ) {}

  async ngOnInit() {
    this.banks = await this.getBankList();
  }

  private async getBankList(): Promise<Bank[]> {
    const showPendingBanks =
      await this.remoteConfigService.showBanksPendingIntegration(this.country);

    const banks = bankUtil.getAllBanks(this.country, showPendingBanks);

    if (this.questionnaireBankList) {
      return banks;
    }

    return banks.filter(
      (bank) => !TEMPORARILY_DISABLED_BANKS.includes(bank.id)
    );
  }

  private dismissModal(bank: Partial<Bank>): void {
    const selectedBank = bank && bank.id ? bank : null;
    this.modalController.dismiss({ bank: selectedBank });
  }

  selectBank(bank: Bank): void {
    this.dismissModal(bank);
  }

  closeModal(): void {
    this.dismissModal({});
  }
}
