import { Injectable } from "@angular/core";
import { PopoverController, PopoverOptions } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class PopoverService {
  constructor(private popoverController: PopoverController) {}

  createPopover(options: PopoverOptions): Promise<HTMLIonPopoverElement> {
    return this.popoverController.create(options);
  }

  dismissPopover(data: any, role?: string, id?: string): Promise<boolean> {
    return this.popoverController.dismiss(data, role, id);
  }
}
