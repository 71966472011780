import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  constructor(private toastController: ToastController) {}

  async error(message: string, duration = 3000): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration,
      color: "danger",
    });
    toast.present();
  }

  async message(message: string, duration = 3000): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration,
    });
    toast.present();
  }

  async success(message: string, duration = 3000): Promise<void> {
    const toast = await this.toastController.create({
      message,
      duration,
      color: "success",
    });
    toast.present();
  }
}
