// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-title {
  padding-inline-start: 8px;
  text-align: justify;
}

ion-list {
  padding-top: 8px;
  background: transparent;
}
ion-list ion-item {
  padding: 12px 0px;
  border-bottom: 1px solid #e0e0e0;
}
ion-list ion-item ion-avatar {
  background: var(--ion-tab-background, #f3f3fd);
}

ion-content {
  --background: var(--ion-color-white, #ffffff);
}

.close-btn {
  --color: var(--ion-color-required, #666666);
}

.spinner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/bank-list-modal/bank-list-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,uBAAA;AACF;AAAE;EACE,iBAAA;EACA,gCAAA;AAEJ;AAAI;EACE,8CAAA;AAEN;;AAGA;EACE,6CAAA;AAAF;;AAGA;EACE,2CAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF","sourcesContent":["ion-title {\n  padding-inline-start: 8px;\n  text-align: justify;\n}\n\nion-list {\n  padding-top: 8px;\n  background: transparent;\n  ion-item {\n    padding: 12px 0px;\n    border-bottom: 1px solid #e0e0e0;\n\n    ion-avatar {\n      background: var(--ion-tab-background, #f3f3fd);\n    }\n  }\n}\n\nion-content {\n  --background: var(--ion-color-white, #ffffff);\n}\n\n.close-btn {\n  --color: var(--ion-color-required, #666666);\n}\n\n.spinner-wrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
