import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { AuthLocalGuard } from "./guards/auth-local.guard";
import { DeleteAccountGuard } from "./guards/delete-account.guard";
import { MainLocalGuard } from "./guards/main-local.guard";
import { StateGuard } from "./guards/state.guard";
import { AndroidSignupGuard } from "./guards/android-signup.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "onboarding-intro",
    pathMatch: "full",
    data: { queryParamsHandling: "merge" },
  },
  {
    path: "onboarding-intro",
    loadChildren: () =>
      import("./pages/onboarding-intro/onboarding-intro.module").then(
        (m) => m.OnboardingIntroPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
    data: { queryParamsHandling: "merge" },
  },
  {
    path: "onboarding-intro/:promo-code",
    loadChildren: () =>
      import("./pages/onboarding-intro/onboarding-intro.module").then(
        (m) => m.OnboardingIntroPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "questionnaire",
    loadChildren: () =>
      import("./onboarding/questionnaire/questionnaire.module").then(
        (m) => m.QuestionnairePageModule
      ),
    canActivate: [StateGuard, AndroidSignupGuard],
  },
  {
    path: "terms-of-service",
    loadChildren: () =>
      import("./onboarding/terms-of-service/terms-of-service.module").then(
        (m) => m.TermsOfServicePageModule
      ),
    canActivate: [StateGuard, AndroidSignupGuard],
  },
  {
    path: "credentials",
    loadChildren: () =>
      import("./onboarding/credentials/credentials.module").then(
        (m) => m.CredentialsPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "id-card-verification",
    loadChildren: () =>
      import(
        "./onboarding/id-card-verification/id-card-verification.module"
      ).then((m) => m.IdCardVerificationPageModule),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "info-cross-check",
    loadChildren: () =>
      import("./onboarding/info-cross-check/info-cross-check.module").then(
        (m) => m.InfoCrossCheckPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "ktp-scan",
    loadChildren: () =>
      import("./onboarding/ktp-scan/ktp-scan.module").then(
        (m) => m.KtpScanPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "additional-info",
    loadChildren: () =>
      import("./pages/additional-info/additional-info.module").then(
        (m) => m.AdditionalInfoPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "additional-profile",
    loadChildren: () =>
      import("./onboarding/additional-profile/additional-profile.module").then(
        (m) => m.AdditionalProfilePageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "additional-payday-info",
    loadChildren: () =>
      import(
        "./onboarding/additional-payday-info/additional-payday-info.module"
      ).then((m) => m.AdditionalPaydayInfoPageModule),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "employment",
    loadChildren: () =>
      import("./onboarding/employment/employment.module").then(
        (m) => m.EmploymentPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "employment-verification",
    loadChildren: () =>
      import(
        "./onboarding/employment-verification/employment-verification.module"
      ).then((m) => m.EmploymentVerificationPageModule),
    canActivate: [AuthLocalGuard],
  },
  {
    path: "bank-statements",
    loadChildren: () =>
      import("./pages/bank-statements/bank-statements.module").then(
        (m) => m.BankStatementsPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "finverse",
    loadChildren: () =>
      import("./pages/finverse/finverse.module").then(
        (m) => m.FinversePageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "link-bank-account",
    loadChildren: () =>
      import("./pages/link-bank/link-bank.module").then(
        (m) => m.LinkBankPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "onboarding-final-screen",
    loadChildren: () =>
      import(
        "./pages/onboarding-final-screen/onboarding-final-screen.module"
      ).then((m) => m.OnboardingFinalScreenPageModule),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "main",
    loadChildren: () =>
      import("./main/main.module").then((m) => m.MainPageModule),
    canActivate: [AuthLocalGuard, MainLocalGuard, AndroidSignupGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "application-rejected",
    loadChildren: () =>
      import("./pages/user-rejected/user-rejected.module").then(
        (m) => m.UserRejectedPageModule
      ),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "account-recovery",
    loadChildren: () =>
      import("./pages/account-recovery/account-recovery.module").then(
        (m) => m.AccountRecoveryPageModule
      ),
    canActivate: [AuthLocalGuard],
  },
  {
    path: "permanently-deactivated",
    loadChildren: () =>
      import(
        "./pages/permanently-deactivated/permanently-deactivated.module"
      ).then((m) => m.PermanentlyDeactivatedPageModule),
    canActivate: [AuthLocalGuard, AndroidSignupGuard],
  },
  {
    path: "delete-account",
    loadChildren: () =>
      import("./pages/delete-account/delete-account.module").then(
        (m) => m.DeleteAccountPageModule
      ),
    canActivate: [DeleteAccountGuard, AndroidSignupGuard],
  },
  {
    path: "redirect-to-app",
    loadChildren: () =>
      import("./main/pages/AppRedirect/app-redirect.module").then(
        (m) => m.AppRedirectModule
      ),
  },
  {
    path: "line-success",
    loadChildren: () =>
      import("./main/pages/line-login-success/line-login-success.module").then(
        (m) => m.LineLoginSuccessModule
      ),
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "onboarding-intro",
    data: { queryParamsHandling: "merge" },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
