import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appNumberOnlyInput]",
})
export class NumberOnlyInputDirective {
  constructor() {}

  private hasSpecialCharacter(value: string): boolean {
    return /^[\+e\-]/.test(value);
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event): void {
    if (this.hasSpecialCharacter(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent): void {
    let clipboardData = event?.clipboardData;
    let pastedValue = clipboardData?.getData("text");

    if (isNaN(parseInt(pastedValue)) || this.hasSpecialCharacter(pastedValue)) {
      event.preventDefault();
    }
  }
}
