import { Component, Input } from "@angular/core";
import { CompositeTransaction } from "../../models/transaction.model";

@Component({
  selector: "app-recent-transactions",
  templateUrl: "./recent-transactions.component.html",
  styleUrls: ["./recent-transactions.component.scss"],
})
export class RecentTransactionsComponent {
  @Input() transactions: CompositeTransaction[];
  @Input() showAccessFee: boolean;

  constructor() {}
}
