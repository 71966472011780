import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AccountDeletionService } from "../../services/account-detetion.service";

@Component({
  selector: "app-popover-ion-header",
  templateUrl: "./popover-ion-header.component.html",
  styleUrls: ["./popover-ion-header.component.scss"],
})
export class PopoverIonHeaderComponent implements OnInit {
  @Input() defaultHref: string;
  @Input() useCustomEvent: boolean;
  @Input() showCloseIcon: boolean;
  @Input() darkColor: boolean;
  @Output() backBtnClick = new EventEmitter();

  constructor(private accountDeletionService: AccountDeletionService) {
    this.useCustomEvent = false;
  }

  ngOnInit() {}

  showActions(event) {
    this.accountDeletionService.showHeaderActionsPopover(event);
  }

  getBackButtonIcon(): string {
    return this.showCloseIcon ? "close-outline" : null;
  }
}
