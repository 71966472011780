import { Component, Input, OnInit } from "@angular/core";
import { PopoverService } from "../../services/popover.service";
import { openLink } from "../../utils/helpers";

@Component({
  selector: "app-redirect-confirmation-modal",
  templateUrl: "./redirect-confirmation-modal.component.html",
  styleUrls: ["./redirect-confirmation-modal.component.scss"],
})
export class RedirectConfirmationModalComponent implements OnInit {
  @Input() message: string;
  @Input() link: string;

  constructor(private popoverService: PopoverService) {}

  ngOnInit() {}

  stay(): void {
    this.popoverService.dismissPopover({ redirect: false });
  }

  redirect(): void {
    // Safari blocks the popups.
    // Therefore, for that only, we pass the link here, to immediately open it after the user's click (if a user chooses the redirect option)
    if (this.link) {
      window.location.href = this.link;
    }

    this.popoverService.dismissPopover({ redirect: true });
  }
}
