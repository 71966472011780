// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper-button {
  height: 40px;
  width: 40px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: relative;
  background: var(--ion-stepper-prev, #e9e9e9);
  color: var(--ion-color-radio-label, #444444);
}
.stepper-button ion-icon {
  font-size: 20px;
}
.stepper-button.final {
  width: 90px;
}
.stepper-button.final span {
  color: var(--ion-color-white, #ffffff);
}
.stepper-button.next {
  background: var(--ion-color-cta, #5852e3);
}
.stepper-button.next ion-icon {
  color: var(--ion-color-white, #ffffff);
}

.hidden {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/stepper-button/stepper-button.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,oBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,4CAAA;EACA,4CAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,WAAA;AADJ;AAEI;EACE,sCAAA;AAAN;AAIE;EACE,yCAAA;AAFJ;AAII;EACE,sCAAA;AAFN;;AAOA;EACE,wBAAA;AAJF","sourcesContent":[".stepper-button {\n  height: 40px;\n  width: 40px;\n  border-radius: 500px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 8px;\n  position: relative;\n  background: var(--ion-stepper-prev, #e9e9e9);\n  color: var(--ion-color-radio-label, #444444);\n\n  ion-icon {\n    font-size: 20px;\n  }\n\n  &.final {\n    width: 90px;\n    span {\n      color: var(--ion-color-white, #ffffff);\n    }\n  }\n\n  &.next {\n    background: var(--ion-color-cta, #5852e3);\n\n    ion-icon {\n      color: var(--ion-color-white, #ffffff);\n    }\n  }\n}\n\n.hidden {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
