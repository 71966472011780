import { Component, Input, OnInit } from "@angular/core";

import { CountryCodeEnum } from "../../models/country.model";
import { PopoverService } from "../../services/popover.service";
import { openLink } from "../../utils/helpers";
import { ChatService } from "../../services/chat.service";
import { take, tap } from "rxjs";

@Component({
  selector: "app-account-deleted-popup",
  templateUrl: "./account-deleted-popup.component.html",
  styleUrls: ["./account-deleted-popup.component.scss"],
})
export class AccountDeletedPopupComponent implements OnInit {
  @Input()
  country: CountryCodeEnum;

  constructor(
    private popoverService: PopoverService,
    private readonly chatService: ChatService
  ) {}

  ngOnInit() {}

  close(): void {
    this.popoverService.dismissPopover({});
  }

  contactUs(): void {
    this.chatService
      .getContactLink()
      .pipe(
        take(1),
        tap((link) => {
          openLink(link);
          this.close();
        })
      )
      .subscribe();
  }
}
