import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[digitOnly]",
})
export class DigitOnlyDirective {
  @Input() isDigitOnlyEnabled: boolean = true;

  private specialKeys = [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Home",
    "End",
    "Backspace",
    "Delete",
  ];

  constructor(private el: ElementRef) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (!this.isDigitOnlyEnabled) {
      return;
    }

    const e = <KeyboardEvent>event;
    const isNavigationKey = this.specialKeys.indexOf(e.key) !== -1;
    if (!isNavigationKey && !this.isDigitKey(e)) {
      e.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    if (!this.isDigitOnlyEnabled) {
      return;
    }

    const pastedText = event.clipboardData?.getData("text/plain") ?? "";
    if (!this.isDigitString(pastedText)) {
      event.preventDefault();
    }
  }

  @HostListener("document:paste", ["$event"])
  onDocumentPaste(event: ClipboardEvent) {
    if (!this.isDigitOnlyEnabled) {
      return;
    }

    const pastedText = event.clipboardData?.getData("text/plain") ?? "";
    if (!this.isDigitString(pastedText)) {
      event.preventDefault();
    }
  }

  private isDigitKey(event: KeyboardEvent): boolean {
    return /\d/.test(event.key);
  }

  private isDigitString(str: string): boolean {
    return /^\d*$/.test(str);
  }
}
