import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppPages } from "../shared/models/app.model";
import { User } from "../shared/models/user.model";

import { RouterStates } from "../shared/consts";
import { StorageService } from "../shared/services/storage.service";

@Injectable({
  providedIn: "root",
})
export class StateGuard  {
  routerStates = RouterStates;
  constructor(private router: Router, private storageService: StorageService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const user$ = from(this.storageService.get("user"));
    return user$.pipe(
      map((userData: User) => {
        if (!userData) {
          const userState = { page: AppPages.onboardingIntro, status: true };
          this.storageService.set("user", { userState });

          const redirectTo = this.getRouterState(AppPages.onboardingIntro);
          this.router.navigate([`/${redirectTo}`], {
            queryParams: route.queryParams,
          });

          return false;
        }

        const userStatePage = AppPages[userData.userState.page];
        const targetRouterState = this.getRouterState(userStatePage);

        if (state.url.split("?")[0] === `/${targetRouterState}`) {
          return true;
        } else {
          this.router.navigate([`/${targetRouterState}`], {
            queryParams: route.queryParams,
          });
          return false;
        }
      })
    );
  }

  getRouterState(page: AppPages): string {
    return this.routerStates.get(page).redirectTo;
  }
}
