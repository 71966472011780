import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appAlphanumericDashOnly]",
})
export class AlphanumericDashOnlyDirective {
  private isAlphanumericOrDash(value: string): boolean {
    return /^[a-zA-Z0-9-]*$/.test(value);
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event): void {
    const key = event.key.toUpperCase();

    // Prevents non-alphanumeric and non-dash characters, while allowing control keys.
    if (!this.isAlphanumericOrDash(key) && key.length === 1) {
      event.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData;
    const pastedValue = clipboardData?.getData("text").toUpperCase();

    if (!pastedValue || !this.isAlphanumericOrDash(pastedValue)) {
      event.preventDefault();
    }
  }
}
