// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transaction-status {
  padding: 16px 8px;
  display: flex;
  align-items: center;
  flex: none;
  height: 100%;
}
.transaction-status.rejected {
  background: linear-gradient(0deg, rgba(255, 0, 0, 0.02), rgba(255, 0, 0, 0.02)), #ffffff;
}
.transaction-status.rejected .status-icon {
  color: var(--ion-status-color-error, #ff0000);
}
.transaction-status.pending {
  background: linear-gradient(0deg, #fefcf7, #fefcf7), #ffffff;
}
.transaction-status.pending .status-icon {
  color: var(--ion-status-color-pending, #d79c05);
}
.transaction-status.paidBack {
  background: linear-gradient(0deg, rgba(0, 199, 79, 0.02), rgba(0, 199, 79, 0.02)), #ffffff;
}
.transaction-status.paidBack .status-icon {
  color: var(--ion-status-color-success, #00c74f);
}
.transaction-status .status-icon {
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/transaction-status-icon/transaction-status-icon.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,YAAA;AACF;AACE;EACE,wFAAA;AACJ;AAMI;EACE,6CAAA;AAJN;AAQE;EACE,4DAAA;AANJ;AAQI;EACE,+CAAA;AANN;AAUE;EACE,0FAAA;AARJ;AAeI;EACE,+CAAA;AAbN;AAiBE;EACE,eAAA;AAfJ","sourcesContent":[".transaction-status {\n  padding: 16px 8px;\n  display: flex;\n  align-items: center;\n  flex: none;\n  height: 100%;\n\n  &.rejected {\n    background: linear-gradient(\n        0deg,\n        rgba(255, 0, 0, 0.02),\n        rgba(255, 0, 0, 0.02)\n      ),\n      #ffffff;\n\n    .status-icon {\n      color: var(--ion-status-color-error, #ff0000);\n    }\n  }\n\n  &.pending {\n    background: linear-gradient(0deg, #fefcf7, #fefcf7), #ffffff;\n\n    .status-icon {\n      color: var(--ion-status-color-pending, #d79c05);\n    }\n  }\n\n  &.paidBack {\n    background: linear-gradient(\n        0deg,\n        rgba(0, 199, 79, 0.02),\n        rgba(0, 199, 79, 0.02)\n      ),\n      #ffffff;\n\n    .status-icon {\n      color: var(--ion-status-color-success, #00c74f);\n    }\n  }\n\n  .status-icon {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
