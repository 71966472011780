import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { IonInput } from "@ionic/angular";
import { TextFieldTypes } from "@ionic/core";

@Component({
  selector: "app-pin-toggle",
  templateUrl: "./pin-toggle.component.html",
  styleUrls: ["./pin-toggle.component.scss"],
})
export class PinToggleComponent {
  @Input() activateIcon;
  @Input() inputType: TextFieldTypes = "text";
  @Input() toggleInputType = true;
  @Output() visibilityChange = new EventEmitter<boolean>();

  showPin = false;

  @ContentChild(IonInput) pinInput: IonInput;

  constructor() {}

  togglePinVisibility(): void {
    this.showPin = !this.showPin;

    /**
     * Set input type to "password" only if required.
     * In some cases, masking is handled externally by the parent component.
     * This component is only responsible for toggling visibility.
     */
    if (this.toggleInputType) {
      this.pinInput.type = this.showPin ? this.inputType : "password";
    }

    this.visibilityChange.emit(this.showPin);
  }
}
