import { Component, Input, OnInit } from "@angular/core";
import { PopoverService } from "../../services/popover.service";

@Component({
  selector: "app-account-deleted-popup",
  templateUrl: "./account-exists-popup.component.html",
  styleUrls: ["./account-exists-popup.component.scss"],
})
export class AccountExistsPopupComponent implements OnInit {
  constructor(private popoverService: PopoverService) {}
  @Input("method") method;
  ngOnInit() {}

  close(): void {
    this.popoverService.dismissPopover({ action: "close" });
  }

  navigateLogin(): void {
    this.popoverService.dismissPopover({ action: "login" });
  }
}
