// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finverse-link-modal {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  background: var(--ion-color-white, #ffffff);
}
.finverse-link-modal .ui-frame {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/finverse-link-modal/finverse-link-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,2CAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":[".finverse-link-modal {\n  width: 100%;\n  height: 100%;\n  margin: 0px auto;\n  display: flex;\n  flex-direction: column;\n  background: var(--ion-color-white, #ffffff);\n\n  .ui-frame {\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
