import { Component, Input, OnInit } from "@angular/core";
import { PopoverService } from "src/app/shared/services/popover.service";

@Component({
  selector: "app-cancel-verification",
  templateUrl: "./cancel-verification.component.html",
  styleUrls: ["./cancel-verification.component.scss"],
})
export class CancelVerificationComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() hint: string;
  @Input() closeBtnName: string;
  @Input() actionBtnName: string;
  @Input() hideHint: boolean;

  constructor(private popoverService: PopoverService) {}

  ngOnInit() {}

  close(): void {
    this.popoverService.dismissPopover({ action: "close" });
  }

  cancel(): void {
    this.popoverService.dismissPopover({ action: "cancelVerification" });
  }
}
