import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { Observable } from "rxjs";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-referral-modal",
  templateUrl: "./referral-modal.component.html",
  styleUrls: ["./referral-modal.component.scss"],
})
export class ReferralModalComponent implements OnInit {
  @Input() heading: string;
  @Input() description: string;

  referral$: Observable<{ referralLink: string }>;

  constructor(
    private readonly modalController: ModalController,
    private readonly userService: UserService
  ) {}

  ngOnInit() {
    this.referral$ = this.userService.getReferralId();
  }

  private dismissModal(link?: string): void {
    this.modalController.dismiss({ link });
  }

  copyLink(link: string): void {
    this.dismissModal(link);
  }

  closeModal(): void {
    this.dismissModal();
  }
}
