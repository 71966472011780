import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "urlSanitize",
})
export class UrlSanitize implements PipeTransform {
  constructor() {}

  transform(url: string): string {
    return `url("${url}")`;
  }
}
