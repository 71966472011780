// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.contact-us ion-icon {
  font-size: 20px;
  flex: none;
}
.contact-us p {
  font-size: 14px;
  line-height: 19px;
  color: var(--ion-color-required, #666666);
}
.contact-us p a {
  font-weight: 500;
  text-decoration: none;
  color: var(--ion-color-cta, #5852e3);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/contact-us/contact-us.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;AACF;AACE;EACE,eAAA;EACA,UAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;EACA,yCAAA;AAAJ;AAEI;EACE,gBAAA;EACA,qBAAA;EACA,oCAAA;AAAN","sourcesContent":[".contact-us {\n  width: 100%;\n  margin: 0px auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n\n  ion-icon {\n    font-size: 20px;\n    flex: none;\n  }\n\n  p {\n    font-size: 14px;\n    line-height: 19px;\n    color: var(--ion-color-required, #666666);\n\n    a {\n      font-weight: 500;\n      text-decoration: none;\n      color: var(--ion-color-cta, #5852e3);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
