import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { KnackBannerType } from "../../models/knack.model";

@Component({
  selector: "app-get-membership-banner",
  templateUrl: "./get-membership-banner.component.html",
  styleUrls: ["./get-membership-banner.component.scss"],
})
export class GetMembershipBannerComponent implements OnInit {
  @Input() bannerType: string;
  @Output() signUp = new EventEmitter<void>();

  knackBannerTypes = KnackBannerType;
  bannerBackground: string;

  constructor() {}

  get isInsuranceAndBudgeting(): boolean {
    return this.bannerType === this.knackBannerTypes.InsuranceAndBudgeting;
  }

  ngOnInit() {
    this.bannerBackground = "assets/icon/dot-background.svg";
  }

  onButtonClick() {
    this.signUp.emit();
  }
}
