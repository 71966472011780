import { Component, Input, OnInit } from "@angular/core";

import {
  LangChangeEvent,
  TranslatePipe,
  TranslateService,
} from "@ngx-translate/core";
import { Observable } from "rxjs";

import { CompositeTransaction } from "../../models/transaction.model";
import { DateService } from "../../services/date.service";
import { CountryInfo } from "../../models/country.model";
import { UserService } from "../../services/user.service";
import {
  TRANSACTION_DISBURSE_STATUS,
  TRANSACTION_PAYBACK_STATUS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
  TRANSACTION_WITHDRAWAL_SPEED,
} from "../../consts";
import { OnDestroyComponent } from "../on-destroy/on-destroy.component";
import { bankUtil } from "../../utils/bank-util";

@Component({
  selector: "app-composite-transaction",
  templateUrl: "./composite-transaction.component.html",
  styleUrls: ["./composite-transaction.component.scss"],
})
export class CompositeTransactionComponent
  extends OnDestroyComponent
  implements OnInit
{
  @Input() transaction: CompositeTransaction;
  @Input() showStatusIndicator: boolean;
  @Input() showAccessFee: boolean;

  userCountry$: Observable<CountryInfo>;

  status: string; // Transaction Status
  payoutStatus: string;
  payoutStatusClass: string;
  paybackStatus: string;
  paybackStatusClass: string;
  expanded: boolean;
  isMembershipTransaction: boolean;

  processingCost: number;
  transactionDate: string;
  transactionFee: string;
  withdrawalSpeed: string;

  currentLang: string;

  constructor(
    private translatePipe: TranslatePipe,
    private dateService: DateService,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.userCountry$ = this.userService.userCountryWatch$;
    this.currentLang = this.dateService.getCurrentLang();
    this.init();

    this.subscriptions.langChange =
      this.translateService.onLangChange.subscribe(
        (newLang: LangChangeEvent) => {
          this.init();
          this.currentLang = newLang.lang;
        }
      );
  }

  private init(): void {
    this.status =
      this.transaction?.transactionStatus ?? TRANSACTION_STATUS.Rejected;

    this.withdrawalSpeed = this.setWithdrawalSpeed(
      this.transaction?.withdrawalSpeed
    );

    this.payoutStatusClass =
      this.transaction?.disburseStatus ?? TRANSACTION_DISBURSE_STATUS.Rejected;
    this.payoutStatus = this.setPayoutStatus(this.transaction?.disburseStatus);

    this.paybackStatusClass =
      this.transaction?.paybackStatus ??
      TRANSACTION_PAYBACK_STATUS.CollectionFailed;

    this.paybackStatus = this.setPaybackStatus(this.transaction?.paybackStatus);

    this.isMembershipTransaction =
      this.transaction?.transactionType === TRANSACTION_TYPE.Membership;

    this.transactionDate = this.setTransactionDate(
      this.transaction?.transactionType
    );

    this.processingCost = this.getProcessingCost();
  }

  private setPayoutStatus(payoutStatus: string): string {
    switch (payoutStatus) {
      case TRANSACTION_DISBURSE_STATUS.InProgress:
      case TRANSACTION_DISBURSE_STATUS.Accepted:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_STATUS.PENDING"
        );
      case TRANSACTION_DISBURSE_STATUS.PaidOut:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_STATUS.PAID_OUT"
        );
      default:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_STATUS.REJECTED"
        );
    }
  }

  private setPaybackStatus(paybackStatus: string): string {
    switch (paybackStatus) {
      case TRANSACTION_PAYBACK_STATUS.Overpaid:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.OVERPAID"
        );
      case TRANSACTION_PAYBACK_STATUS.Pending:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.PENDING"
        );
      case TRANSACTION_PAYBACK_STATUS.PaidBack:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.PAID_BACK"
        );
      case TRANSACTION_PAYBACK_STATUS.PartiallyPaid:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.PARTIALLY_PAID_BACK"
        );
      case TRANSACTION_PAYBACK_STATUS.CollectionFailedRetry:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.RETRYING_COLLECTION"
        );
      default:
        return this.translatePipe.transform(
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.COLLECTION_FAILED"
        );
    }
  }

  private setTransactionDate(transactionType: string): string {
    if (transactionType == TRANSACTION_TYPE.Membership) {
      const startDate = this.dateService.formatDisplayDate(
        "MMM dd, yyyy",
        this.transaction?.fromDate || new Date()
      );

      const endDate = this.dateService.formatDisplayDate(
        "MMM dd, yyyy",
        this.transaction?.toDate || new Date()
      );

      return `${startDate} - ${endDate}`;
    }

    const dateValue = parseInt(this.transaction?.dateTime, 10) || 0;
    return this.dateService.formatDisplayDate(
      "MMM dd, yyyy",
      this.dateService.convertSecondsToMilliseconds(dateValue)
    );
  }

  private setWithdrawalSpeed(withdrawalSpeed: string): string {
    const type =
      withdrawalSpeed === TRANSACTION_WITHDRAWAL_SPEED.Fast ? "FAST" : "NORMAL";

    return this.translatePipe.transform(
      `TRANSACTIONS.TRANSACTION_WITHDRAWAL_SPEED.${type}`
    );
  }

  private getProcessingCost(): number {
    return (
      (this.transaction?.processingFee || 0) +
      (this.transaction?.fastWithdrawalFee || 0)
    );
  }

  setBankName(countryInfo: CountryInfo): string {
    return bankUtil.getTransactionBankName(
      this.transaction?.bankName,
      countryInfo
    );
  }

  expandDetails(): void {
    this.expanded = !this.expanded;
  }

  getFormattedTransactionDate(date: string): string {
    const timeFormat = this.dateService.getTimeFormat();
    const format = `MMM dd yyyy, ${timeFormat}`;

    const convertedDate = this.dateService.getTimestamp(date);
    return this.dateService.formatDisplayDate(format, convertedDate);
  }

  getFormattedCurrencyCode(countryInfo: CountryInfo): string {
    if (!countryInfo) {
      return null;
    }

    return this.translatePipe.transform(countryInfo?.currency?.currencyCode);
  }
}
