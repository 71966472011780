// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trust-content {
  width: 100%;
  padding: 12px 16px 20px 16px;
  border-radius: 8px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: var(--ion-color-white, #ffffff);
}
.trust-content-header {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  gap: 12px;
}
.trust-content-header img {
  flex: none;
}
.trust-content-header img.sec-icon {
  width: 18px;
}
.trust-content-header img.sec-folder {
  width: 20px;
}
.trust-content-title {
  flex-grow: 1;
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  color: var(--ion-color-black, #000000);
  font-style: normal;
}
.trust-content-content {
  width: 100%;
  margin: 0px auto;
  text-align: left;
  padding: 0;
  font-size: 13px;
  line-height: 18px;
  color: var(--ion-color-required, #666666);
}
.trust-content-content span {
  text-decoration: underline;
  font-weight: 600;
  color: var(--ion-dark-green, #00a912);
}
.trust-content-copy {
  width: 100%;
  margin: 8px auto 0px auto;
  text-align: left;
  font-size: 13px;
  line-height: 18px;
  color: var(--ion-color-input, #cccccc);
}

ion-content {
  --background: var(--ion-color-white, #ffffff);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/trust-content/trust-content.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,4BAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,2CAAA;AACF;AACE;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACE,UAAA;AACN;AACM;EACE,WAAA;AACR;AAEM;EACE,WAAA;AAAR;AAKE;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,sCAAA;EACA,kBAAA;AAHJ;AAME;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;EACA,eAAA;EACA,iBAAA;EACA,yCAAA;AAJJ;AAMI;EACE,0BAAA;EACA,gBAAA;EACA,qCAAA;AAJN;AAQE;EACE,WAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sCAAA;AANJ;;AAUA;EACE,6CAAA;AAPF","sourcesContent":[".trust-content {\n  width: 100%;\n  padding: 12px 16px 20px 16px;\n  border-radius: 8px;\n  margin: 0px auto;\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n  background: var(--ion-color-white, #ffffff);\n\n  &-header {\n    width: 100%;\n    margin: 0px auto;\n    display: flex;\n    align-items: center;\n    gap: 12px;\n\n    img {\n      flex: none;\n\n      &.sec-icon {\n        width: 18px;\n      }\n\n      &.sec-folder {\n        width: 20px;\n      }\n    }\n  }\n\n  &-title {\n    flex-grow: 1;\n    text-align: left;\n    font-size: 16px;\n    line-height: 21px;\n    font-weight: 600;\n    color: var(--ion-color-black, #000000);\n    font-style: normal;\n  }\n\n  &-content {\n    width: 100%;\n    margin: 0px auto;\n    text-align: left;\n    padding: 0;\n    font-size: 13px;\n    line-height: 18px;\n    color: var(--ion-color-required, #666666);\n\n    span {\n      text-decoration: underline;\n      font-weight: 600;\n      color: var(--ion-dark-green, #00a912);\n    }\n  }\n\n  &-copy {\n    width: 100%;\n    margin: 8px auto 0px auto;\n    text-align: left;\n    font-size: 13px;\n    line-height: 18px;\n    color: var(--ion-color-input, #cccccc);\n  }\n}\n\nion-content {\n  --background: var(--ion-color-white, #ffffff);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
