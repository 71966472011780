import { Component, Input, OnInit } from "@angular/core";

import { CountryInfo } from "../../models/country.model";

import { DateService } from "../../services/date.service";
import { CompositeTransaction } from "../../models/transaction.model";
import { TRANSACTION_TYPE } from "../../consts";

@Component({
  selector: "app-upcoming-charges",
  templateUrl: "./upcoming-charges.component.html",
  styleUrls: ["./upcoming-charges.component.scss"],
})
export class UpcomingChargesComponent implements OnInit {
  @Input() upcomingTransactionCharges: CompositeTransaction[];
  @Input() countryInfo: CountryInfo;

  @Input() upcomingTransactionChargesTotal: number;

  transactions: CompositeTransaction[];
  totalDue: number;
  initialValue: number = 0;

  constructor(private dateService: DateService) {}

  ngOnInit() {
    this.transactions = this.upcomingTransactionCharges.map(
      (transaction: CompositeTransaction) => {
        const transactionDate = this.getTransactionDate(transaction);

        const totalFees =
          (transaction.processingFee || this.initialValue) +
          (transaction.fastWithdrawalFee || this.initialValue);

        return {
          ...transaction,
          transactionDate,
          totalFees,
        };
      }
    );

    this.totalDue = this.upcomingTransactionChargesTotal;
  }

  private getTransactionDate(transaction: CompositeTransaction): string {
    if (!transaction) {
      return "";
    }

    if (transaction.transactionType === TRANSACTION_TYPE.Membership) {
      const startDate = this.formatTimeZoneAdjustedDate(transaction.fromDate);
      const endDate = this.formatTimeZoneAdjustedDate(transaction.toDate);
      return `${startDate} - ${endDate}`;
    }

    const timestamp = Number(transaction.dateTime) || 0;
    const dateValue = this.dateService.convertSecondsToMilliseconds(timestamp);
    return this.formatTimeZoneAdjustedDate(dateValue);
  }

  private formatTimeZoneAdjustedDate(date: string | number | Date): string {
    const timeZoneAdjustedDate = this.dateService.getTZAdjustedDate(
      date,
      this.countryInfo.timeZone
    );

    return this.dateService.formatDisplayDate(
      "MMM d, yyyy",
      timeZoneAdjustedDate
    );
  }
}
