import {
  APP_INITIALIZER,
  ChangeDetectorRef,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
  isDevMode,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";

import { RouteReuseStrategy } from "@angular/router";
import {
  DOCUMENT,
  LOCATION_INITIALIZED,
  registerLocaleData,
} from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeTh from "@angular/common/locales/th";
import localeId from "@angular/common/locales/id";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";

import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

import { IonicModule, IonicRouteStrategy, Platform } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { Drivers } from "@ionic/storage";

import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/compat/functions";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import {
  AngularFireRemoteConfigModule,
  SETTINGS as REMOTE_CONFIG_SETTINGS,
} from "@angular/fire/compat/remote-config";

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

import { environment } from "src/environments/environment";
import { RequestInterceptorService } from "./shared/services/request-interceptor.service";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { getErrorHandler } from "../error-handler";
import { take } from "rxjs";
import { StorageService } from "./shared/services/storage.service";
import { LanguageEnum } from "./shared/models/country.model";
import { NgxMaskModule } from "ngx-mask";
import { UserService } from "./shared/services/user.service";

// Register supported locales
registerLocaleData(localeEn, "en");
registerLocaleData(localeTh, "th");
registerLocaleData(localeId, "id");

export function changeDetectorRefFactory() {
  return ChangeDetectorRef;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    "/assets/i18n/",
    ".json?v=" + environment.translationHash
  );
}

export function ApplicationInitializerFactory(
  translate: TranslateService,
  storageService: StorageService,
  injector: Injector
) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    const doc = injector.get(DOCUMENT);

    await storageService.init();
    const user = await storageService.get("user");

    translate.addLangs(Object.values(LanguageEnum));

    const language =
      user &&
        user.language &&
        Object.values(LanguageEnum).includes(user.language)
        ? user.language
        : LanguageEnum.en;

    translate.setDefaultLang(language);
    doc.documentElement.lang = language;

    try {
      translate.use(language).pipe(take(1)).subscribe();
    } catch (err) { }
  };
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
    IonicModule.forRoot({
      backButtonText: "",
    }),
    AppRoutingModule,
    TranslateModule.forRoot(),
    IonicStorageModule.forRoot({
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFireRemoteConfigModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    Platform,
    {
      provide: ChangeDetectorRef,
      useFactory: changeDetectorRefFactory,
    },
    { provide: LOCALE_ID, useValue: "en" },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      // this provider require also importing of HttpClientModule
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService, StorageService, Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeBetaAccessCheck,
      deps: [UserService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: getErrorHandler,
    },
    {
      provide: REMOTE_CONFIG_SETTINGS,
      useFactory: () => ({
        minimumFetchIntervalMillis: environment.minimumFetchIntervalMillis,
        fetchTimeoutMillis: environment.fetchTimeoutMillis,
      }),
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

// Initialize function for beta access check
export function initializeBetaAccessCheck(userService: UserService) {
  return (): Promise<object> => userService.checkBetaTesterAccess();
}
