import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";

import { TranslatePipe } from "@ngx-translate/core";
import { CountryCodeEnum } from "../models/country.model";
import { getCurrencyLocale } from "../utils/helpers";

@Pipe({ name: "customCurrency" })
export class CustomCurrencyPipe implements PipeTransform {
  constructor(
    private decimalPipe: DecimalPipe,
    private translatePipe: TranslatePipe
  ) {}

  transform(
    amount: string | number,
    currencyCode: string,
    country: CountryCodeEnum,
    digitsInfo?: string,
    formattedCurrencyCode?: string
  ): string {
    const currencyLocale = getCurrencyLocale(country);
    const transformAmount = this.decimalPipe.transform(
      amount,
      digitsInfo,
      currencyLocale
    );

    const translateCurrencyCode =
      formattedCurrencyCode || this.translatePipe.transform(currencyCode);

    return `${translateCurrencyCode} ${transformAmount}`;
  }
}
