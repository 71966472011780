export enum AppPages {
  onboardingIntro = "onboardingIntro",
  termsOfService = "termsOfService",
  questionnaire = "questionnaire",
  credentials = "credentials",
  otpPhone = "otpPhone",
  otpEmail = "otpEmail",
  inputPin = "inputPin",
  idVerification = "idVerification",
  idVerificationError = "idVerificationError",
  infoCrossCheck = "infoCrossCheck",
  ktpScan = "ktpScan",
  ktpScanError = "ktpScanError",
  additionalInfo = "additionalInfo",
  additionalProfile = "additionalProfile",
  employment = "employment",
  bankStatements = "bankStatements",
  forwardStatement = "forwardStatement",
  uploadStatementEntry = "uploadStatementEntry",
  uploadStatement = "uploadStatement",
  statementManualCheck = "statementManualCheck",
  statementVerificationError = "statementVerificationError",
  linkBankAccount = "linkBankAccount",
  linkIdBankAccount = "linkIdBankAccount",
  metroBankConfirmation = "metroBankConfirmation",
  metroConfirmationWaiting = "metroConfirmationWaiting",
  linkAccountSuccess = "linkAccountSuccess",
  linkAccountError = "linkAccountError",
  linkAccountWaiting = "linkAccountWaiting",
  uploadConfirmationWaiting = "uploadConfirmationWaiting",
  statementPermanentRejection = "statementPermanentRejection",
  onboardingFinalScreen = "onboardingFinalScreen",
  activeUser = "activeUser",
  withdrawPending = "withdrawPending",
  withdrawRejected = "withdrawRejected",
  withdrawSuccessful = "withdrawSuccessful",
  rejectedUser = "rejectedUser",
  ssoBenefits = "ssoBenefits",
  earnMoney = "earnMoney",
  bankSyncSelection = "bankSyncOptionSelection",
  finverseLinkWaiting = "finverseLinkWaiting",
  finverseLinkEntry = "finverseLinkEntry",
  accountRecovery = "accountRecovery",
  permanentlyDeactivated = "permanentlyDeactivated",
}
