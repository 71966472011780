import { Component, Input, OnInit } from "@angular/core";
import { FEATURE_EXPLAINER_VARIANT } from "../../consts";

@Component({
  selector: "app-feature-explainer-banner",
  templateUrl: "./feature-explainer-banner.component.html",
  styleUrls: ["./feature-explainer-banner.component.scss"],
})
export class FeatureExplainerBannerComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() variant: FEATURE_EXPLAINER_VARIANT;

  bannerBackground: string;

  constructor() {
    this.variant = FEATURE_EXPLAINER_VARIANT.primary;
  }

  ngOnInit() {
    this.bannerBackground = this.setBanerBackground();
  }

  private setBanerBackground(): string {
    if (this.variant === FEATURE_EXPLAINER_VARIANT.primary) {
      return "assets/icon/explainer-banner-purple.svg";
    }

    return "assets/icon/explainer-banner-orange.svg";
  }
}
