import { Injectable, isDevMode } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ModalController } from "@ionic/angular";

import { App, AppInfo } from "@capacitor/app";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import {
  BehaviorSubject,
  catchError,
  from,
  lastValueFrom,
  Observable,
  of,
  switchMap,
  take,
  tap,
  timeout,
} from "rxjs";

import { environment } from "src/environments/environment";

import {
  GetUserByIdResponse,
  MessagingPreference,
  User,
  UserExistsResponse,
  UserPaydayInfo,
  UserProfile,
  UserProfileData,
  UserReferral,
  UserState,
  UserTrackingData,
} from "../models/user.model";

import { InsuranceData, InsuranceRequest } from "../models/tvi.model";

import { COUNTRIES, REQUEST_API, UNKNOWN_COUNTRY_RESPONSE } from "../consts";
import { StorageService } from "./storage.service";
import {
  CountryCode,
  CountryCodeEnum,
  CountryInfo,
  CountryLocale,
  LanguageEnum,
} from "../models/country.model";
import { CountrySelectComponent } from "../components/country-select/country-select.component";
import { getLocalCountryInfo } from "../utils/helpers";
import { AnalyticsService } from "./analytics.service";
import { UserHistory } from "../models/user-history.model";
import { AppPages } from "../models/app.model";
import { PlatformService } from "./platform.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userProfileSubject: BehaviorSubject<UserProfileData>;
  userProfileWatch$: Observable<UserProfileData>;

  userCountrySubject: BehaviorSubject<CountryInfo>;
  userCountryWatch$: Observable<CountryInfo>;

  private countrySelectionCount: number;
  private countryUpdatingCount: number;
  private betaAccessVerified: boolean = false;

  constructor(
    private storageService: StorageService,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private modalController: ModalController,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private platformService: PlatformService
  ) {
    this.userCountrySubject = new BehaviorSubject(null);
    this.userCountryWatch$ = this.userCountrySubject.asObservable();

    this.userProfileSubject = new BehaviorSubject(null);
    this.userProfileWatch$ = this.userProfileSubject.asObservable();

    this.countrySelectionCount = 0;
    this.countryUpdatingCount = 0;
  }

  updateUserProfileSubject(userProfileData: Partial<UserProfileData>): void {
    this.userProfileSubject.next({
      ...this.userProfileSubject.value,
      ...userProfileData,
    });
  }

  async updateLocalUser(user: Partial<User>): Promise<void> {
    const storageUser = await this.storageService.get("user");
    let userUpdate = storageUser ? { ...storageUser, ...user } : user;

    return this.storageService.set("user", userUpdate);
  }

  async updateLocalUserState(userState: UserState): Promise<void> {
    const storageUser = await this.storageService.get("user");
    let userStateUpdate = storageUser
      ? { ...storageUser, userState }
      : { userState };

    return this.storageService.set("user", userStateUpdate);
  }

  updateUser(user: Partial<User>, spinner = true): Observable<User> {
    const headers = spinner
      ? new HttpHeaders()
      : new HttpHeaders().set("hideLoading", "true");

    return this.httpClient.post<User>(
      `${REQUEST_API}/users/this-user/update`,
      {
        ...user,
      },
      { headers }
    );
  }

  updateUserPin(pin: string, newPin: string): Observable<{ status: number }> {
    return this.httpClient.post<{ status: number }>(
      `${REQUEST_API}/users/this-user/pin`,
      {
        pin,
        newPin,
      },
      { headers: { hideError: "true" } }
    );
  }

  createUser(
    user: Partial<User>
  ): Observable<{ userId: string; jwtToken: string }> {
    const trackingCreation = this.getUserTrackingCookies();
    const url = `${REQUEST_API}/users`;

    // If Android, fetch appInfo first
    if (this.platformService.isAndroid()) {
      return from(App.getInfo()).pipe(
        switchMap((appInfo: AppInfo) => {
          return this.httpClient.post<{ userId: string; jwtToken: string }>(
            url,
            {
              user,
              trackingCreation,
              appInfo: {
                build: appInfo.build || "",
                version: appInfo.version || "",
              },
            }
          );
        })
      );
    }

    // Otherwise, create a user directly
    return this.httpClient.post<{ userId: string; jwtToken: string }>(url, {
      user,
      trackingCreation,
    });
  }

  saveAdditionalUserProfile(
    additionalProfile: UserProfile
  ): Observable<{ status: number }> {
    return this.httpClient.post<{ status: number }>(
      `${REQUEST_API}/users/this-user/profile`,
      { additionalProfile }
    );
  }

  saveAdditionalPaydayInfo(
    paydayInfo: UserPaydayInfo
  ): Observable<{ status: number }> {
    return this.httpClient.post<{ status: number }>(
      `${REQUEST_API}/users/this-user/payday-info`,
      { paydayInfo }
    );
  }

  suspendAccount(
    pin: string,
    deletedReason: string,
    deletedReasonInput?: string
  ): Observable<{ userId: string }> {
    return this.httpClient.post<{ userId: string }>(
      `${REQUEST_API}/users/this-user/suspend-account`,
      { pin, deletedReason, deletedReasonInput },
      { headers: { hideError: "true" } }
    );
  }

  /**
   * @deprecated
   */
  checkIfUserEmailExists(email: string): Observable<UserExistsResponse> {
    return this.httpClient.post<UserExistsResponse>(
      `${REQUEST_API}/users/check-if-email-exists`,
      { email }
    );
  }

  /**
   * @deprecated
   */
  checkIfUserPhoneExists(phone: string): Observable<UserExistsResponse> {
    return this.httpClient.post<UserExistsResponse>(
      `${REQUEST_API}/users/check-if-phone-exists`,
      { phone }
    );
  }

  getUserById(): Observable<GetUserByIdResponse> {
    return this.httpClient.get<GetUserByIdResponse>(
      `${REQUEST_API}/users/this-user`
    );
  }

  requestResetPinCode(requestCodeBody) {
    return this.httpClient.post<{ status: boolean }>(
      `${REQUEST_API}/users/this-user/request-pin-reset`,
      requestCodeBody,
      { headers: { hideError: "true" } }
    );
  }

  loginResetPinCode(requestCodeBody) {
    return this.httpClient.post(
      `${REQUEST_API}/auth/login-reset-pin`,
      requestCodeBody,
      { headers: { hideError: "true" } }
    );
  }

  resetPinCode(resetCodeBody) {
    return this.httpClient.put<{ status: boolean }>(
      `${REQUEST_API}/users/this-user/reset-pin`,
      resetCodeBody,
      { headers: { hideError: "true" } }
    );
  }

  loginVerifyPin(verifyCodeBody) {
    return this.httpClient.post<{ resetId: string }>(
      `${REQUEST_API}/auth/login-verify-pin`,
      verifyCodeBody,
      { headers: { hideError: "true" } }
    );
  }

  getUserReferrals(): Observable<UserReferral[]> {
    return this.httpClient.get<UserReferral[]>(`${REQUEST_API}/referrals/`);
  }

  trackLastLogin(): Observable<UserTrackingData> {
    const url = `${REQUEST_API}/users/this-user/track-last-login`;
    const headers = { hideError: "true" };
    const trackingLogin = this.getUserTrackingCookies();

    // If Android, fetch appInfo first
    if (this.platformService.isAndroid()) {
      return from(App.getInfo()).pipe(
        switchMap((appInfo: AppInfo) => {
          return this.httpClient.post<UserTrackingData>(
            url,
            {
              trackingLogin,
              appInfo: {
                build: appInfo.build || "",
                version: appInfo.version || "",
              },
            },
            { headers }
          );
        })
      );
    }

    // Otherwise, track directly
    return this.httpClient.post<UserTrackingData>(
      url,
      {
        trackingLogin,
      },
      { headers }
    );
  }

  checkUserReferral(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${REQUEST_API}/referrals/status`);
  }

  getUserName(user, language?: string): string {
    const userLanguage = language || this.getUserLanguage(user);

    switch (userLanguage) {
      case LanguageEnum.en:
      case LanguageEnum.ph:
      case LanguageEnum.id:
        return user?.fullNameEn || user.fullName;
      default:
        return user.fullNameTh || user.fullName;
    }
  }

  getUserLanguage(user: User): string {
    const url = new URL(window.location.href);
    return (
      url.searchParams.get("lang") || user?.language || environment.defaultLang
    );
  }

  finishLinkingProcess() {
    return this.httpClient.post<{ status: number }>(
      `${REQUEST_API}/users/this-user/finish-linking`,
      {}
    );
  }

  setActivationDate() {
    return this.httpClient.post<{ activationDate: number }>(
      `${REQUEST_API}/users/this-user/activation`,
      {}
    );
  }

  getReferralId(): Observable<{ referralLink: string }> {
    return this.httpClient.get<{ referralLink: string }>(
      `${REQUEST_API}/referrals/id`
    );
  }

  getUserState(spinner = true): Observable<UserState> {
    let headers = spinner
      ? new HttpHeaders()
      : new HttpHeaders().set("hideLoading", "true");

    return this.httpClient.get<UserState>(
      `${REQUEST_API}/users/this-user/state`,
      {
        headers: headers,
      }
    );
  }

  getUserTrackingCookies(): Partial<UserTrackingData> {
    let fbp, fbc, gaClientId;

    fbp = this.getCookie("_fbp");
    fbc = this.getCookie("_fbc");
    gaClientId = this.getCookie("_ga");

    return {
      fbp,
      fbc,
      gaClientId,
    };
  }

  setCountry(country: CountryCodeEnum, userLanguage = ""): CountryInfo {
    // exit if we already have a country setting in progress
    this.countryUpdatingCount += 1;
    if (this.countryUpdatingCount !== 1) {
      return null;
    }

    let countryInfo = getLocalCountryInfo(country);

    if (userLanguage) {
      countryInfo = {
        ...countryInfo,
        language: this.resolvePreferredLanguage(country, userLanguage),
      };
    }

    this.userCountrySubject.next(countryInfo);
    this.translateService.use(countryInfo.language).pipe(take(1)).subscribe();

    // update country setting count
    this.countryUpdatingCount -= 1;
    if (this.countryUpdatingCount < 0) {
      this.countryUpdatingCount = 0;
    }

    this.analyticsService.trackVariable({
      country: countryInfo?.country,
      preferred_language: countryInfo?.language,
    });

    return countryInfo;
  }

  getCountryInfo(timeoutMs: number = 3000): Observable<CountryCode> {
    // @ts-ignore
    return this.httpClient
      .get<CountryCode>(`${REQUEST_API}/users/country-info`)
      .pipe(
        timeout(timeoutMs)
        // catchError((error) => {
        //   console.log('getCountryInfo:error::timeout', error);
        //   if (error.name === "TimeoutError") {
        //     return of({ code: UNKNOWN_COUNTRY_RESPONSE });
        //   }
        //   // Pass along other errors
        //   return throwError(() => error);
        // })
      );
  }

  getInsuranceStatus(): Observable<InsuranceData> {
    return this.httpClient.get<InsuranceData>(
      `${REQUEST_API}/users/this-user/insurance`
    );
  }

  requestInsurance(insuranceRequest: InsuranceRequest) {
    return this.httpClient.post<{ status: boolean }>(
      `${REQUEST_API}/users/this-user/insurance`,
      {
        insuranceRequest,
      },
      { headers: { hideError: "true" } }
    );
  }

  getInsurancePolicyLink(): Observable<{ downloadLink: string }> {
    return this.httpClient.get<{ downloadLink: string }>(
      `${REQUEST_API}/users/this-user/download-insurance`,
      { headers: { hideError: "true" } }
    );
  }

  updateInsurance(insuranceUpdate: Partial<InsuranceRequest>) {
    return this.httpClient.put<{ status: boolean }>(
      `${REQUEST_API}/users/this-user/insurance`,
      {
        insuranceUpdate,
      },
      { headers: { hideError: "true" } }
    );
  }

  verifyId(
    idCardNumber: string,
    updateStateIfValid?: AppPages
  ): Observable<{ isValid: boolean }> {
    return this.httpClient.post<{ isValid: boolean }>(
      `${REQUEST_API}/users/this-user/recovery/verify-id`,
      {
        idCardNumber,
        updateStateIfValid,
      },
      {
        headers: { hideError: "true" },
      }
    );
  }

  activateAccount(): Observable<{ status: boolean }> {
    return this.httpClient.post<{ status: boolean }>(
      `${REQUEST_API}/users/this-user/recovery/activate`,
      {}
    );
  }

  getDeletedAccountHistory(): Observable<Partial<UserHistory>> {
    return this.httpClient.get<Partial<UserHistory>>(
      `${REQUEST_API}/users/this-user/recovery/history`,
      { headers: { hideError: "true" } }
    );
  }

  updateInsuranceReminderStatus(hideInsuranceReminder: boolean) {
    return this.httpClient.post<{ status: boolean }>(
      `${REQUEST_API}/users/this-user/insurance/reminder`,
      {
        hideInsuranceReminder,
      },
      { headers: { hideError: "true" } }
    );
  }

  async handleUnknownCountry(userId: string = ""): Promise<CountryInfo> {
    // exit if we already have a modal opened
    this.countrySelectionCount += 1;
    if (this.countrySelectionCount !== 1) {
      return null;
    }

    const countrySelectComponent = await this.modalController.create({
      component: CountrySelectComponent,
      backdropDismiss: false,
    });

    await countrySelectComponent.present();

    const { data } = await countrySelectComponent.onDidDismiss();

    // update opened modals count
    this.countrySelectionCount -= 1;
    if (this.countrySelectionCount < 0) {
      this.countrySelectionCount = 0;
    }

    if (data && data.code) {
      this.updateLocalUser({
        country: data.code,
      });

      if (userId) {
        this.updateUser({ country: data.code }, false)
          .pipe(take(1))
          .subscribe();
      }

      return this.setCountry(data.code);
    }

    return null;
  }

  private getCookie(cookieName: string): string {
    if (this.cookieService.check(cookieName)) {
      return this.cookieService.get(cookieName);
    }

    return null;
  }

  setMessagingPreference(
    messagingPreference: Pick<MessagingPreference, "allowMarketing">
  ) {
    return this.httpClient.post<{ status: boolean }>(
      `${REQUEST_API}/users/this-user/messaging-preference`,
      messagingPreference,
      { headers: { hideError: "true" } }
    );
  }

  private resolvePreferredLanguage(
    country: CountryCodeEnum,
    userLang: string
  ): string {
    switch (country) {
      case CountryCodeEnum.th:
        return userLang;
      case CountryCodeEnum.id:
        return CountryLocale.id;
      case CountryCodeEnum.ph:
        return CountryLocale.ph;
      default:
        return CountryLocale.en;
    }
  }

  verifyBetaAccessToken(token: string): Observable<{ success: boolean }> {
    //skip API request if token is not provided
    if (!token) {
      return of({ success: false });
    }

    return this.httpClient
      .post<{ success: boolean }>(
        `${REQUEST_API}/auth/verify-beta-access-token`,
        { token },
        { headers: { hideError: "true" } }
      )
      .pipe(
        catchError((err) => {
          //fail silently
          return of({ success: false });
        })
      );
  }

  hasBetaAccess(countryCode: CountryCodeEnum): boolean {
    const country = COUNTRIES.find((ctry) => ctry.id === countryCode);
    if (!country.betaAccessOnly || isDevMode()) {
      return true;
    }
    return this.betaAccessVerified;
  }

  checkBetaTesterAccess(): Promise<object> {
    const urlParams = new URLSearchParams(window.location.search);
    const betaAccessToken = urlParams.get("betaAccessToken") || "";
    const req$ = this.verifyBetaAccessToken(betaAccessToken).pipe(
      tap((res) => {
        this.betaAccessVerified = res.success;
      })
    );
    //Convert observable to promise
    return lastValueFrom(req$);
  }
}
