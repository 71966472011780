// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg {
  width: 46px;
  height: 46px;
  color: var(--ion-color-required, #666666);
}
svg.completed {
  color: var(--ion-dark-green, #00a912);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/svg-icon/svg-icon.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EAEA,yCAAA;AAAF;AAEE;EACE,qCAAA;AAAJ","sourcesContent":["svg {\n  width: 46px;\n  height: 46px;\n\n  color: var(--ion-color-required, #666666);\n\n  &.completed {\n    color: var(--ion-dark-green, #00a912);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
