// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-list-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  margin: 24px auto;
  background: var(--ion-error-list-background);
  border: 1px solid var(--ion-error-list-border-color);
  box-sizing: border-box;
  border-radius: 4px;
}
.error-list-cont .error-list-header {
  width: 100%;
  margin: 2px auto 12px auto;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  color: var(--ion-color-black, #000000);
  padding-bottom: 16px;
  font-weight: 300;
  border-bottom: 1px solid var(--ion-error-list-border-color);
  display: flex;
}
.error-list-cont .error-list-header ion-icon {
  margin-right: 8px;
  color: var(--ion-color-red, #eb5757);
  font-size: 27px;
  position: relative;
  top: -2px;
}
.error-list-cont .error-list-item {
  width: 100%;
  margin: 6px auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: var(--ion-color-black, #000000);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/rejection-message/rejection-message.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;EAEA,WAAA;EACA,iBAAA;EAEA,4CAAA;EACA,oDAAA;EACA,sBAAA;EACA,kBAAA;AADF;AAGE;EACE,WAAA;EACA,0BAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sCAAA;EACA,oBAAA;EACA,gBAAA;EACA,2DAAA;EAEA,aAAA;AAFJ;AAII;EACE,iBAAA;EACA,oCAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;AAFN;AAME;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,sCAAA;AAJJ","sourcesContent":[".error-list-cont {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 16px;\n\n  width: 100%;\n  margin: 24px auto;\n\n  background: var(--ion-error-list-background);\n  border: 1px solid var(--ion-error-list-border-color);\n  box-sizing: border-box;\n  border-radius: 4px;\n\n  .error-list-header {\n    width: 100%;\n    margin: 2px auto 12px auto;\n    text-align: left;\n    font-size: 14px;\n    line-height: 19px;\n    color: var(--ion-color-black, #000000);\n    padding-bottom: 16px;\n    font-weight: 300;\n    border-bottom: 1px solid var(--ion-error-list-border-color);\n\n    display: flex;\n\n    ion-icon {\n      margin-right: 8px;\n      color: var(--ion-color-red, #eb5757);\n      font-size: 27px;\n      position: relative;\n      top: -2px;\n    }\n  }\n\n  .error-list-item {\n    width: 100%;\n    margin: 6px auto;\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    padding: 0px;\n    font-size: 14px;\n    line-height: 19px;\n    font-weight: 600;\n    color: var(--ion-color-black, #000000);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
