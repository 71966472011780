import { Injectable } from "@angular/core";
import { CookieConsentInstance } from "../models/cookie-consent.model";

@Injectable({
  providedIn: "root",
})
export class CookieConsentService {
  constructor() {}

  showCookieSettings(): void {
    const cookieConsentInstance = this.getCookieConsentInstance();
    if (cookieConsentInstance) {
      cookieConsentInstance.show();
    }
  }

  isCookieConsentAvailable(): boolean {
    return !!this.getCookieConsentInstance();
  }

  changeLanguage(selectedLang: string): void {
    const cookieConsentInstance = this.getCookieConsentInstance();
    if (cookieConsentInstance) {
      cookieConsentInstance.currentLang = selectedLang;
      cookieConsentInstance.applyTranslation();
    }
  }

  private getCookieConsentInstance(): CookieConsentInstance {
    return (window as any).CookieScript?.instance as CookieConsentInstance;
  }
}
