import { Component, OnInit } from "@angular/core";
import { PopoverService } from "../../services/popover.service";
import { CookieConsentService } from "../../services/cookie-consent.service";

@Component({
  selector: "app-header-actions",
  templateUrl: "./header-actions.component.html",
  styleUrls: ["./header-actions.component.scss"],
})
export class HeaderActionsComponent implements OnInit {
  isCookieConsentAvailable: boolean;

  constructor(
    private popoverService: PopoverService,
    private cookieConsentService: CookieConsentService
  ) {}

  ngOnInit() {
    this.isCookieConsentAvailable =
      this.cookieConsentService.isCookieConsentAvailable();
  }

  logout(): void {
    this.popoverService.dismissPopover({ action: "logout" });
  }

  deleteAccount(): void {
    this.popoverService.dismissPopover({ action: "deleteAccount" });
  }

  showCookieSettings(): void {
    this.popoverService.dismissPopover({ action: "cookieConsent" });
  }
}
