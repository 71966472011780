import { Component, OnInit } from "@angular/core";
import { PopoverService } from "../../services/popover.service";
import { openLink } from "../../utils/helpers";
import { SEC_LISTING_LINK_PH } from "../../consts";

@Component({
  selector: "app-trust-content",
  templateUrl: "./trust-content.component.html",
  styleUrls: ["./trust-content.component.scss"],
})
export class TrustContentComponent implements OnInit {
  constructor(private popoverService: PopoverService) {}

  ngOnInit() {}

  close(): void {
    this.popoverService.dismissPopover({});
  }

  openSecLink() {
    openLink(SEC_LISTING_LINK_PH);
    this.close();
  }
}
