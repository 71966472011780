// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
:host a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}
:host a .icon-show-pin,
:host a .icon-hide-pin {
  margin-left: 12px;
  height: 22px;
  flex-shrink: 0;
  opacity: 0.3;
}
:host a .icon-show-pin.active,
:host a .icon-hide-pin.active {
  opacity: 1 !important;
}

.hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/pin-toggle/pin-toggle.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAF;AAEE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAAJ;AAEI;;EAEE,iBAAA;EACA,YAAA;EACA,cAAA;EACA,YAAA;AAAN;AAEM;;EACE,qBAAA;AACR;;AAKA;EACE,aAAA;AAFF","sourcesContent":[":host {\n  width: 100%;\n  margin: auto;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  a {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 4px;\n\n    .icon-show-pin,\n    .icon-hide-pin {\n      margin-left: 12px;\n      height: 22px;\n      flex-shrink: 0;\n      opacity: 0.3;\n\n      &.active {\n        opacity: 1 !important;\n      }\n    }\n  }\n}\n\n.hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
