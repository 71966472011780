import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { DateService } from "src/app/shared/services/date.service";
import { THAI_WEEKDAYS, THAI_MONTHS } from "../../consts";

/**
 * @deprecated
 */
@Component({
  selector: "app-custom-calendar-modal",
  templateUrl: "./custom-calendar-modal.component.html",
  styleUrls: ["./custom-calendar-modal.component.scss"],
})
export class CustomCalendarModalComponent implements OnInit {
  @Input() selectedDate: string;
  @Input() title: string;

  @Input() minDate: string;
  @Input() maxDate: string;

  date: string;
  options: {};

  constructor(
    private dateService: DateService,
    private modalCtrl: ModalController
  ) {
    this.title = "SELECT_DATE";
  }

  ngOnInit(): void {
    if (!this.maxDate) {
      this.maxDate = this.dateService.addDays(new Date(), 45).toISOString();
    }

    if (this.selectedDate) {
      const dateSelected = new Date(this.selectedDate);
      this.date = new Date(
        dateSelected.getTime() - dateSelected.getTimezoneOffset() * 60000
      ).toISOString();
    } else {
      this.date = this.dateService.addDays(new Date(), 1).toISOString();
    }

    this.options = {
      from: this.minDate || new Date("1920-01-01"),
      to: this.maxDate,
      weekStart: 1,
      pickMode: "single",
    };

    if (this.dateService.getCurrentLang() === "th") {
      this.dateService.setMomentLocale("th");
      this.options["weekdays"] = THAI_WEEKDAYS;
      this.options["monthPickerFormat"] = THAI_MONTHS;
    }
  }

  select() {
    this.modalCtrl.dismiss({
      date: this.dateService.formatDate("dd MMM, yyyy", this.date),
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      date: "",
    });
  }
}
