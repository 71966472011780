import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-svg-icon",
  templateUrl: "./svg-icon.component.html",
  styleUrls: ["./svg-icon.component.scss"],
})
export class SvgIconComponent implements OnInit {
  link: string;

  @Input() completed: boolean;
  @Input() name: string;

  constructor() {}

  ngOnInit(): void {
    this.link = `/assets/icon/${this.name}.svg#${this.name}`;
  }
}
