import { Component, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-finverse-link-modal",
  templateUrl: "./finverse-link-modal.component.html",
  styleUrls: ["./finverse-link-modal.component.scss"],
})
export class FinverseLinkModalComponent implements OnInit, OnDestroy {
  @Input() link: string;
  src: SafeResourceUrl;

  constructor(
    private modalController: ModalController,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(this.link);
    this.renderer.listen("window", "message", (event) => {
      this.receiveMessage(event);
    });
  }

  ngOnDestroy(): void {
    this.renderer.destroy();
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  receiveMessage(event: MessageEvent): void {
    if (event.data === "close") {
      this.closeModal();
    }
    if (event.data === "success") {
      this.modalController.dismiss({ success: true });
    }
  }
}
