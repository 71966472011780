// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entry-anim {
  background: var(--ion-color-white, #ffffff);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px 24px 24px 24px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.entry-anim .ewa-footer {
  margin-top: auto;
}
.entry-anim .lottie-player {
  margin-top: auto;
}
.entry-anim-content {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding: 36px 24px;
}
.entry-anim-content .title {
  width: 100%;
  margin: 0px auto;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--ion-color-contact-title, #303478);
}
.entry-anim-content .desc {
  max-width: 320px;
  width: 100%;
  margin: 0px auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: var(--ion-color-previous, #888888);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/entry-animation/entry-animation.component.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;EACA,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,4BAAA;EACA,aAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,WAAA;EACA,gBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;AAHJ;AAKI;EACE,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EAEA,8CAAA;AAJN;AAOI;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EAEA,yCAAA;AANN","sourcesContent":[".entry-anim {\n  background: var(--ion-color-white, #ffffff);\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  padding: 24px 24px 24px 24px;\n  z-index: 9999;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n\n  .ewa-footer {\n    margin-top: auto;\n  }\n\n  .lottie-player {\n    margin-top: auto;\n  }\n\n  &-content {\n    width: 100%;\n    margin: 0px auto;\n\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    gap: 8px;\n    padding: 36px 24px;\n\n    .title {\n      width: 100%;\n      margin: 0px auto;\n      font-style: normal;\n      font-weight: 900;\n      font-size: 24px;\n      line-height: 32px;\n      text-align: center;\n\n      color: var(--ion-color-contact-title, #303478);\n    }\n\n    .desc {\n      max-width: 320px;\n      width: 100%;\n      margin: 0px auto;\n      font-style: normal;\n      font-weight: normal;\n      font-size: 16px;\n      line-height: 21px;\n      text-align: center;\n\n      color: var(--ion-color-previous, #888888);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
