import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-filter-pill",
  templateUrl: "./filter-pill.component.html",
  styleUrls: ["./filter-pill.component.scss"],
})
export class FilterPillComponent implements OnInit {
  @Input() value: string;
  @Output() removeValue = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  clearTag() {
    this.removeValue.emit(this.value);
  }
}
