import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { TranslatePipe } from "@ngx-translate/core";

import { environment } from "src/environments/environment";
import { showConnectionBanner } from "./shared/utils/helpers";

@Injectable()
export class CompositeErrorHandler implements ErrorHandler {
  private errorHandler: ErrorHandler;

  constructor(private injector: Injector) {
    if (environment.production) {
      this.errorHandler = new Sentry.SentryErrorHandler();
    } else {
      this.errorHandler = new ErrorHandler();
    }
  }

  private async handleChunkError(): Promise<void> {
    try {
      if ("caches" in window) {
        const cacheNames = await caches.keys();
        await Promise.all(cacheNames.map((name) => caches.delete(name)));
      }
    } catch (cacheError) {
      console.error("Error while clearing caches:", cacheError);
    } finally {
      window.location.reload();
    }
  }

  handleError(error: any): void {
    const translatePipe = this.injector.get(TranslatePipe);
    const connectivityErrorMessage =
      translatePipe.transform("CONNECTION_ISSUE");

    // Handle HttpErrorResponse (Unknown Errors)
    if (error instanceof HttpErrorResponse && error.status === 0) {
      // return to escape sentry noise
      return;
    }

    // Handle Firebase Config Errors
    if (
      error.message?.includes("Remote Config: Fetch client failed to connect")
    ) {
      showConnectionBanner(connectivityErrorMessage);
      // return to escape sentry noise
      return;
    }

    // Handle Chunk Load Errors
    const chunkFailedMessage = /Loading chunk [^\s]+ failed[\s\S]*$/;
    const errorMessage = error?.message || error?.toString();

    if (chunkFailedMessage.test(errorMessage)) {
      this.handleChunkError();
      // return to escape sentry noise
      return;
    }

    this.errorHandler.handleError(error);
  }
}
