import { Injectable } from "@angular/core";
import { from, map, of, switchMap, take, tap } from "rxjs";

import { GTM_EVENT_NAMES, GTM_FUNNEL_VERSION } from "../consts";
import { StorageService } from "./storage.service";
import { LoginMethod } from "../models/login.model";
import { AuthService } from "./auth.service";
import { DateService } from "./date.service";
import { User } from "../models/user.model";
import { UNKNOWN_VERSION, VersionService } from "./native-app-version.service";
import { PlatformService } from "./platform.service";

declare const dataLayer: any;

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(
    private readonly storageService: StorageService,
    private readonly authService: AuthService,
    private readonly dateService: DateService,
    private readonly nativeAppVersionService: VersionService,
    private readonly platformService: PlatformService
  ) {}

  trackVariable(variable: any): void {
    dataLayer.push(variable);
  }

  trackStep(
    step: number,
    eventName: string,
    stepName: string,
    funnel_version: number = GTM_FUNNEL_VERSION
  ) {
    dataLayer.push({
      event: eventName,
      step,
      step_name: stepName,
      funnel_version,
    });
  }

  /**
   * Tracks the user's session activity by triggering an event whenever the user logs in or returns to the app after being away.
   * The event is triggered if the user logs in or if they revisit the app after 30 minutes have passed since the last session update.
   *
   * @param {LoginMethod} [logInMethod] - Optional identifier for the login method used, included in tracking data if available.
   *
   * @remarks
   * This function:
   * - Checks if the user is logged in with Firebase authentication.
   * - Retrieves the last session update timestamp from storage.
   * - Triggers an event if more than 30 minutes have elapsed since the last recorded session update or if this is a fresh login.
   * - Updates the session timestamp in storage when the event is triggered.
   *
   *
   * @returns {void}
   */
  trackSessionUpdate(logInMethod?: LoginMethod) {
    this.authService
      .getFireAuthUser()
      .pipe(
        take(1),
        map((firebaseUser) => !!firebaseUser?.uid),
        switchMap((isLoggedIn) => {
          if (!isLoggedIn) {
            return of(null);
          }

          return from(this.storageService.get("lastSessionUpdateDate")).pipe(
            map(
              (lastSessionDate) =>
                !lastSessionDate ||
                this.dateService.hasElapsedMinutes(lastSessionDate, 30)
            )
          );
        }),
        switchMap((shouldUpdate) => {
          if (!shouldUpdate) {
            return of(null);
          }

          return from(this.storageService.get("user") as Promise<User>).pipe(
            tap((user) => {
              if (user?.userState) {
                // set last session update date
                this.storageService.set(
                  "lastSessionUpdateDate",
                  new Date().getTime()
                );

                this.trackVariable({
                  event: GTM_EVENT_NAMES.userLoggedIn,
                  user_state: user.userState.page,
                  ...(logInMethod && { login_method: logInMethod }),
                });
              }
            })
          );
        })
      )
      .subscribe();
  }

  /**
   * Identifies the user's platform and updates Clevertap user properties.
   *
   * This function determines the user's platform (web or native app) and retrieves the app version if available.
   * It then pushes the data to dataLayer for GTM tracking .
   *
   * @returns {void}
   */
  identifyUserPlatform(): void {
    const platformType = this.platformService.isWeb() ? "web" : "android_app";
    const appVersion = this.getAppVersion();

    const data: Record<string, string> = {
      last_platform_used: platformType,
      last_app_version: appVersion,
    };

    // Track the properties
    this.trackVariable(data);
  }

  /**
   * Retrieves the app version if the platform is Android and the version is known.
   *
   * @returns {string | null} The app version or null if unknown.
   */
  private getAppVersion(): string | null {
    if (this.platformService.isWeb()) {
      return null; // No app version available on web platform.
    }

    return this.nativeAppVersionService.versionName !== UNKNOWN_VERSION
      ? this.nativeAppVersionService.versionName
      : null;
  }

  /**
   * Converts a date to a Clevertap-compatible date format for tracking purposes.
   *
   * @remarks
   * This function takes a date as input and returns a string in the format `$D_{timestamp}`,
   * where `{timestamp}` is the Unix timestamp of the input date.
   *
   * @param {string | Date} date - The date to be converted. Can be a string in ISO 8601 format, a timestamp or a Date object.
   *
   * @returns {string} The converted date in the format `$D_{timestamp}`.
   */
  formatClevertapDate(date: string | number | Date): string {
    const timestamp = this.dateService.getTimestamp(date);
    return `$D_${timestamp}`;
  }
}
