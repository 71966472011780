import { Input, Component, OnInit } from "@angular/core";

import {
  LangChangeEvent,
  TranslatePipe,
  TranslateService,
} from "@ngx-translate/core";

import { Observable } from "rxjs";

import {
  TRANSACTION_WITHDRAWAL_SPEED,
  TRANSACTION_DISBURSE_STATUS,
  TRANSACTION_PAYBACK_STATUS,
} from "../../consts";

import { CountryInfo, LanguageEnum } from "../../models/country.model";
import { KnackTransaction } from "../../models/knack.model";

import { DateService } from "../../services/date.service";
import { UserService } from "../../services/user.service";
import { OnDestroyComponent } from "../on-destroy/on-destroy.component";
import { bankUtil } from "../../utils/bank-util";

@Component({
  selector: "app-transaction",
  templateUrl: "./transaction.component.html",
  styleUrls: ["./transaction.component.scss"],
})
export class TransactionComponent extends OnDestroyComponent implements OnInit {
  @Input() transaction: KnackTransaction;
  @Input() showAccessFee: boolean;

  expanded: boolean;
  transactionDate: string;

  paybackStatus: string;
  paybackStatusClass: string;
  status: string;
  statusClass: string;
  withdrawalSpeed: string;

  transactionCompleteStatus: string;
  currentLang: string;

  userCountry$: Observable<CountryInfo>;
  transactionAmount: any;

  constructor(
    private dateService: DateService,
    private translatePipe: TranslatePipe,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    this.userCountry$ = this.userService.userCountryWatch$;
    this.currentLang = this.dateService.getCurrentLang();
    this.setViewDetails(this.currentLang);

    this.subscriptions.langChange =
      this.translateService.onLangChange.subscribe(
        (newLang: LangChangeEvent) => {
          this.setViewDetails(newLang.lang);
        }
      );
  }

  expandDetails(): void {
    this.expanded = !this.expanded;
  }

  setBankName(countryInfo: CountryInfo): string {
    return bankUtil.getTransactionBankName(
      this.transaction?.bankName,
      countryInfo
    );
  }

  private setViewDetails(currentLang: string): void {
    this.currentLang = currentLang;
    this.setTransactionDate(currentLang);
    this.setStatus();
    this.setPaybackStatus();
    this.setTransactionCompleteStatus();
    this.setWithdrawalSpeed();
  }

  private setStatus(): void {
    let statusName;
    switch (this.transaction.disburseStatus) {
      case TRANSACTION_DISBURSE_STATUS.PaidOut:
        statusName = "TRANSACTIONS.TRANSACTION_STATUS.PAID_OUT";
        this.statusClass = "success";
        break;
      case TRANSACTION_DISBURSE_STATUS.InProgress:
        statusName = "TRANSACTIONS.TRANSACTION_STATUS.PENDING";
        this.statusClass = "pending";
        break;
      case TRANSACTION_DISBURSE_STATUS.Accepted:
        statusName = "TRANSACTIONS.TRANSACTION_STATUS.PENDING"; // 'Accepted' status should have the same style as the 'InProgress' one
        this.statusClass = "pending";
        break;
      default:
        statusName = "TRANSACTIONS.TRANSACTION_STATUS.REJECTED";
        this.statusClass = "error";
        break;
    }

    this.status = this.translatePipe.transform(statusName);
  }

  private setWithdrawalSpeed(): void {
    let withdrawalSpeed = "TRANSACTIONS.TRANSACTION_WITHDRAWAL_SPEED.NORMAL";

    if (this.transaction.withdrawalSpeed == TRANSACTION_WITHDRAWAL_SPEED.Fast) {
      withdrawalSpeed = "TRANSACTIONS.TRANSACTION_WITHDRAWAL_SPEED.FAST";
    }

    this.withdrawalSpeed = this.translatePipe.transform(withdrawalSpeed);
  }

  private setPaybackStatus(): void {
    let statusName;
    switch (this.transaction.paybackStatus) {
      case TRANSACTION_PAYBACK_STATUS.Overpaid:
        statusName = "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.OVERPAID";
        this.paybackStatusClass = "overpaid";
        break;
      case TRANSACTION_PAYBACK_STATUS.Pending:
        statusName = "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.PENDING";
        this.paybackStatusClass = "pending";
        break;
      case TRANSACTION_PAYBACK_STATUS.PaidBack:
        statusName = "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.PAID_BACK";
        this.paybackStatusClass = "success";
        break;
      case TRANSACTION_PAYBACK_STATUS.PartiallyPaid:
        statusName =
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.PARTIALLY_PAID_BACK";
        this.paybackStatusClass = "partial-success";
        break;
      case TRANSACTION_PAYBACK_STATUS.CollectionFailedRetry:
        statusName =
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.RETRYING_COLLECTION";
        this.paybackStatusClass = "partial-error";
        break;
      default:
        statusName =
          "TRANSACTIONS.TRANSACTION_PAYBACK_STATUS.COLLECTION_FAILED";
        this.paybackStatusClass = "error";
        break;
    }

    this.paybackStatus = this.translatePipe.transform(statusName);
  }

  private setTransactionCompleteStatus(): void {
    const isDisburseRejected =
      this.transaction.disburseStatus === TRANSACTION_DISBURSE_STATUS.Rejected;

    const isPaybackFailed =
      this.transaction.paybackStatus ===
        TRANSACTION_PAYBACK_STATUS.CollectionFailed ||
      this.transaction.paybackStatus ===
        TRANSACTION_PAYBACK_STATUS.CollectionFailedRetry;

    this.transactionCompleteStatus =
      isDisburseRejected || isPaybackFailed ? "error" : this.statusClass;
  }

  private setTransactionDate(currentLang: string): void {
    const timeFormat = this.dateService.getTimeFormat(
      currentLang as LanguageEnum
    );
    const format = `MMM dd yyyy, ${timeFormat}`;

    this.transactionDate = this.dateService.formatDisplayDate(
      format,
      this.dateService.convertSecondsToMilliseconds(this.transaction.dateTime)
    );
  }

  getFormattedCurrencyCode(countryInfo: CountryInfo): string {
    if (!countryInfo) {
      return null;
    }

    return this.translatePipe.transform(countryInfo?.currency?.currencyCode);
  }
}
