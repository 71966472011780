import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { CountryInfo } from "../../models/country.model";

@Component({
  selector: "app-advance-upcoming-charges-notification",
  templateUrl: "./advance-upcoming-charges-notification.component.html",
  styleUrls: ["./advance-upcoming-charges-notification.component.scss"],
})
export class AdvanceUpcomingChargesNotificationComponent implements OnInit {
  @Input() countryInfo: CountryInfo;
  @Input() upcomingTransactionChargesTotal: number;
  @Output() showUpcomingCharges = new EventEmitter();

  totalDue: number;

  constructor() {}

  ngOnInit() {
    this.totalDue = this.upcomingTransactionChargesTotal;
  }

  onClick(): void {
    this.showUpcomingCharges.emit();
  }
}
