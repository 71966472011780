import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { from, Observable, of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { AppPages } from "../shared/models/app.model";
import {
  NOT_ALLOWED_TO_DELETE_ACCOUNT_STATES,
  RouterStates,
} from "../shared/consts";
import { StorageService } from "../shared/services/storage.service";
import { AuthService } from "../shared/services/auth.service";
import { UserService } from "../shared/services/user.service";

@Injectable({
  providedIn: "root",
})
export class DeleteAccountGuard  {
  routerStates = RouterStates;
  constructor(
    private router: Router,
    private storageService: StorageService,
    private auth: AuthService,
    private userService: UserService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const checkFirebaseUser$ = this.auth.getFireAuthUser();
    const user$ = from(this.storageService.get("user"));

    return zip([checkFirebaseUser$, user$]).pipe(
      switchMap(([firebaseUser, userData]) => {
        if (!firebaseUser || !firebaseUser.uid || !userData) {
          const userState = {
            page: AppPages.onboardingIntro,
            status: true,
          };

          this.storageService.set("user", { userState });

          const isIntroPage = state.url === "/onboarding-intro";
          if (isIntroPage) {
            return of(true);
          }

          const redirectTo = this.getRouterState(AppPages.onboardingIntro);
          this.router.navigate([`/${redirectTo}`], {
            queryParams: route.queryParams,
          });

          return of(false);
        }

        return this.userService.getUserState().pipe(
          map((userState) => {
            if (NOT_ALLOWED_TO_DELETE_ACCOUNT_STATES.includes(userState.page)) {
              const redirectTo = this.getRouterState(
                userState.page || AppPages.onboardingIntro
              );

              this.router.navigate([`/${redirectTo}`], {
                queryParams: route.queryParams,
              });

              return false;
            }

            return true;
          })
        );
      })
    );
  }

  getRouterState(page: AppPages): string {
    return this.routerStates.get(page).redirectTo;
  }
}
