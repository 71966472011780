import { Component, ContentChild } from "@angular/core";
import { IonInput } from "@ionic/angular";

@Component({
  selector: "app-pin-toggle",
  templateUrl: "./pin-toggle.component.html",
  styleUrls: ["./pin-toggle.component.scss"],
})
export class PinToggleComponent {
  showPin = false;

  @ContentChild(IonInput) pinInput: IonInput;

  constructor() {}

  togglePinVisibility(): void {
    this.showPin = !this.showPin;
    this.pinInput.type = this.showPin ? "text" : "password";
  }
}
