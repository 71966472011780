import { Injectable } from "@angular/core";

import { PopoverOptions } from "@ionic/angular";
import { TranslatePipe } from "@ngx-translate/core";
import { filter, from, map, Observable, of, switchMap } from "rxjs";

import { UserService } from "./user.service";
import { StorageService } from "./storage.service";
import { CountryCodeEnum, CountryInfo } from "../models/country.model";
import { User } from "../models/user.model";
import { ChatPopupComponent } from "../components/chat-popup/chat-popup.component";
import { PopoverService } from "./popover.service";
import { openLink } from "../utils/helpers";
import { ChatType } from "../models/chat.model";
import { CountryChatMap } from "../consts";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  constructor(
    private readonly userService: UserService,
    private readonly translatePipe: TranslatePipe,
    private readonly storageService: StorageService,
    private readonly popoverService: PopoverService
  ) {}

  private getWhatsappLink(baseUrl: string): Observable<string> {
    return from(this.storageService.get("user")).pipe(
      map((user: User) => user?.userId ?? ""),
      map((userId) => {
        const text = this.translatePipe.transform(
          "CONTACT_US.WHATSAPP_MESSAGE",
          {
            userId,
          }
        );

        const encodedMessage = encodeURIComponent(text);
        return `${baseUrl}?text=${encodedMessage}`;
      })
    );
  }

  getContactLink(): Observable<string> {
    return this.userService.userCountryWatch$.pipe(
      filter((countryInfo: CountryInfo) => !!countryInfo),
      switchMap((countryInfo: CountryInfo) => {
        const { contactUsLink, country } = countryInfo;

        if (country === CountryCodeEnum.id) {
          return this.getWhatsappLink(contactUsLink);
        }

        return of(contactUsLink);
      })
    );
  }

  async showChatPopup(action: string, country: CountryCodeEnum): Promise<void> {
    const chatType = CountryChatMap[country] || ChatType.LINE;

    const options: PopoverOptions = {
      component: ChatPopupComponent,
      componentProps: { action, chatType },
      backdropDismiss: true,
      showBackdrop: true,
      animated: true,
      cssClass: "chat-popup",
    };

    const chatPopup = await this.popoverService.createPopover(options);
    await chatPopup.present();

    const { data } = await chatPopup.onDidDismiss();
    if (data?.link) {
      openLink(data.link);
    }
  }
}
