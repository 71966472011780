// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verification-step {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
}
.verification-step.current {
  background: var(--ion-color-page, #f9f9f9);
  border: 2px solid var(--ion-tab-background);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);
}
.verification-step.completed {
  border: 2px solid var(--ion-color-page);
}
.verification-step.completed .step-count,
.verification-step.completed .step-title {
  color: var(--ion-color-country-code, #00a912) !important;
}
.verification-step .step-icon {
  width: 46px;
  height: 46px;
  flex: none;
  margin-right: 16px;
}
.verification-step .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.verification-step .content .step-count {
  width: 100%;
  margin: 0px auto 2px auto;
  font-size: 10px;
  line-height: 12px;
  color: var(--ion-color-required, #666666);
}
.verification-step .content .step-title {
  width: 100%;
  margin: 0px auto;
  font-size: 14px;
  line-height: 19px;
  color: var(--ion-color-radio-label, #444444);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/verification-step/verification-step.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,0CAAA;EACA,2CAAA;EACA,sBAAA;EACA,iDAAA;AACJ;AAEE;EACE,uCAAA;AAAJ;AACI;;EAEE,wDAAA;AACN;AAGE;EACE,WAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AAFJ;AAII;EACE,WAAA;EACA,yBAAA;EACA,eAAA;EACA,iBAAA;EAEA,yCAAA;AAHN;AAMI;EACE,WAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,4CAAA;AAJN","sourcesContent":[".verification-step {\n  width: 100%;\n  margin: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 4px 8px;\n  border-radius: 4px;\n\n  &.current {\n    background: var(--ion-color-page, #f9f9f9);\n    border: 2px solid var(--ion-tab-background);\n    box-sizing: border-box;\n    box-shadow: 0px 4px 4px rgba(197, 197, 197, 0.25);\n  }\n\n  &.completed {\n    border: 2px solid var(--ion-color-page);\n    .step-count,\n    .step-title {\n      color: var(--ion-color-country-code, #00a912) !important;\n    }\n  }\n\n  .step-icon {\n    width: 46px;\n    height: 46px;\n    flex: none;\n    margin-right: 16px;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n\n    .step-count {\n      width: 100%;\n      margin: 0px auto 2px auto;\n      font-size: 10px;\n      line-height: 12px;\n\n      color: var(--ion-color-required, #666666);\n    }\n\n    .step-title {\n      width: 100%;\n      margin: 0px auto;\n      font-size: 14px;\n      line-height: 19px;\n      color: var(--ion-color-radio-label, #444444);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
