import { Injectable } from "@angular/core";

import * as Sentry from "@sentry/angular-ivy";
import { environment } from "src/environments/environment";
import { User } from "../models/user.model";

@Injectable({
  providedIn: "root",
})
export class LoggerService {
  constructor() {}

  logError(error: unknown): void {
    if (environment.production) {
      Sentry.captureException(error);
    }

    console.error(error);
  }

  updateSentryScope(user: Partial<User>): void {
    if (environment.production) {
      const userData = {
        email: user?.email || null,
        id: user?.userId || null,
      };

      const scope = Sentry.getCurrentScope();

      scope.setUser(userData);
      scope.setTags(userData);
    }
  }
}
