import { CountryCodeEnum } from "../../models/country.model";
import { ValidationMessage } from "../../models/validation-message.model";

export const COUNTRY_VALIDATIONS: Partial<
  Record<CountryCodeEnum, { [controlName: string]: ValidationMessage[] }>
> = {
  [CountryCodeEnum.ph]: {
    bankAccount: [
      { type: "pattern", message: "PH.ERRORS.BANK_ACCOUNT_PATTERN" },
      { type: "maxlength", message: "PH.ERRORS.PENDING_BANK_ACCOUNT_LENGTH" },
    ],
    averageSalary: [
      { type: "min", message: "PH.ERRORS.AVERAGE_SALARY_AMOUNT" },
    ],
  },
  [CountryCodeEnum.id]: {
    phone: [{ type: "pattern", message: "ID.ERRORS.INVALID_PHONE" }],
  },
};
