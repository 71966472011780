import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  private requestsAmount: number;
  isLoading$: Subject<boolean>;

  constructor() {
    this.requestsAmount = 0;
    this.isLoading$ = new Subject();
  }

  show(): void {
    this.requestsAmount += 1;

    if (this.requestsAmount === 1) {
      this.isLoading$.next(true);
    }
  }

  hide(): void {
    this.requestsAmount -= 1;
    if (this.requestsAmount < 0) {
      this.requestsAmount = 0;
    }

    if (this.requestsAmount === 0) {
      this.isLoading$.next(false);
    }
  }
}
