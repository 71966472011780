// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#action-trigger-btn ion-icon {
  color: var(--ion-color-contact-title, #303478) !important;
  font-size: 21px;
}

.offset-left {
  margin-left: 8px;
}

ion-title {
  padding: 0px 44px;
}

ion-back-button {
  color: var(--ion-color-contact-title, #303478) !important;
  --color-focused: var(--ion-color-contact-title, #303478);
  --color-hover: var(--ion-color-contact-title, #303478);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/popover-ion-header/popover-ion-header.component.scss"],"names":[],"mappings":"AACE;EACE,yDAAA;EACA,eAAA;AAAJ;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,yDAAA;EACA,wDAAA;EACA,sDAAA;AADF","sourcesContent":["#action-trigger-btn {\n  ion-icon {\n    color: var(--ion-color-contact-title, #303478) !important;\n    font-size: 21px;\n  }\n}\n\n.offset-left {\n  margin-left: 8px;\n}\n\nion-title {\n  padding: 0px 44px;\n}\n\nion-back-button {\n  color: var(--ion-color-contact-title, #303478) !important;\n  --color-focused: var(--ion-color-contact-title, #303478);\n  --color-hover: var(--ion-color-contact-title, #303478);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
