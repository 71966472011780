import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-no-transaction",
  templateUrl: "./no-transaction.component.html",
  styleUrls: ["./no-transaction.component.scss"],
})
export class NoTransactionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
