import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "read-more",
  templateUrl: "./read-more.component.html",
  styleUrls: ["./read-more.component.scss"],
})
export class ReadMoreComponent implements AfterViewInit {
  @Output() expandEvent = new EventEmitter<boolean>();
  @Input() text = "";
  @Input() numberOfLines = 1;
  @Input() numberOfLinesForMobile = this.numberOfLines;

  maxHeightPx: number;
  isCollapsed = false;
  isCollapsable = false;
  width: number;
  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const currentHeight =
      this.elementRef.nativeElement.getElementsByTagName("div")[0].offsetHeight;
    const lineHeight = getComputedStyle
      ? +getComputedStyle(this.elementRef.nativeElement).lineHeight.replace(
          "px",
          ""
        )
      : null;
    this.maxHeightPx = lineHeight * this.numberOfLines;

    if (currentHeight > this.maxHeightPx) {
      this.isCollapsed = true;
      this.isCollapsable = true;
    }
    this.cdr.detectChanges();
  }

  toggleText() {
    this.isCollapsed = !this.isCollapsed;
    this.expandEvent.emit(this.isCollapsed);
  }
}
