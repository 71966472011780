// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-bubble {
  margin-bottom: 24px;
  margin-right: 12px;
}
.chat-bubble.shift {
  margin-bottom: 72px;
}
.chat-bubble img {
  width: 32px;
}

.line-chat {
  --background: var(--ion-line-chat-color, #41c50a);
  --background-activated: var(--ion-line-chat-color-dark, #36a300);
}

.facebook-chat {
  --background: var(--ion-fb-chat-color, #1877f2);
  --background-activated: var(--ion-fb-chat-bg, #3b5998);
}

.whatsapp-chat {
  --background: var(--ion-whatsapp-chat-bg, #0d9f16);
  --background-activated: var(--ion-whatsapp-chat-bg-dark, #0a7f12);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/chat-bubble/chat-bubble.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,WAAA;AAAJ;;AAIA;EACE,iDAAA;EACA,gEAAA;AADF;;AAIA;EACE,+CAAA;EACA,sDAAA;AADF;;AAIA;EACE,kDAAA;EACA,iEAAA;AADF","sourcesContent":[".chat-bubble {\n  margin-bottom: 24px;\n  margin-right: 12px;\n\n  &.shift {\n    margin-bottom: 72px;\n  }\n\n  img {\n    width: 32px;\n  }\n}\n\n.line-chat {\n  --background: var(--ion-line-chat-color, #41c50a);\n  --background-activated: var(--ion-line-chat-color-dark, #36a300);\n}\n\n.facebook-chat {\n  --background: var(--ion-fb-chat-color, #1877f2);\n  --background-activated: var(--ion-fb-chat-bg, #3b5998);\n}\n\n.whatsapp-chat {\n  --background: var(--ion-whatsapp-chat-bg, #0d9f16);\n  --background-activated: var(--ion-whatsapp-chat-bg-dark, #0a7f12);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
