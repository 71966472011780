import { Component, OnInit } from "@angular/core";
import { ChatService } from "../../services/chat.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.scss"],
})
export class ContactUsComponent implements OnInit {
  contactLink$: Observable<string>;

  constructor(private readonly chatService: ChatService) {}

  ngOnInit() {
    this.contactLink$ = this.chatService.getContactLink();
  }
}
