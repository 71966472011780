// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-pill {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 16px;
  gap: 0px;
  background: rgba(239, 120, 47, 0.07);
  border: 1px solid var(--ion-withdraw-rejection, #ef772e);
  border-radius: 500px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.filter-pill .value {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--ion-color-black, #000000);
}
.filter-pill .clear-btn {
  color: var(--ion-color-required, #666666);
}
.filter-pill .clear-btn ion-icon {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/filter-pill/filter-pill.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,QAAA;EAEA,oCAAA;EACA,wDAAA;EACA,oBAAA;EAEA,UAAA;EACA,QAAA;EACA,YAAA;AADF;AAGE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sCAAA;AADJ;AAIE;EACE,yCAAA;AAFJ;AAII;EACE,eAAA;AAFN","sourcesContent":[".filter-pill {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 0px 0px 0px 16px;\n  gap: 0px;\n\n  background: rgba(239, 120, 47, 0.07);\n  border: 1px solid var(--ion-withdraw-rejection, #ef772e);\n  border-radius: 500px;\n\n  flex: none;\n  order: 0;\n  flex-grow: 0;\n\n  .value {\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 16px;\n    color: var(--ion-color-black, #000000);\n  }\n\n  .clear-btn{\n    color: var(--ion-color-required, #666666);\n\n    ion-icon{\n      font-size: 16px;\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
