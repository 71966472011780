import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

import { TranslatePipe } from "@ngx-translate/core";
import { CountryCodeEnum } from "../models/country.model";
import { getCurrencyLocale } from "../utils/helpers";

@Pipe({ name: "currencyStyles" })
export class CurrencyStylesPipe implements PipeTransform {
  html: string;

  constructor(
    private sanitizer: DomSanitizer,
    private decimalPipe: DecimalPipe,
    private translatePipe: TranslatePipe
  ) {}

  transform(
    amount: number,
    currencyCode: string,
    digitsInfo: string,
    country: CountryCodeEnum,
    amountClass: string,
    currencyClass: string,
    formattedCurrencyCode?: string
  ): SafeHtml {
    const currencyLocale = getCurrencyLocale(country);
    const transformAmount = this.decimalPipe.transform(
      amount,
      digitsInfo,
      currencyLocale
    );

    const translateCurrencyCode =
      formattedCurrencyCode || this.translatePipe.transform(currencyCode);

    if (country && country === CountryCodeEnum.th) {
      this.html = `<span class="${amountClass}">${transformAmount}</span><span class="${currencyClass}">${translateCurrencyCode}</span>`;
    } else {
      this.html = `<span class="${currencyClass}">${translateCurrencyCode}</span><span class="${amountClass}">${transformAmount}</span>`;
    }

    return this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.sanitizer.bypassSecurityTrustHtml(this.html)
    ) as SafeHtml;
  }
}
