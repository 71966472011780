import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-stepper-button",
  templateUrl: "./stepper-button.component.html",
  styleUrls: ["./stepper-button.component.scss"],
})
export class StepperButtonComponent implements OnInit {
  @Input() next?: boolean;
  @Input() final: boolean;

  @Output() stepperClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick(): void {
    this.stepperClick.emit();
  }
}
