import { Component, Input, OnInit } from "@angular/core";

import { PopoverService } from "../../services/popover.service";

@Component({
  selector: "app-account-does-not-exist-popup",
  templateUrl: "./account-does-not-exist.component.html",
  styleUrls: ["./account-does-not-exist.component.scss"],
})
export class AccountDoesNotExistPopupComponent implements OnInit {
  @Input("method") method;

  title: string;
  description: string;

  constructor(private popoverService: PopoverService) {}

  ngOnInit() {
    this.title = `VERIFICATION.ACCOUNT_DOES_NOT_EXIST_POPUP.${
      this.method === "phone" ? "TITLE_PHONE" : "TITLE_EMAIL"
    }`;

    this.description = `VERIFICATION.ACCOUNT_DOES_NOT_EXIST_POPUP.${
      this.method === "phone" ? "DESC_PHONE" : "DESC_EMAIL"
    }`;
  }

  close(): void {
    this.popoverService.dismissPopover({});
  }
}
