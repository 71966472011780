import { Component } from "@angular/core";

import { Subject } from "rxjs";

import { LoaderService } from "../../services/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  showLoading$: Subject<boolean>;
  
  constructor(private loaderService: LoaderService) {
    this.showLoading$ = this.loaderService.isLoading$;
  }
}
