export enum CountryCodeEnum {
  th = "TH",
  ph = "PH",
  id = "ID",
}

export enum CountryLocale {
  th = "th-th",
  thEN = "th-en",
  ph = "ph-en",
  id = "id-id",
  en = "en",
}

export interface CountryCode {
  code: CountryCodeEnum;
}

export enum LanguageEnum {
  en = "en",
  th = "th",
  ph = "ph-en",
  id = "id-id",
}

export interface CountryInfo {
  country: CountryCodeEnum;
  flagIcon: string;
  language: string;
  phone: string;
  phoneCode: string;
  validationRules: any;
  currency: CountryCurrency;
  locale: CountryLocale;
  contactUsLink: string;
  timeZoneOffset: string;
  timeZone: string;
  zipCodeLength: number;
  phoneNumberPlaceholder: string;
}

export interface CountryCurrency {
  currencyCode: string;
  digitsInfo: string;
  currencySymbol: string;
}

export interface Country {
  title: string;
  icon: string;
  id: CountryCodeEnum;
  betaAccessOnly?: boolean;
}
