import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: "root",
})
export class PlatformService {
  constructor(private platform: Platform) {}

  isAndroid(): boolean {
    return this.platform.is("android") && Capacitor.isNativePlatform();
  }

  isWeb(): boolean {
    return (
      !this.isAndroid() &&
      (this.platform.is("mobileweb") || this.platform.is("desktop"))
    );
  }
}
