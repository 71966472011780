import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { CountryCodeEnum } from "../../models/country.model";
import { ValidationMessage } from "../../models/validation-message.model";

import { FORM_VALIDATION } from "./form-validation.const";
import { COUNTRY_VALIDATIONS } from "./form-validation-country.const";

@Component({
  selector: "app-validation-error",
  templateUrl: "./validation-error.component.html",
  styleUrls: ["./validation-error.component.scss"],
})
export class ValidationErrorComponent implements OnInit {
  validationMessageList = FORM_VALIDATION;

  @Input() form!: UntypedFormGroup;
  @Input() controlName!: string;
  @Input() country: CountryCodeEnum;

  validationMessages: ValidationMessage[];

  constructor() {}

  ngOnInit(): void {
    this.validationMessages = this.getValidationMessages(
      this.country,
      this.controlName
    );
  }

  checkIfInvalid(validationType: string): boolean {
    const control = this.form.get(this.controlName);
    if (
      control &&
      (control.dirty || control.touched) &&
      control.invalid &&
      control.errors
    ) {
      const errors = Object.keys(control.errors);
      return errors[0] === validationType;
    }

    return false;
  }

  getValidationMessages(country: CountryCodeEnum, controlName: string) {
    const defaultValidation = FORM_VALIDATION;
    const countryValidation = COUNTRY_VALIDATIONS[country] || null;

    const countryValidationMessages = countryValidation
      ? countryValidation[controlName]
      : null;

    const defaultMessages = defaultValidation[controlName];

    // Merge messages from both instances, giving priority to country-specific messages
    if (countryValidationMessages) {
      const nonConflictingDefaultMessages = defaultMessages.filter(
        (defaultMessage) =>
          !countryValidationMessages.some(
            (countryMessage) => countryMessage.type === defaultMessage.type
          )
      );

      return countryValidationMessages.concat(nonConflictingDefaultMessages);
    }

    return defaultMessages;
  }
}
