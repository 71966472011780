import { Component, OnInit } from "@angular/core";
import { PopoverService } from "../../services/popover.service";

@Component({
  selector: "app-confirm-skip-id-card",
  templateUrl: "./confirm-skip-id-card.component.html",
  styleUrls: ["./confirm-skip-id-card.component.scss"],
})
export class ConfirmSkipIdCardComponent implements OnInit {
  constructor(private popoverService: PopoverService) {}

  ngOnInit() {}

  handleClick(action: string): void {
    this.popoverService.dismissPopover({ action });
  }
}
