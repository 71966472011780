import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user.service";
import { CountryCodeEnum, CountryInfo } from "../../models/country.model";

@Directive({
  selector: "[appShowToPhUserOnly]",
})
export class ShowToPhUserOnlyDirective implements OnInit, OnDestroy {
  private subscription!: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.subscription = this.userService.userCountryWatch$.subscribe(
      (countryInfo: CountryInfo) => {
        this.viewContainer.clear();
        if (countryInfo.country === CountryCodeEnum.ph) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
