import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { ChatConfig, ChatType } from "../../models/chat.model";
import { ChatTypesConfig } from "../../consts";

@Component({
  selector: "app-chat-bubble",
  templateUrl: "./chat-bubble.component.html",
  styleUrls: ["./chat-bubble.component.scss"],
  animations: [
    trigger("slideInOut", [
      state("void", style({ transform: "translateX(100%)", opacity: 0 })),
      transition(":enter, :leave", [
        animate(
          "0.5s ease-in-out",
          style({ transform: "translateX(0)", opacity: 1 })
        ),
      ]),
    ]),
  ],
})
export class ChatBubbleComponent implements OnInit {
  @Input() chatType: ChatType;
  @Input() adjustChatBubblePosition: boolean = false;
  @Output() onChatOpen = new EventEmitter<void>();

  chatConfig: ChatConfig;

  constructor() {}

  ngOnInit(): void {
    this.chatConfig = ChatTypesConfig[this.chatType];
  }
}
