// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-banner {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
}
.feature-banner .figure {
  flex: none;
  width: 44px;
}
.feature-banner-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.feature-banner-content .title {
  width: 100%;
  margin: 0px auto;
  color: var(--ion-color-black, #000000);
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
}
.feature-banner-content .desc {
  width: 100%;
  margin: 0px auto;
  padding: 0px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--ion-color-required, #666666);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/feature-explainer-banner/feature-explainer-banner.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;EACA,kBAAA;AACF;AACE;EACE,UAAA;EACA,WAAA;AACJ;AAEE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AAAJ;AAEI;EACE,WAAA;EACA,gBAAA;EACA,sCAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAN;AAGI;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yCAAA;AADN","sourcesContent":[".feature-banner {\n  width: 100%;\n  margin: 0px auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n  padding: 16px;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  border-radius: 8px;\n\n  .figure {\n    flex: none;\n    width: 44px;\n  }\n\n  &-content {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n\n    .title {\n      width: 100%;\n      margin: 0px auto;\n      color: var(--ion-color-black, #000000);\n      font-style: normal;\n      font-weight: 700;\n      font-size: 15px;\n      line-height: 20px;\n    }\n\n    .desc {\n      width: 100%;\n      margin: 0px auto;\n      padding: 0px;\n      font-weight: 400;\n      font-size: 12px;\n      line-height: 16px;\n      color: var(--ion-color-required, #666666);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
