export enum CountryCodeEnum {
  th = "TH",
  ph = "PH",
  id = "ID"
}

export enum LanguageEnum {
  en = "en",
  th = "th",
  ph = "ph-en",
  id = "id-id"
}

export interface CountryInfo {
  country: CountryCodeEnum;
  flagIcon: string;
  language: string;
  phone: string;
  phoneCode: string;
  validationRules: any;
  phoneNumberPlaceholder: string;
}
