import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";
import { catchError, finalize, switchMap, take } from "rxjs/operators";

import { LoaderService } from "./loader.service";
import { ToasterService } from "./toaster.service";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { DEFAULT_LANG } from "../consts";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthService } from "./auth.service";
import { AccountDeletionService } from "./account-detetion.service";
import { NavController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class RequestInterceptorService implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private toasterService: ToasterService,
    private angularFireAuth: AngularFireAuth,
    private authService: AuthService,
    private router: Router,
    private injector: Injector,
    private accountDeletionService: AccountDeletionService,
    private navController: NavController
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const translateService = this.injector.get(TranslateService); // Interceptor depends on translate service. But the TranslateService might not be available during initialization

    if (!req.headers.get("hideLoading")) {
      this.loaderService.show();
    }

    if (req.body && req.body.userId) {
      delete req.body.userId;
    }

    return this.angularFireAuth.idToken.pipe(
      take(1),
      switchMap((idToken) => {
        const headers = {
          lang: translateService.currentLang ?? DEFAULT_LANG,
        };

        if (idToken) {
          headers["Authorization"] = `Bearer ${idToken}`;
        }

        let clone = req.clone({ setHeaders: headers, withCredentials: true });
        return next.handle(clone).pipe(
          catchError((err) => {
            if (!req.headers.get("hideError")) {
              this.handleError(err);
            }

            throw err;
          }),
          finalize(() => {
            this.loaderService.hide();
          })
        );
      })
    );
  }

  private async handleError(errResponse: HttpErrorResponse): Promise<void> {
    const errorMessage = await this.getErrorMessage(errResponse);
    if (errResponse.status !== 401) {
      this.toasterService.error(errorMessage);
    }
  }

  private async getErrorMessage(
    errResponse: HttpErrorResponse
  ): Promise<string> {
    let errorMessage = "Something went wrong";
    const translatePipe = this.injector.get(TranslatePipe); // Interceptor depends on translate service. So the TranslatePipe might not be available during initialization

    if (errResponse.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${errResponse.error.message}`;
    } else {
      // server-side error
      errorMessage = `Status code: ${errResponse.status}. Message: ${errResponse?.error?.message}`;
      //on unauthorize logout the user
      if (errResponse.status === 401) {
        await this.authService.logout();
        this.router.navigate([`/login`]);
      }

      // too many requests
      if (errResponse.status === 429) {
        errorMessage = translatePipe
          ? translatePipe.transform("TOO_MANY_REQUESTS")
          : errResponse.error;
      }

      // user is deleted
      if (
        errResponse.error?.status === 404 &&
        errResponse.error?.message === "Your account has been deleted"
      ) {
        errorMessage = translatePipe
          ? translatePipe.transform("ACCOUNT.DELETED")
          : "เราได้ลบบัญชีของคุณแล้ว";

        this.accountDeletionService.showAccountDeletedPopover(
          errResponse.error?.country
        );
        this.router.navigate([`/`]);
        await this.authService.logout();
      }

      if (errResponse.error?.status === 400) {
        errorMessage = errResponse.error.message;
      }

      if (errResponse?.error?.message?.includes("EASILY_GUESSED_PIN")) {
        errorMessage = translatePipe.transform(errResponse.error.message);
      }
    }

    return errorMessage;
  }
}
