import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { utcToZonedTime } from "date-fns-tz";
import { PH_COUNTRY_INFO } from "src/app/countries/ph";
import { TH_COUNTRY_INFO } from "src/app/countries/th";

import {
  COUNT_TO_SHOW,
  MASKING_CHARACTER,
  POLICY_LINK,
  POLICY_LINK_ID,
  TERMS_LINK,
  TERMS_LINK_ID,
  USER_IDENTITY_INFO_FIELDS,
} from "../consts";

import {
  CountryCodeEnum,
  CountryInfo,
  CountryLocale,
  LanguageEnum,
} from "../models/country.model";

import { Translation } from "../models/translate-param.model";
import { ID_COUNTRY_INFO } from "src/app/countries/id";

export const getFileBufferClone = async (
  file: File,
  customFileName: string = ""
): Promise<File> => {
  try {
    const buffer = await file.arrayBuffer();
    return new File([buffer], customFileName || file.name, {
      type: file.type,
    });
  } catch (error) {
    return await cloneFileFallback(file, customFileName);
  }
};

/**
 * Clones a File object by creating a new File with the same content.
 * If the original file's `arrayBuffer` method fails, it falls back to using `readAsDataURL`.
 * This method uses `FileReader` API is used to read the original file.
 *
 * @async
 * @param {File} file - The original File object to be cloned.
 * @param {string} The custom file name for the cloned File.
 * @returns {Promise<File|null>} A Promise that resolves with the cloned File.
 */
const cloneFileFallback = async (
  file: File,
  customFileName: string
): Promise<File | null> => {
  return new Promise<File>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const dataURL = event.target.result;
      const clonedFile = new File([dataURL], customFileName || file.name, {
        type: file.type,
      });
      resolve(clonedFile);
    };

    reader.onerror = () => {
      reject(null);
    };

    reader.readAsDataURL(file);
  });
};

export const getFileNameFromPath = (filePath: string): string => {
  return filePath.split("/").pop();
};

export const removeStringSpaces = (str: string): string => {
  return str.replace(/\s/g, "");
};

export const maskString = (str: string): string => {
  const formatString = removeStringSpaces(str);
  const maskingLength = formatString.length - COUNT_TO_SHOW;

  return formatString
    .split("")
    .map((str, index) => {
      if (index < maskingLength) {
        str = `${MASKING_CHARACTER}`;
      }

      return str;
    })
    .join("");
};

export const validateIdCardNumber = (idCardNumber: string): boolean => {
  const idWithoutSpaces = removeStringSpaces(idCardNumber);

  if (!/^[0-9]{13}$/g.test(idWithoutSpaces)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += Number.parseInt(idWithoutSpaces.charAt(i)) * (13 - i);
  }

  const checkSum = (11 - (sum % 11)) % 10;
  if (checkSum === Number.parseInt(idWithoutSpaces.charAt(12))) {
    return true;
  }

  return false;
};

export const clearPhoneNumber = (phoneNumber: string): string => {
  if (phoneNumber && phoneNumber.includes("+")) {
    return phoneNumber.replace("+", "");
  }

  return phoneNumber;
};

export const calculateTipPercentAmount = (
  percent: number,
  total: number
): number => {
  return Math.round(percent * 0.01 * total);
};

export const calculateTipPercent = (amount: number, total: number): number => {
  return parseFloat(((amount * 100) / total).toFixed(2));
};

export const getDynamicLink = (
  deeplink: string,
  appVersion: string | number
): string => {
  return `https://ewaservices.page.link/?link=${deeplink}&apn=com.finn.app&amv=${appVersion}&afl=${deeplink}`;
};

export const calculateReadMinutes = (wordCount: number): number => {
  if (!wordCount) {
    return 0;
  }

  const wordsPerMinute = 200; // Average case.
  return Math.ceil(wordCount / wordsPerMinute);
};

export const validateUserIdentityInfo = (identityInfo: any) => {
  for (const key in identityInfo) {
    if (!USER_IDENTITY_INFO_FIELDS.includes(key)) {
      delete identityInfo[key];
    }
  }

  return identityInfo;
};

export const maskEmail = (emailStr: string) => {
  if (!emailStr) {
    return "";
  }

  const replace = emailStr.split("").map((str, index, array) => {
    if (index !== 0 && index !== array.length - 1) {
      return "*";
    }

    return str;
  });

  return replace.join("");
};

export const maskPhone = (phoneNumber: string) => {
  if (!phoneNumber) {
    return "";
  }

  const first3 = phoneNumber.substring(0, 3);
  const last3 = phoneNumber.substring(phoneNumber.length - 3);
  const mask = phoneNumber
    .substring(3, phoneNumber.length - 3)
    .replace(/\d/g, "*");

  return `${first3}${mask}${last3}`;
};

export const getOSPlatform = () => {
  const userAgent = navigator.userAgent;
  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "IOS";
  }
  return "ANDROID";
};

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const isPlatformNative = () => Capacitor.isNativePlatform();

export const openLink = (link: string): void => {
  if (isPlatformNative()) {
    Browser.open({ url: link });
  } else {
    setTimeout(() => window.open(link, "_blank", "noopener"), 500);
  }
};

export const extractReferralCode = (url: string): string => {
  // Regular expression pattern to match the referral code
  const pattern = /\/([a-f0-9-]+)$/i;

  // Extract the referral code using the pattern
  const match = url.match(pattern);

  if (match && match.length > 1) {
    return match[1];
  }

  return null;
};

export const mergeTranslations = (
  th_v1: Translation,
  th_v2: Translation
): Translation => {
  const mergedTranslations: Translation = {};

  // Merge translations from th_v1 that don't exist in th_v2
  for (const key in th_v1) {
    if (!(key in th_v2)) {
      mergedTranslations[key] = th_v1[key];
    } else {
      const v1Value = th_v1[key];
      const v2Value = th_v2[key];

      if (typeof v1Value === "object" && typeof v2Value === "object") {
        // Recursively merge nested objects
        mergedTranslations[key] = mergeTranslations(
          v1Value as Translation,
          v2Value as Translation
        );
      } else {
        mergedTranslations[key] = v2Value;
      }
    }
  }

  // Merge translations from th_v2 and update existing translations
  for (const key in th_v2) {
    if (!(key in th_v1)) {
      mergedTranslations[key] = th_v2[key];
    }
  }

  return mergedTranslations;
};

export const getLocale = (
  country: CountryCodeEnum,
  lang?: string
): CountryLocale => {
  switch (country) {
    case CountryCodeEnum.ph:
      return PH_COUNTRY_INFO.locale;
    case CountryCodeEnum.id:
      return ID_COUNTRY_INFO.locale;
    case CountryCodeEnum.th:
      return lang && lang === LanguageEnum.en
        ? CountryLocale.thEN
        : TH_COUNTRY_INFO.locale;
    default:
      return PH_COUNTRY_INFO.locale;
  }
};

export const getCurrencyLocale = (country: CountryCodeEnum): string => {
  switch (country) {
    case CountryCodeEnum.id:
      return "id";
    case CountryCodeEnum.th:
      return "th";
    default:
      return "en";
  }
};

export const getLocalCountryInfo = (country: CountryCodeEnum): CountryInfo => {
  switch (country) {
    case CountryCodeEnum.ph:
      return PH_COUNTRY_INFO;
    case CountryCodeEnum.id:
      return ID_COUNTRY_INFO;
    default:
      return TH_COUNTRY_INFO;
  }
};

export const getTermsLink = (
  country: CountryCodeEnum,
  locale: CountryLocale
): string => {
  if (country === CountryCodeEnum.id) {
    return TERMS_LINK_ID;
  }

  return `${TERMS_LINK}?locale=${locale}`;
};

export const getPolicyLink = (
  country: CountryCodeEnum,
  locale: CountryLocale
): string => {
  if (country === CountryCodeEnum.id) {
    return POLICY_LINK_ID;
  }

  return `${POLICY_LINK}?locale=${locale}`;
};

export const validateOnEnglishString = (
  str: string,
  userCountry: CountryCodeEnum
): string => {
  if (!str) {
    return str;
  }

  let regex = /[^a-zA-Z\s.-]/g;

  if (userCountry === CountryCodeEnum.ph) {
    regex = regex = /[^a-zA-ZÑñ\s.-]/g;
  }

  const validCharacters = str.replace(regex, "");

  return validCharacters;
};

export const validateOnThaiString = (str: string): string => {
  if (!str) {
    return str;
  }

  const regex = /[^\u0E00-\u0E7F\s.-]/g;
  const validCharacters = str.replace(regex, "");

  return validCharacters;
};

/**
 * Used to convert object values to milliseconds.
 * The conversion will be done for fields, specified in the `fields` array.
 * @param obj
 * @param fields
 */
export const convertObjectFieldsToMilliseconds = (
  obj: any,
  fields: string[]
) => {
  const convertSecondsToMilliseconds = (seconds: number) => {
    return seconds * 1000;
  };

  if (typeof obj !== "object" || !Array.isArray(fields)) {
    return {};
  }

  const objectCopy = Object.assign({}, obj);

  fields.forEach((fieldName: string) => {
    if (objectCopy[fieldName]) {
      objectCopy[fieldName] = Array.isArray(objectCopy[fieldName]) // if the field's value is an array, iterate and convert numbers to ms
        ? objectCopy[fieldName].map((item) => {
            return typeof item === "number"
              ? convertSecondsToMilliseconds(item)
              : item;
          })
        : convertSecondsToMilliseconds(objectCopy[fieldName]);
    }
  });

  return objectCopy;
};

/**
 * Used to convert object values to a specific timezone.
 * The conversion will be done for fields, specified in the `fields` array.
 * @param obj
 * @param fields
 * @param timezone
 */
export const convertFieldsInSpecificTimezone = (
  obj: any,
  fields: string[],
  timezone: string
) => {
  if (typeof obj !== "object" || !Array.isArray(fields)) {
    return obj;
  }

  const objectCopy = Object.assign({}, obj);

  fields.forEach((fieldName: string) => {
    if (objectCopy[fieldName]) {
      objectCopy[fieldName] = Array.isArray(objectCopy[fieldName]) // if the field's value is an array
        ? objectCopy[fieldName].map((item) => {
            return typeof item === "number"
              ? utcToZonedTime(item, timezone)
              : item;
          })
        : utcToZonedTime(objectCopy[fieldName], timezone);
    }
  });

  return objectCopy;
};

/**
 * Formats the insurance policy number into a specific pattern.
 * @param {string} policyNumber - The insurance policy number to format.
 * @returns {string} The formatted insurance policy number.
 */
export const formatInsurancePolicyNumber = (policyNumber: string): string => {
  if (!policyNumber) {
    return "";
  }

  return `${policyNumber.substring(0, 6)}/${policyNumber.substring(
    6,
    10
  )}/${policyNumber.substring(10, 15)}-${policyNumber.substring(15)}`;
};

export const replaceCountryCode = (
  phone: string,
  countryCode: string,
  replacement: string = "0"
): string => {
  if (!phone || !countryCode) {
    return "";
  }

  return phone.includes(countryCode)
    ? phone.replace(countryCode, replacement)
    : phone;
};

export const replaceZeroWithCountryCode = (
  phone: string,
  countryCode: string
) => {
  // Create a regular expression that matches 0 at the start of the string
  const regex = /^0/;
  // Replace 0 with the country code
  return phone.replace(regex, countryCode);
};

/**
 * Removes all non-alphanumeric characters from the input string.
 *
 * @param input - The string to be sanitized, containing potential non-alphanumeric characters.
 * @returns A new string containing only alphanumeric characters from the input.
 */
export const sanitizeAlphanumericInput = (input: string): string => {
  if (input == null) {
    return "";
  }

  return String(input).replace(/[^a-zA-Z0-9]/g, "");
};

export const defer = (fn: () => void, ms: number = 5000) => setTimeout(fn, ms);

export function showConnectionBanner(message: string, duration: number = 5000) {
  const bannerId = "network-issue-banner";

  // Prevent multiple banners
  if (document.getElementById(bannerId)) {
    return;
  }

  // Create the banner
  const banner = document.createElement("div");
  banner.id = bannerId;
  banner.style.position = "fixed";
  banner.style.bottom = "3%";
  banner.style.width = "90%";
  banner.style.margin = "0 auto";
  banner.style.left = "0";
  banner.style.right = "0";
  banner.style.backgroundColor = "red";
  banner.style.color = "white";
  banner.style.textAlign = "center";
  banner.style.padding = "10px";
  banner.style.zIndex = "1000";
  banner.style.borderRadius = "10px";
  banner.style.opacity = "0";
  banner.style.transition = "opacity 0.5s ease-in-out";
  banner.innerText = message;

  // Append to the DOM with fade-in effect
  document.body.appendChild(banner);
  setTimeout(() => {
    banner.style.opacity = "1";
  }, 10);

  // Fade-out and remove after the specified duration
  setTimeout(() => {
    banner.style.opacity = "0";
    setTimeout(() => {
      banner.remove();
    }, 500);
  }, duration);
}
