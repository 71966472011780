import { Bank } from "./models/bank.model";
import { AppPages } from "./models/app.model";
import { VerificationStep } from "./models/verification-step";
import { environment } from "src/environments/environment";
import { BankPassbook } from "./models/bank-passbook.model";
import { Country, CountryCodeEnum } from "./models/country.model";
import { ChatConfig, ChatType } from "./models/chat.model";

export const REQUEST_URL = environment.requestUrl;

export const REQUEST_API = `${REQUEST_URL}/api`;
export const DEFAULT_LANG = "en";
export const EWA_FORWARD_EMAIL = "@st.ewa-services.com";
export const EWA_INFO_EMAIL = "info@ewa-services.com";
export const LINE_CHAT_LINK = "https://page.line.me/finn.app.th";
export const FB_CHAT_LINK_PH = "https://m.me/finn.app.ph";
export const FB_PAGE_PH = "https://www.facebook.com/finn.app.ph";
export const WHATSAPP_CHAT_LINK = "https://wa.me/+6287755319003";
export const POLICY_LINK = "https://www.finn-app.com/privacy-policy.html";
export const POLICY_LINK_ID =
  "https://finn-indonesia-legal.surge.sh/privacy-policy.html";
export const TERMS_LINK = "https://www.finn-app.com/terms-and-conditions.html";
export const SEC_LISTING_LINK_PH =
  "https://www.sec.gov.ph/lending-companies-and-financing-companies-2/list-of-recorded-online-lending-platforms/#gsc.tab=0";
export const TERMS_LINK_ID =
  "https://finn-indonesia-legal.surge.sh/terms-and-conditions.html";

export const ALLOWED_OTP_REQUESTS = 5;
export const OTP_REQUESTS_COUNT = 31;

export const TIME_ZONE = "Asia/Bangkok";
export const ISO_STRING_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX";

export enum MembershipFilters {
  jobs = "jobs",
  courses = "courses",
  articles = "articles",
}

export enum EMPLOYER_TYPES {
  Private = "PE",
  Government = "GA",
  State = "SE",
  Foundation = "FO",
  TransportationLogistics = "LTW",
  BPO = "BPO",
  FinancialAndLegal = "FIL",
  HotelsFood = "HF",
  InformationTechnology = "IT",
  Education = "EDU",
  Healthcare = "H",
  Freelance = "FR",
  Service = "SER",
  OtherCorporateIndustries = "OCI",
  OFW = "OFW",
  PoliceMilitary = "POLMIL",
  Unemployed = "UE",
  Others = "O",
}

/**
 * A set of employer types that should trigger the search functionality.
 * This set includes various predefined employer types that require additional processing.
 */
export const EMPLOYER_SEARCH_TRIGGER_TYPES = new Set([
  EMPLOYER_TYPES.Private,
  EMPLOYER_TYPES.TransportationLogistics,
  EMPLOYER_TYPES.Others,
  EMPLOYER_TYPES.BPO,
  EMPLOYER_TYPES.FinancialAndLegal,
  EMPLOYER_TYPES.HotelsFood,
  EMPLOYER_TYPES.InformationTechnology,
  EMPLOYER_TYPES.Education,
  EMPLOYER_TYPES.Healthcare,
  EMPLOYER_TYPES.Freelance,
  EMPLOYER_TYPES.Service,
  EMPLOYER_TYPES.OtherCorporateIndustries,
]);

export enum REF_DATA_TYPES {
  EmployerTypes = "employerType",
  BusinessTypes = "businessType",
  Positions = "position",
  Gender = "gender",
  MaritalStatus = "maritalStatus",
  HousingStatus = "housingStatus",
  EducationLevel = "educationLevel",
  AdvancePurpose = "advancePurpose",
}

export enum ID_VERIFICATION_STEPS {
  IdFrontScan = 1,
  IdBackScan = 2,
  SelfieImage = 3,
}

export enum STATEMENT_STATUS {
  pending = 1,
  approved = 2,
  rejected = 3,
}

export enum TRANSACTION_VARIANT {
  v1 = "v1",
  v2 = "v2",
}

export enum TRANSACTION_DISBURSE_STATUS {
  InProgress = "inProgress",
  PaidOut = "paidOut",
  Rejected = "rejected",
  Accepted = "accepted",
}

export enum TRANSACTION_PAYBACK_STATUS {
  Pending = "pendingPayback",
  PaidBack = "paidBack",
  CollectionFailedRetry = "collectionFailedRe",
  CollectionFailed = "collectionFailed",
  Overpaid = "overpaid",
  PartiallyPaid = "partiallyPaid",
}

export enum TRANSACTION_WITHDRAWAL_SPEED {
  Normal = "normal",
  Fast = "fast",
}

export enum FEATURE_EXPLAINER_VARIANT {
  primary,
  secondary,
}

export enum QUOTE_ALERT_BANNER_TYPES {
  note = "note",
  reminder = "reminder",
  warning = "warning",
  notice = "notice",
}
export enum DEBIT_TYPE {
  debitCard = "D",
  creditCard = "C",
}

export enum MEMBERSHIP_PAYMENT_STATUS {
  paymentDue,
  paid,
}

export enum ACCOUNT_DEACTIVATION_KEYS {
  FailedCharge = "failed-charge",
  PaydayLogicChange = "payday-logic-change",
  BankChange = "bank-change",
  WrongAccount = "wrong-account",
  NoDebitCard = "no-debit-card",
  EmployerChange = "employer-change",
  PermanentDeactivation = "permanent-deactivation",
}

export const VERIFICATION_STEPS: VerificationStep[] = [
  {
    step: ID_VERIFICATION_STEPS.IdFrontScan,
    iconName: "front-id-icon",
    title: "ID_VERIFICATION.ENTRY.VERIFICATION_STEPS.ID_FRONT",
  },
  {
    step: ID_VERIFICATION_STEPS.IdBackScan,
    iconName: "back-id-icon",
    title: "ID_VERIFICATION.ENTRY.VERIFICATION_STEPS.ID_BACK",
  },
  {
    step: ID_VERIFICATION_STEPS.SelfieImage,
    iconName: "selfie-icon",
    title: "ID_VERIFICATION.ENTRY.VERIFICATION_STEPS.SELFIE",
  },
];

export const GTM_FUNNEL_VERSION = 1;

export enum GTM_EVENT_NAMES {
  registerButtonClicked = "Register Acc. Button Clicked",
  questionnairePassed = "Questionnaire Passed",
  questionnaireRejected = "Questionnaire Rejected",
  phoneNumberVerified = "User Phone Nr. Verified",
  idCardOCRPassed = "ID Card OCR Passed",
  idCardOCRFailed = "ID Card OCR Failed",
  employmentInfoEntered = "Employment Info Entered",
  bankAccountCheck = "Bank Account Check",
  statementPartiallyUploaded = "Statement Partial Upload",
  statementLinkingStarted = "Statement Linking Started",
  statementLinkingCompleted = "Statement Linking Completed",
  statementsUploaded = "Statements Uploaded",
  statementsApproved = "Statements Approved",
  statementsRejected = "Statements Rejected",
  paymentMethodLinkSuccessful = "Payment Method Link Succeeded",
  paymentMethodLinkFailed = "Payment Method Link Failed",
  userLoggedIn = "User Logged In",
  withdrawalDetailsSubmitted = "Withdrawal Details Selected",
  tipAmountSelected = "Tip Amount Selected",
  WithdrawalMade = "Withdrawal Made",
  onboardingStepDone = "Onboarding Step Done",
  withdrawalStepDone = "Withdrawal Step Done",
  articleOpened = "Financial Education Article Opened",
  articleScrolledThrough = "Financial Education Article ScrollThrough",
  referralLinkCopyClick = "Referral Link Copy Click",
  referralSocialMediaClick = "Referral Social Media Click",
  membershipSubscription = "Membership Subscription",
  membershipUnsubscription = "Membership Unsubscription",
  accountDeleted = "Account Deleted",
  recoveryStarted = "Account Recovery Started",
  recoveryCompleted = "Account Recovery Complete",
  featureStatusFinancialHealthCheck = "Feature Status: Financial Healthcheck",
  featureUpskilling = "Feature Upskilling",
  featurePartTimeJobs = "Feature Part Time Jobs",
  featureSSOKnowledge = "Feature SSO Knowledge",
  featureAccidentInsurance = "Feature Accident Insurance",
  featureAccidentInsuranceRegistration = "Feature Accident Insurance Register",
  featureAccidentInsuranceUpdate = "Feature Accident Insurance Update",
  featureFastBudgetPro = "Feature Fast Budget Pro",
  featureFinancialEducation = "Feature Financial Education",
}

export enum GTM_ONBOARDING_STEPS {
  STEP_TOS = "T&C Accepted",
  STEP_QUESTIONNAIRE_PASSED = "Quest. Passed",
  STEP_PHONE_VERIFIED = "Phone Nr. Verified",
  STEP_CREDENTIALS_SETUP = "Email + PIN Setup",
  STEP_ID_VERIFIED = "ID Card OCR Passed",
  STEP_CROSSCHECK_DONE = "Personal Info Confirmed",
  STEP_EMPLOYMENT_INFO = "Employment Info Entered",
  STEP_BANK_INFO = "Bank Acc. Info Entered",
  STEP_STATEMENTS_UPLOADED = "Statements Upload Completed",
  STEP_SALARIES_FLAGGED = "Salaries Flagged",
  STEP_STATEMENTS_APPROVED = "Statements Approved",
  STEP_PAYMENT_METHOD_LINKED = "Paym. Method Linked",
}

export enum GTM_WITHDRAWAL_STEPS {
  STEP_INITIATED = "Withdrawal Initiated",
  STEP_CONFIGURED = "Withdrawal Details Selected",
  STEP_CONFIRMED = "Withdrawal Confirmed",
  STEP_TIP_SELECTED = "Tip Amount Selected",
  STEP_PIN_ENTERED = "Withdrawal PIN Inputted",
  STEP_COMPELTED = "Withdrawal Made",
}

export enum MEMBERSHIP_SUBSCRIPTION_TRIGGERS {
  dashboardMenuItemClick = "Dashboard Menu Item Click",
  withdrawalOptionClick = "Withdrawal Page - Express Membership Option Click",
  membershipPageMenuItemClick = "Membership Feature List Item Click",
  membershipPage = "Membership Page Visit",
  manageMembershipPage = "Membership Management Settings Page",
  getMembershipBannerClick = "Get Membership Banner Click",
}

export const WALLET_ID_PATTERN = /^[a-zA-Z0-9._-]+$/;

export const BANKS_PH = new Map<string, Bank>([
  [
    "bpi",
    {
      id: "bpi",
      logoURL: "assets/icon/bank_logos/PH/BPI.svg",
      name: "PH.BANKS.BPI",
      shortName: "bpi",
      code: "010040018",
      bankAccountPattern: /^\d{10}$/,
      bankAccountLength: 10,
    },
  ],
  [
    "ubp",
    {
      id: "ubp",
      logoURL: "assets/icon/bank_logos/PH/union-bank.svg",
      name: "PH.BANKS.UNIONBANK",
      shortName: "ubp",
      code: "011570011",
      bankAccountPattern: /^\d{12}$/,
      bankAccountLength: 12,
    },
  ],
  [
    "metro",
    {
      id: "metro",
      logoURL: "assets/icon/bank_logos/PH/metrobank.svg",
      name: "PH.BANKS.METRO",
      shortName: "metro",
      code: "010269996",
      bankAccountPattern: /^[a-zA-Z0-9]*$/,
    },
  ],
]);

/**
 * An array of PH banks that are partially supported or are yet to be integrated.
 * These banks can be selectively shown or hidden in the UI
 * based on the configuration flag or rollout phase.
 */
export const BANKS_PENDING_PH = new Map<string, Bank>([
  [
    "maya",
    {
      id: "maya",
      logoURL: "assets/icon/bank_logos/PH/maya.svg",
      name: "PH.BANKS.MAYA",
      shortName: "maya",
      code: "900002",
      bankAccountPattern: /^[a-zA-Z0-9]*$/,
      isPendingIntegration: true,
    },
  ],
  [
    "bdo",
    {
      id: "bdo",
      logoURL: "assets/icon/bank_logos/PH/bdo.svg",
      name: "PH.BANKS.BDO",
      shortName: "bdo",
      code: "900003",
      bankAccountPattern: /^[a-zA-Z0-9]*$/,
      isPendingIntegration: true,
    },
  ],
  [
    "security",
    {
      id: "security",
      logoURL: "assets/icon/bank_logos/PH/security-bank.svg",
      name: "PH.BANKS.SECURITY",
      shortName: "security",
      code: "900005",
      bankAccountPattern: /^[a-zA-Z0-9]*$/,
      isPendingIntegration: true,
    },
  ],
  [
    "gcash",
    {
      id: "gcash",
      logoURL: "assets/icon/bank_logos/PH/gcash.svg",
      name: "PH.BANKS.GCASH",
      shortName: "gcash",
      code: "018040010",
      isWallet: true,
      bankAccountPattern: WALLET_ID_PATTERN,
    },
  ],
  [
    "landbank",
    {
      id: "landbank",
      logoURL: "assets/icon/bank_logos/PH/land-bank.svg",
      name: "PH.BANKS.LANDBANK",
      shortName: "landbank",
      code: "10350025",
      bankAccountPattern: /^[a-zA-Z0-9]*$/,
      isPendingIntegration: true,
    },
  ],
]);

export const BANKS_PENDING_TH = new Map<string, Bank>();

export const BANKS = new Map<string, Bank>([
  [
    "scb",
    {
      id: "scb",
      logoURL: "assets/icon/bank_logos/scb.svg",
      name: "BANKS.SCB",
      shortName: "scb",
      code: "014",
    },
  ],
  [
    "bangkok",
    {
      id: "bangkok",
      logoURL: "assets/icon/bank_logos/bangkok.svg",
      name: "BANKS.BANGKOK",
      shortName: "bbl",
      code: "002",
    },
  ],
  [
    "kbank",
    {
      id: "kbank",
      logoURL: "assets/icon/bank_logos/farmers-bank.svg",
      name: "BANKS.KBANK",
      shortName: "kbank",
      code: "004",
    },
  ],
  [
    "ktb",
    {
      id: "ktb",
      logoURL: "assets/icon/bank_logos/ktb.svg",
      name: "BANKS.KTB",
      shortName: "ktb",
      code: "006",
    },
  ],
  [
    "ayudhya",
    {
      id: "ayudhya",
      logoURL: "assets/icon/bank_logos/ayudhya.svg",
      name: "BANKS.AYUDHYA",
      shortName: "bay",
      code: "025",
    },
  ],
  [
    "ttb",
    {
      id: "ttb",
      logoURL: "assets/icon/bank_logos/ttb.svg",
      name: "BANKS.TTB",
      shortName: "tmb",
      code: "011",
    },
  ],
  [
    "gsb",
    {
      id: "gsb",
      logoURL: "assets/icon/bank_logos/gsb.svg",
      name: "BANKS.GSB",
      shortName: "gsb",
      code: "030",
    },
  ],
  [
    "other",
    {
      id: "other",
      logoURL: "assets/img/bank-logo-placeholder.png",
      name: "BANKS.OTHER",
      shortName: "other",
      code: "",
    },
  ],
]);

export const BANKS_ID = new Map<string, Bank>([
  [
    "bri",
    {
      id: "bri",
      logoURL: "assets/icon/bank_logos/ID/bri_icon.svg",
      name: "BANKS.BRI",
      shortName: "bri",
      code: "002",
    },
  ],
]);

export const BANKS_PENDING_ID = new Map<string, Bank>([
  [
    "bni",
    {
      id: "bni",
      logoURL: "assets/icon/bank_logos/ID/bni_icon.svg",
      name: "BANKS.BNI",
      shortName: "bni",
      code: "009",
    },
  ],
  [
    "bca",
    {
      id: "bca",
      logoURL: "assets/icon/bank_logos/ID/bca_icon.svg",
      name: "BANKS.BCA",
      shortName: "bca",
      code: "014",
    },
  ],
  [
    "cimb",
    {
      id: "cimb",
      logoURL: "assets/icon/bank_logos/ID/cimb_icon.svg",
      name: "BANKS.CIMB",
      shortName: "cimb",
      code: "022",
    },
  ],
  [
    "mandiri",
    {
      id: "mandiri",
      logoURL: "assets/icon/bank_logos/ID/mandiri_icon.svg",
      name: "BANKS.MANDIRI",
      shortName: "mandiri",
      code: "008",
    },
  ],
]);

export const PASSBOOKS = new Map<string, BankPassbook>([
  [
    "scb",
    {
      bank: BANKS.get("scb"),
      logo: "assets/icon/passbook_logos/scb.svg",
      backgroundImage: "assets/img/passbook/scb-background.svg",
      nameEN: "Savings Account Passbook",
      nameTH: "สมุดบัญชีออมทรัพย์",
    },
  ],
  [
    "bangkok",
    {
      bank: BANKS.get("bangkok"),
      logo: "assets/icon/passbook_logos/bangkok.svg",
      backgroundImage: "assets/img/passbook/bangkok-background.svg",
      nameEN: "Savings Account Passbook",
      nameTH: "สมุดบัญชีออมทรัพย์",
    },
  ],
  [
    "kbank",
    {
      bank: BANKS.get("kbank"),
      logo: "assets/icon/passbook_logos/kbank.svg",
      backgroundImage: "assets/img/passbook/kbank-background.svg",
      nameEN: "Savings Deposit Passbook",
      nameTH: "สมุดบัญชีเงินฝากออมทรัพย์",
    },
  ],
  [
    "ktb",
    {
      bank: BANKS.get("ktb"),
      logo: "assets/icon/passbook_logos/ktb.svg",
      backgroundImage: "assets/img/passbook/ktb-background.svg",
      nameEN: "Savings Account Passbook",
      nameTH: "สมุดบัญชีออมทรัพย์",
    },
  ],
  [
    "ayudhya",
    {
      bank: BANKS.get("ayudhya"),
      logo: "assets/icon/passbook_logos/ayudhya.svg",
      backgroundImage: "assets/img/passbook/ayudhya-background.svg",
      nameEN: "Savings Account Passbook",
      nameTH: "สมุดบัญชีออมทรัพย์",
    },
  ],
  [
    "ttb",
    {
      bank: BANKS.get("ttb"),
      logo: "assets/icon/passbook_logos/ttb.svg",
      backgroundImage: "assets/img/passbook/ttb-background.svg",
      nameEN: "Savings Deposit Passbook",
      nameTH: "สมุดบัญชีเงินฝากออมทรัพย์",
    },
  ],
  [
    "ubp",
    {
      bank: BANKS_PH.get("ubp"),
      logo: "assets/icon/passbook_logos/ubp.png",
      backgroundImage: "assets/img/passbook/ubp-background.svg",
      nameEN: "Savings Account Passbook",
    },
  ],
  [
    "bpi",
    {
      bank: BANKS_PH.get("bpi"),
      logo: "assets/icon/passbook_logos/bpi.svg",
      backgroundImage: "assets/img/passbook/bpi-background.svg",
      nameEN: "Savings Account Passbook",
    },
  ],
  [
    "gcash",
    {
      bank: BANKS_PENDING_PH.get("gcash"),
      logo: "assets/icon/passbook_logos/gcash.svg",
      backgroundImage: "assets/img/passbook/gcash-background.svg",
      nameEN: "Savings Account Passbook",
    },
  ],
  [
    "maya",
    {
      bank: BANKS_PENDING_PH.get("maya"),
      logo: "assets/icon/passbook_logos/maya.svg",
      backgroundImage: "assets/img/passbook/maya-background.svg",
      nameEN: "Savings Account Passbook",
    },
  ],
  [
    "bdo",
    {
      bank: BANKS_PENDING_PH.get("bdo"),
      logo: "assets/icon/passbook_logos/bdo.svg",
      backgroundImage: "assets/img/passbook/bdo-background.svg",
      nameEN: "Savings Account Passbook",
    },
  ],
  [
    "landbank",
    {
      bank: BANKS_PENDING_PH.get("landbank"),
      logo: "assets/icon/passbook_logos/landbank.svg",
      backgroundImage: "assets/img/passbook/landbank-background.svg",
      nameEN: "Savings Account Passbook",
    },
  ],
  [
    "security",
    {
      bank: BANKS_PENDING_PH.get("security"),
      logo: "assets/icon/passbook_logos/security.svg",
      backgroundImage: "assets/img/passbook/security-background.svg",
      nameEN: "Savings Account Passbook",
    },
  ],
  [
    "metro",
    {
      bank: BANKS_PH.get("metro"),
      logo: "assets/icon/passbook_logos/metro.svg",
      backgroundImage: "assets/img/passbook/metro-background.svg",
      nameEN: "Savings Account Passbook",
    },
  ],
  [
    "bri",
    {
      bank: BANKS_ID.get("bri"),
      logo: "assets/icon/passbook_logos/bri.svg",
      backgroundImage: "assets/img/passbook/bri-background.svg",
      nameEN: "Rekening Tabungan",
    },
  ],
  [
    "bni",
    {
      bank: BANKS_PENDING_ID.get("bni"),
      logo: "assets/icon/passbook_logos/bni.svg",
      backgroundImage: "assets/img/passbook/bni-background.svg",
      nameEN: "Rekening Tabungan",
    },
  ],
  [
    "bca",
    {
      bank: BANKS_PENDING_ID.get("bca"),
      logo: "assets/icon/passbook_logos/bca.svg",
      backgroundImage: "assets/img/passbook/bca-background.svg",
      nameEN: "Rekening Tabungan",
    },
  ],
  [
    "cimb",
    {
      bank: BANKS_PENDING_ID.get("cimb"),
      logo: "assets/icon/passbook_logos/cimb.svg",
      backgroundImage: "assets/img/passbook/cimb-background.svg",
      nameEN: "Rekening Tabungan",
    },
  ],
  [
    "mandiri",
    {
      bank: BANKS_PENDING_ID.get("mandiri"),
      logo: "assets/icon/passbook_logos/mandiri.svg",
      backgroundImage: "assets/img/passbook/mandiri-background.svg",
      nameEN: "Rekening Tabungan",
    },
  ],
]);

export const BANKING_APPS = new Map<string, string>([
  ["scb", "SCB Easy"],
  ["bangkok", "Bualuang mBanking"],
  ["kbank", "K PLUS"],
  ["ktb", "Krungthai NEXT"],
  ["ayudhya", "KMA (Krungsri Mobile App)"],
  ["ttb", "TTB Touch"],
  ["ubp", "Unionbank"],
  ["gcash", "GCash"],
  ["maya", "Maya"],
  ["bdo", "BDO Online"],
  ["landbank", "Landbank App"],
  ["security", "Security Bank App"],
  ["metro", "Metrobank App"],
  ["bri", "Brimo"],
  ["bni", "BNI Mobile Banking"],
  ["bca", "BCA Mobile"],
  ["cimb", "CIMB Clicks"],
  ["mandiri", "Livin' by Mandiri"],
]);

export const RouterStates = new Map<AppPages, { redirectTo: string }>([
  [
    AppPages.onboardingIntro,
    {
      redirectTo: "onboarding-intro",
    },
  ],
  [
    AppPages.termsOfService,
    {
      redirectTo: "terms-of-service",
    },
  ],
  [
    AppPages.questionnaire,
    {
      redirectTo: "questionnaire",
    },
  ],
  [
    AppPages.credentials,
    {
      redirectTo: "credentials/credentials-entry",
    },
  ],
  [
    AppPages.inputPin,
    {
      redirectTo: "credentials/input-pin/set-pin",
    },
  ],
  [
    AppPages.idVerification,
    {
      redirectTo: "id-card-verification/id-verification-entry",
    },
  ],
  [
    AppPages.idVerificationError,
    {
      redirectTo: "id-card-verification/id-verification-error",
    },
  ],
  [
    AppPages.infoCrossCheck,
    {
      redirectTo: "info-cross-check",
    },
  ],
  [
    AppPages.ktpScan,
    {
      redirectTo: "ktp-scan/ktp-scan-entry",
    },
  ],
  [
    AppPages.ktpScanError,
    {
      redirectTo: "ktp-scan/ktp-scan-error",
    },
  ],
  [
    AppPages.additionalProfile,
    {
      redirectTo: "additional-profile",
    },
  ],
  [
    AppPages.additionalPaydayInfo,
    {
      redirectTo: "additional-payday-info",
    },
  ],
  [
    AppPages.additionalInfo,
    {
      redirectTo: "additional-info",
    },
  ],
  [
    AppPages.employment,
    {
      redirectTo: "employment",
    },
  ],
  [
    AppPages.employmentDocRequest,
    {
      redirectTo: "employment-verification/verification-entry",
    },
  ],
  [
    AppPages.employmentVerificationReview,
    {
      redirectTo: "employment-verification/verification-review",
    },
  ],
  [
    AppPages.employmentVerificationRejection,
    {
      redirectTo: "employment-verification/verification-rejection",
    },
  ],
  [
    AppPages.bankStatements,
    {
      redirectTo: "bank-statements/bank-selection",
    },
  ],
  [
    AppPages.forwardStatement,
    {
      redirectTo: "bank-statements/forward-statement",
    },
  ],
  [
    AppPages.uploadStatementEntry,
    {
      redirectTo: "bank-statements/statement-upload-entry",
    },
  ],
  [
    AppPages.uploadStatement,
    {
      redirectTo: "bank-statements/statement-upload",
    },
  ],
  [
    AppPages.statementManualCheck,
    {
      redirectTo: "bank-statements/manual-check-waiting",
    },
  ],
  [
    AppPages.statementVerificationError,
    {
      redirectTo: "bank-statements/statement-verification-error",
    },
  ],
  [
    AppPages.linkBankAccount,
    {
      redirectTo: "link-bank-account/link-account-entry",
    },
  ],
  [
    AppPages.linkIdBankAccount,
    {
      redirectTo: "link-bank-account/link-account-id",
    },
  ],
  [
    AppPages.metroBankConfirmation,
    {
      redirectTo: "link-bank-account/metro-bank-confirmation",
    },
  ],
  [
    AppPages.metroConfirmationWaiting,
    {
      redirectTo: "link-bank-account/metro-confirmation-waiting",
    },
  ],
  [
    AppPages.linkAccountSuccess,
    {
      redirectTo: "link-bank-account/link-account-success",
    },
  ],
  [
    AppPages.linkAccountError,
    {
      redirectTo: "link-bank-account/link-bank-error",
    },
  ],
  [
    AppPages.linkAccountWaiting,
    {
      redirectTo: "link-bank-account/link-account-waiting",
    },
  ],
  [
    AppPages.uploadConfirmationWaiting,
    {
      redirectTo: "link-bank-account/upload-confirmation-waiting",
    },
  ],
  [
    AppPages.statementPermanentRejection,
    {
      redirectTo: "bank-statements/statement-rejection",
    },
  ],
  [
    AppPages.onboardingFinalScreen,
    {
      redirectTo: "onboarding-final-screen",
    },
  ],
  [
    AppPages.activeUser,
    {
      redirectTo: "main/dashboard",
    },
  ],
  [
    AppPages.withdrawPending,
    {
      redirectTo: "main/withdraw/withdraw-pending",
    },
  ],
  [
    AppPages.withdrawRejected,
    {
      redirectTo: "main/withdraw/withdraw-rejection",
    },
  ],
  [
    AppPages.withdrawSuccessful,
    {
      redirectTo: "main/withdraw/withdraw-success",
    },
  ],
  [
    AppPages.ssoBenefits,
    {
      redirectTo: "main/membership/sso-benefits",
    },
  ],
  [
    AppPages.earnMoney,
    {
      redirectTo: "main/membership/earn-money/part-time-jobs",
    },
  ],
  [
    AppPages.rejectedUser,
    {
      redirectTo: "application-rejected",
    },
  ],
  [
    AppPages.finverseLinkWaiting,
    {
      redirectTo: "finverse/link-waiting",
    },
  ],
  [
    AppPages.finverseLinkEntry,
    {
      redirectTo: "finverse/link",
    },
  ],
  [
    AppPages.accountRecovery,
    {
      redirectTo: "account-recovery/reactivate",
    },
  ],
  [
    AppPages.permanentlyDeactivated,
    {
      redirectTo: "permanently-deactivated",
    },
  ],
  [
    AppPages.preAccountDeletion,
    {
      redirectTo: "delete-account",
    },
  ],
]);

export const BASE_DATE_FORMAT = "dd/MM/yyyy";
export const MONTH_YEAR_FORMAT = "yyyy-MM";
export const YEAR_MONTH_DATE_FORMAT = "yyyy-MM-dd";
export const STATEMENT_SCORE = 0.5;

export enum CARD_STATUSES {
  success = "00",
  expired = "54",
  invalidData = "02",
}

export const ATM_CARD_BANK_IDS: string[] = [];
export const UPLOAD_SCREENSHOT_BANKS = [""];
export const STATEMENT_REQUEST_MONTHS = 4;
export const TEMPORARILY_DISABLED_BANKS_LINK_CARD = ["gsb", "other"]; // for the linkBankCard step only
export const TEMPORARILY_DISABLED_BANKS = ["ttb", "gsb", "other"];
export const LINK_ACCOUNT_BANKS = [
  "ayudhya",
  "scb",
  "ktb",
  "kbank",
  "bangkok",
  "ubp",
  "bpi",
  "gcash",
  "bri",
  "metro",
  "landbank",
];

export const FINVERSE_ALLOWED_BANKS = [];
export const FINVERSE_ONLY_BANKS = ["bpi", "ubp", "metro", "landbank"];

export enum LINK_BANK_TYPE {
  linkAccount = "linkAccount",
  linkCard = "linkCard",
}

export const LINE_ID = "https://line.me/R/ti/p/@finn.app.th";
export const TERMS =
  "https://docs.google.com/document/u/4/d/e/2PACX-1vTZLZe8zHa90b4U1aNRRZlfQ_h-p7s6Nx4PPLRwxhCATeAa87G7Qo2MV71XHZQ0dLVyoWwHchk2fIJd/pub";

/**
 * <p> Add full paths of the pages instead of
 * the component name to show Chat
 * FAB button in the pages
 *
 * E.g /questionnaire
 */
export const CHAT_ALLOWED_PATHS = [
  "/credentials/credentials-entry",
  "/id-card-verification/id-verification-error",
  "/bank-statements/bank-selection",
  "/link-bank-account/link-account-entry",
  "/main/dashboard",
];

// Paths where the chat feature is enabled specifically for Indonesia.
const CHAT_ALLOWED_PATHS_ID = [
  "/ktp-scan/ktp-scan-entry",
  "/ktp-scan/ktp-scan-error",
  "/additional-profile",
  "/employment",
];

// Paths where the chat feature is enabled specifically for Philippines.
const CHAT_ALLOWED_PATHS_PH = [];

// Paths where the chat feature is enabled specifically for Thailand.
const CHAT_ALLOWED_PATHS_TH = [
  "/id-card-verification/id-verification-error",
  "/id-card-verification/id-verification-entry",
  "/id-card-verification/id-verification-preview",
  "/id-card-verification/id-verification-progress",
  "/id-card-verification/ocr-best-practices",
  "/employment",
];

export const CHAT_ALLOWED_PATHS_BY_COUNTRY = {
  [CountryCodeEnum.id]: CHAT_ALLOWED_PATHS_ID,
  [CountryCodeEnum.ph]: CHAT_ALLOWED_PATHS_PH,
  [CountryCodeEnum.th]: CHAT_ALLOWED_PATHS_TH,
};

/**
 * Defines a list of paths that need the chat bubble repositioned
 * higher on the page to prevent overlapping content.
 */
export const CHAT_BUBBLE_OVERLAP_ADJUSTED_PATHS = ["/main/dashboard"];

/**
 * Configuration object that maps each chat type (as defined in the ChatType enum)
 * to its corresponding settings
 */
export const ChatTypesConfig: Record<ChatType, ChatConfig> = {
  [ChatType.LINE]: {
    iconSrc: "assets/icon/line-icon.png",
    headerIconSrc: "assets/icon/line_logo_full.svg",
    altText: "Line Chat Icon",
    className: "line-chat",
    chatApp: "LINE",
  },
  [ChatType.FACEBOOK]: {
    iconSrc: "assets/icon/fb-icon.png",
    headerIconSrc: "assets/icon/fb-icon.png",
    altText: "Facebook Chat Icon",
    className: "facebook-chat",
    chatApp: "Facebook",
  },
  [ChatType.WHATSAPP]: {
    iconSrc: "assets/icon/whatsapp-icon.png",
    headerIconSrc: "assets/icon/whatsapp-icon-sm.svg",
    altText: "WhatsApp Chat Icon",
    className: "whatsapp-chat",
    chatApp: "Whatsapp",
  },
};

// Map of country codes to their respective chat types
export const CountryChatMap: Record<CountryCodeEnum, ChatType> = {
  [CountryCodeEnum.th]: ChatType.LINE,
  [CountryCodeEnum.id]: ChatType.WHATSAPP,
  [CountryCodeEnum.ph]: ChatType.FACEBOOK,
};

export const COUNT_TO_SHOW = 4;
export const MASKING_CHARACTER = "X";

export const WITHDRAW_STATES = [
  AppPages.withdrawPending,
  AppPages.withdrawRejected,
  AppPages.withdrawSuccessful,
];

export const AFTER_ONBOARDING_STATES = [
  AppPages.activeUser,
  ...WITHDRAW_STATES,
];

export const PRE_ONBOARDING_STATES = [
  AppPages.questionnaire,
  AppPages.termsOfService,
  AppPages.credentials,
  AppPages.inputPin,
  AppPages.idVerification,
  AppPages.idVerificationError,
  AppPages.infoCrossCheck,
  AppPages.additionalProfile,
  AppPages.employment,
  AppPages.bankStatements,
  AppPages.finverseLinkEntry,
  AppPages.finverseLinkWaiting,
  AppPages.linkBankAccount,
  AppPages.linkIdBankAccount,
  AppPages.onboardingFinalScreen,
];

export const USER_IDENTITY_INFO_FIELDS = [
  "date_of_expiry_en",
  "date_of_birth_en",
  "full_name_th",
  "title_first_name_en",
  "middle_last_name_en",
  "id_number",
  "address_th",
  "sub_district",
  "district",
  "province",
  "confidence",
];

export const THAI_WEEKDAYS = ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"];
export const THAI_MONTHS = [
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค.",
];

export const NOT_ALLOWED_TO_DELETE_ACCOUNT_STATES = [
  AppPages.onboardingIntro,
  AppPages.termsOfService,
  AppPages.questionnaire,
  AppPages.otpPhone,
  AppPages.otpEmail,
  AppPages.inputPin,
  AppPages.idVerificationError,
];

export const VWO_ID_VERIFICATION_CAMPAIGN = "2";
export const UPLOAD_CONFIRMATION_ALLOWED_FILE_TYPES = [
  "pdf",
  "jpg",
  "jpeg",
  "png",
  "heic",
];

export const COUNTRIES: Country[] = [
  {
    title: "Thailand",
    icon: "assets/countries/thai_flag.svg",
    id: CountryCodeEnum.th,
  },
  {
    title: "Philippines",
    icon: "assets/countries/philippines_flag.svg",
    id: CountryCodeEnum.ph,
  },
  {
    title: "Indonesia",
    icon: "assets/countries/indonesian_flag.svg",
    id: CountryCodeEnum.id,
  },
];

export const DISABLED_STATEMENT_ERROR_KEYS = new Set<string>([
  "statement_timeframe_is_incomplete",
]);
export const STATEMENT_ERRORS = new Map<string, string>([
  [
    "bank_account_number_not_matching",
    "STATEMENT_ATTACHMENT_ERRORS.BANK_ACCOUNT_NUMBER_NOT_MATCHING",
  ],
  [
    "statement_timeframe_is_incomplete",
    "STATEMENT_ATTACHMENT_ERRORS.STATEMENT_TIMEFRAME_IS_INCOMPLETE",
  ],
  [
    "tmb_old_banking_app_version",
    "STATEMENT_ATTACHMENT_ERRORS.TMB_OLD_BANKING_APP_VERSION",
  ],
  [
    "bbl_online_banking_rejected",
    "STATEMENT_ATTACHMENT_ERRORS.BBL_ONLINE_BANKING_REJECTED",
  ],
  [
    "bay_old_banking_app_no_timestamps",
    "STATEMENT_ATTACHMENT_ERRORS.BAY_OLD_BANKING_APP_NO_TIMESTAMPS",
  ],
  [
    "user_uploaded_statement_from_wrong_bank",
    "STATEMENT_ATTACHMENT_ERRORS.USER_UPLOADED_STATEMENT_FROM_WRONG_BANK",
  ],
  [
    "cannot_determine_statement_bank_name",
    "STATEMENT_ATTACHMENT_ERRORS.CANNOT_DETERMINE_STATEMENT_BANK_NAME",
  ],
  ["kbank_file_too_large", "STATEMENT_ATTACHMENT_ERRORS.KBANK_FILE_TOO_LARGE"],
]);

export const ACCOUNT_DOES_NOT_EXIST_ERR_MSG = "Invalid account credentials";
export const FAILED_CHARGE_SUPPORT_LINK = "https://cutt.ly/O8mLrt4";
export const FAILED_CHARGE_SUPPORT_LINK_PH = "https://cutt.ly/BwkH6hPH";

export const FAST_BUDGET_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.blodhgard.easybudget";
export const FAST_BUDGET_APP_STORE =
  "https://apps.apple.com/us/app/fast-budget-expense-manager/id1178825025?platform=iphone";

export const FINANCIAL_HEALTH_SCORE_TH =
  "https://docs.google.com/forms/d/e/1FAIpQLSeXIC7b3R6iCqmel-WQ_qn-b4jZ96B2td0pUANMfnHqSlKrWQ/viewform";
export const FINANCIAL_HEALTH_SCORE_TH_EN =
  "https://docs.google.com/forms/d/e/1FAIpQLSdhsK8x2v5qBnkW4do9rYSF-VrPO1LW4TUq3EV0I0Cpy5dQWA/viewform";

export const QUESTION_MARK_PATTERN = /^[ ?]*$/; // Matches spaces and '?' characters

export const LINK_BRI_ACCOUNT_ERRORS = [
  "CHANNEL_CODE_NOT_SUPPORTED_ERROR",
  "CUSTOMER_NOT_FOUND_ERROR",
  "INVALID_ACCOUNT_DETAILS",
  "ACCOUNT_ACCESS_BLOCKED",
  "MAX_ACCOUNT_LINKING",
  "OTP_DELIVERY_ERROR",
  "CHANNEL_UNAVAILABLE",
];

export const LINK_BRI_ACCOUNT_ERRORS_OTP = [
  "DATA_NOT_FOUND_ERROR",
  "INVALID_OTP_ERROR",
  "EXPIRED_OTP_ERROR",
  "MAX_OTP_ATTEMPTS_ERROR",
  "ACCOUNT_LINKING_ALREADY_COMPLETED",
  "ACCOUNT_LINKING_ALREADY_FAILED",
  "DUPLICATE_ERROR",
];

// Deleted users survey form link for permanently deactivated users
export const DELETED_USERS_SURVEY_FORM_LINK =
  "https://docs.google.com/forms/d/e/1FAIpQLSfSBdErHhuUR51RH8rT8GoatFadgCPD2knPfsQq4TcVSElnZQ/formResponse";

export const EMPLOYER_NAME_CHARACTER_LIMIT = 255;

export enum TRANSACTION_TYPE {
  Advance = "advance",
  Membership = "membership",
}

export enum TRANSACTION_STATUS {
  Pending = "pending",
  Rejected = "rejected",
  PaidBack = "paidBack",
}

export const METRO_BANK_WEB_PORTAL_LINK =
  "https://onlinebanking.metrobank.com.ph/signin";

export const UNKNOWN_COUNTRY_RESPONSE = "UNKNOWN";

/**
 * Defines date and time formats for consistent parsing and display across the app.
 */
export const BASIC_DATE_FORMATS = [
  "yyyy-MM-dd", // 2024-06-12
  "MM-dd-yyyy", // 06-12-2024
  "dd-MM-yyyy", // 12-06-2024
  "yyyy/MM/dd", // 2024/06/12
  "MM/dd/yyyy", // 06/12/2024
  "dd/MM/yyyy", // 12/06/2024
  "yyyy MM dd", // 2024 06 12
  "MM dd yyyy", // 06 12 2024
  "dd MM yyyy", // 12 06 2024
];

export const DATE_TIME_FORMATS_24_HOUR = [
  "yyyy-MM-dd HH:mm", // 2024-06-12 15:30
  "MM-dd-yyyy HH:mm", // 06-12-2024 15:30
  "dd-MM-yyyy HH:mm", // 12-06-2024 15:30
  "yyyy-MM-dd HH:mm:ss", // 2024-06-12 15:30:45
  "dd-MM-yyyy HH:mm:ss", // 12-06-2024 15:30:45
  "MM-dd-yyyy HH:mm:ss", // 06-12-2024 15:30:45
  "yyyy/MM/dd HH:mm", // 2024/06/12 15:30
  "dd/MM/yyyy HH:mm:ss", // 12/06/2024 15:30:45
  "yyyy-MM-dd HH:mm:ss", // 2024-06-12 15:30:45
];

export const DATE_TIME_FORMATS_12_HOUR = [
  "yyyy-MM-dd hh:mm a", // 2024-06-12 03:30 PM
  "MM-dd-yyyy hh:mm a", // 06-12-2024 03:30 PM
  "dd-MM-yyyy hh:mm a", // 12-06-2024 03:30 PM
  "yyyy/MM/dd hh:mm:ss a", // 2024/06/12 03:30:45 PM
];

export const ISO_DATE_FORMATS = [
  "yyyy-MM-dd'T'HH:mm:ss'Z'", // 2024-06-12T15:30:00Z
  "yyyy-MM-dd'T'HH:mm:ss.SSSX", // 2024-06-12T15:30:00.000Z
  "yyyy-MM-dd'T'HH:mm:ss", // 2024-06-12T15:30:00
  "yyyy-MM-dd'T'HH:mm", // 2024-06-12T15:30
];

export const MONTH_NAME_FORMATS = [
  "MMMM dd, yyyy", // June 12, 2024
  "MMM dd, yyyy", // Jun 12, 2024
  "dd MMMM yyyy", // 12 June 2024
  "dd MMM yyyy", // 12 Jun 2024
];

export const SHORT_DATE_FORMATS = [
  "MM/yyyy", // 06/2024
  "yyyy-MM", // 2024-06
  "yyyy/MM", // 2024/06
];

export const TIME_ONLY_FORMATS = [
  "HH:mm", // 15:30
  "hh:mm a", // 03:30 PM
  "HH:mm:ss", // 15:30:45
  "hh:mm:ss a", // 03:30:45 PM
];

export const EDGE_CASE_FORMATS = [
  "yyyyMMdd", // 20240612
  "MMddyyyy", // 06122024
  "ddMMyyyy", // 12062024
  "dd MMM yyyy HH:mm", // 12 Jun 2024 15:30
];

// Possible date formats
export const DATE_TIME_FORMATS = [
  ...BASIC_DATE_FORMATS,
  ...DATE_TIME_FORMATS_24_HOUR,
  ...DATE_TIME_FORMATS_12_HOUR,
  ...ISO_DATE_FORMATS,
  ...MONTH_NAME_FORMATS,
  ...SHORT_DATE_FORMATS,
  ...TIME_ONLY_FORMATS,
  ...EDGE_CASE_FORMATS,
];

export const ForceVersionReminderType = "force";
export const RemindVersionReminderType = "remind";
export const SilentVersionReminderType = "silent";

export type UpdatePoliciesType =
  | typeof ForceVersionReminderType
  | typeof RemindVersionReminderType
  | typeof SilentVersionReminderType;

type UpdatePolicies = {
  [version: string]: UpdatePoliciesType;
};

type UpdateMessage = {
  title: string;
  description: string;
  cta: string;
};

export type NativeAppVersioning = {
  versioning: {
    minimumSupportedVersion: string;
    currentVersion: string;
    updatePolicies: UpdatePolicies;
    updateMessage: UpdateMessage;
  };
};
