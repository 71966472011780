import { Component, Input, OnInit } from "@angular/core";
import { take, tap } from "rxjs";

import { ChatConfig, ChatType } from "../../models/chat.model";
import { ChatTypesConfig } from "../../consts";
import { PopoverService } from "../../services/popover.service";
import { ChatService } from "../../services/chat.service";

@Component({
  selector: "app-chat-popup",
  templateUrl: "./chat-popup.component.html",
  styleUrls: ["./chat-popup.component.scss"],
})
export class ChatPopupComponent implements OnInit {
  @Input() chatType: ChatType;
  @Input() action: string;

  chatConfig: ChatConfig;

  constructor(
    private readonly popoverService: PopoverService,
    private readonly chatService: ChatService
  ) {}

  ngOnInit() {
    this.chatConfig = ChatTypesConfig[this.chatType];
  }

  chat() {
    this.chatService
      .getContactLink()
      .pipe(
        take(1),
        tap((link) => {
          this.popoverService.dismissPopover({ link });
        })
      )
      .subscribe();
  }
}
