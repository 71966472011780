import { ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";

import { environment } from "src/environments/environment";

export function getErrorHandler():  Sentry.SentryErrorHandler | ErrorHandler {
  if (environment.production) {
    return new Sentry.SentryErrorHandler();
  }

  return new ErrorHandler();
}
