import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { PlatformService } from "../services/platform.service";

@Directive({
  selector: "[appShowOnPlatform]",
})
export class ShowOnPlatformDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private platformService: PlatformService
  ) {}

  @Input() set appShowOnPlatform(platform: string) {
    this.viewContainer.clear();
    if (
      (platform === "android" && this.platformService.isAndroid()) ||
      (platform === "web" && this.platformService.isWeb())
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
