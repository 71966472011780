// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charges-icon {
  border-radius: 363.636px;
  background: #ffd366;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.charges-details {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
}
.charges-details .title {
  color: var(--ion-color-black, #000);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-bottom: 4px;
}
.charges-details .description {
  color: var(--ion-color-radio-label, #444444);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/upcoming-charges-notification/upcoming-charges-notification.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,YAAA;AADF;;AAIA;EACE,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,uBAAA;EAEA,iBAAA;AAFF;AAIE;EACE,mCAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,SAAA;EACA,kBAAA;AAHJ;AAME;EACE,4CAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,SAAA;AAJJ","sourcesContent":[".charges-icon {\n  border-radius: 363.636px;\n  background: #ffd366;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 40px;\n  height: 40px;\n}\n\n.charges-details {\n  display: flex;\n  padding: 12px 0px;\n  flex-direction: column;\n  justify-content: center;\n\n  margin-left: 16px;\n\n  .title {\n    color: var(--ion-color-black, #000);\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n\n    margin: 0;\n    margin-bottom: 4px;\n  }\n\n  .description {\n    color: var(--ion-color-radio-label, #444444);\n    font-family: Inter;\n    font-size: 11px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
