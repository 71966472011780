import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

import {
  ACCOUNT_DEACTIVATION_KEYS,
  FAILED_CHARGE_SUPPORT_LINK,
  FAILED_CHARGE_SUPPORT_LINK_PH,
  QUOTE_ALERT_BANNER_TYPES,
} from "../../consts";
import { OnDestroyComponent } from "../on-destroy/on-destroy.component";
import { CountryCodeEnum, CountryInfo } from "../../models/country.model";

@Component({
  selector: "app-quote-alert-banner",
  templateUrl: "./quote-alert-banner.component.html",
  styleUrls: ["./quote-alert-banner.component.scss"],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class QuoteAlertBannerComponent
  extends OnDestroyComponent
  implements OnInit
{
  @Input() deactivationReason: ACCOUNT_DEACTIVATION_KEYS;
  @Input() type: QUOTE_ALERT_BANNER_TYPES;
  @Input() countryInfo: CountryInfo;

  title: string;
  link: string;

  constructor(
    private translate: TranslatePipe,
    private translateService: TranslateService
  ) {
    super();
    this.type = QUOTE_ALERT_BANNER_TYPES.note;
  }

  ngOnInit() {
    this.title = this.getTitle();
    this.link = this.getLink();

    this.subscriptions.onLangChange =
      this.translateService.onLangChange.subscribe(() => {
        this.title = this.getTitle();
      });
  }

  getAlertClass(): string {
    switch (this.type) {
      case QUOTE_ALERT_BANNER_TYPES.reminder:
        return "reminder";
      case QUOTE_ALERT_BANNER_TYPES.warning:
        return "warning";
      case QUOTE_ALERT_BANNER_TYPES.notice:
        return "notice";
      default:
        return "note";
    }
  }

  private getTitle(): string {
    switch (this.type) {
      case QUOTE_ALERT_BANNER_TYPES.reminder:
      case QUOTE_ALERT_BANNER_TYPES.notice:
        return this.translate.transform("QUOTE_BANNER.REMINDER");
      case QUOTE_ALERT_BANNER_TYPES.warning:
        return this.translate.transform("QUOTE_BANNER.WARNING");
      default:
        return this.translate.transform("QUOTE_BANNER.NOTE");
    }
  }

  private getLink(): string {
    if (
      !this.deactivationReason ||
      this.deactivationReason ===
        ACCOUNT_DEACTIVATION_KEYS.PermanentDeactivation
    ) {
      return "";
    }

    return this.countryInfo && this.countryInfo.country === CountryCodeEnum.th
      ? FAILED_CHARGE_SUPPORT_LINK
      : FAILED_CHARGE_SUPPORT_LINK_PH;
  }
}
