import { Component, Input, OnInit } from "@angular/core";
import { QUESTION_MARK_PATTERN } from "../../consts";

@Component({
  selector: "app-rejection-message",
  templateUrl: "./rejection-message.component.html",
  styleUrls: ["./rejection-message.component.scss"],
})
export class RejectionMessageComponent implements OnInit {
  @Input() title: string;
  @Input() rejectionReason: string;

  reason: string;

  constructor() {
    this.reason = "";
  }

  ngOnInit() {
    this.reason =
      this.rejectionReason && !QUESTION_MARK_PATTERN.test(this.rejectionReason)
        ? this.rejectionReason
        : this.reason;
  }
}
