import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Platform } from "@ionic/angular";

import { fromEvent, Observable, tap } from "rxjs";
import { pushState } from "history-throttled";
import { BackButtonEmitter } from "@ionic/angular/common/providers/platform";

@Injectable({
  providedIn: "root",
})
export class DomainService {
  constructor(private router: Router, private platform: Platform) {}

  private clearHistory(): void {
    pushState(null, "", location.href);
  }

  disableBrowserBackButton(): Observable<Event> {
    this.clearHistory();
    return fromEvent(window, "popstate").pipe(tap(() => this.clearHistory()));
  }

  getHardwareBackButton(): BackButtonEmitter {
    return this.platform.backButton;
  }
}
