import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslatePipe } from "@ngx-translate/core";
import { Observable } from "rxjs";

import { ToasterService } from "../../services/toaster.service";
import { isPlatformNative } from "../../utils/helpers";
import { MembershipBenefitsType } from "../../models/knack.model";
import { UserService } from "../../services/user.service";
import { CountryInfo } from "../../models/country.model";

@Component({
  selector: "app-membership-sign-up-modal",
  templateUrl: "./membership-sign-up-modal.component.html",
  styleUrls: ["./membership-sign-up-modal.component.scss"],
})
export class MembershipSignUpModalComponent implements OnInit {
  @Input() fees: number;
  @Input() benefitsType: string;

  userCountry$: Observable<CountryInfo>;
  showEarlySalaryBenefit: boolean;
  showRealTimeTransferBenefit: boolean;
  showExtraBenefits: boolean;

  constructor(
    private modalController: ModalController,
    private toasterService: ToasterService,
    private translatePipe: TranslatePipe,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userCountry$ = this.userService.userCountryWatch$;

    this.showEarlySalaryBenefit =
      this.benefitsType === MembershipBenefitsType.EarlySalary;

    this.showRealTimeTransferBenefit =
      this.benefitsType === MembershipBenefitsType.Realtime;

    this.showExtraBenefits =
      this.benefitsType === MembershipBenefitsType.BenefitListVariantAll;
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  signUp(): void {
    // user can only register in the web app
    if (isPlatformNative()) {
      this.toasterService.message(
        this.translatePipe.transform("MEMBERSHIP.WEB_ONLY")
      );
      return;
    }

    this.modalController.dismiss({ action: "signup" });
  }
}
