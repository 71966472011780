import { Component, Input, OnInit } from '@angular/core';
import { CountryCodeEnum } from '../../models/country.model';
import { PopoverService } from '../../services/popover.service';

@Component({
  selector: 'app-disable-notification-popup',
  templateUrl: './disable-notification-popup.component.html',
  styleUrls: ['./disable-notification-popup.component.scss'],
})
export class DisableNotificationPopupComponent implements OnInit {

  @Input()
  country: CountryCodeEnum;

  constructor(private popoverService: PopoverService) {}

  ngOnInit() {}

  close(): void {
    this.popoverService.dismissPopover({});
  }

  confirm(): void {
    this.popoverService.dismissPopover({disable:true});
  }

}
