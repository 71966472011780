import { Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { Renderer2, ElementRef } from "@angular/core";
import {
  Observable,
  forkJoin,
  from,
  interval,
  map,
  of,
  switchMap,
  takeWhile,
} from "rxjs";

@Injectable()
export class AndroidOnboardingService {
  constructor(
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private translatePipe: TranslatePipe
  ) {}

  getAndroidSignUpText(): Observable<SafeHtml[]> {
    return forkJoin([
      this.translateService
        .get("ANDROID.ONBOARDING_INTRO_TEXT")
        .pipe(
          map((text: string) => this.sanitizer.bypassSecurityTrustHtml(text))
        ),
      this.translateService
        .get("ANDROID.BACK_TO_LOGIN")
        .pipe(
          map((text: string) => this.sanitizer.bypassSecurityTrustHtml(text))
        ),
    ]);
  }

  getAndroidMembershipText(): Observable<SafeHtml> {
    return this.translateService.get("ANDROID.MEMBERSHIP_WEB_ONLY").pipe(
      map((text) => this.sanitizer.bypassSecurityTrustHtml(text)) // Sanitize and map the text
    );
  }

  addDynamicLinkHandler(
    elRef: ElementRef,
    renderer: Renderer2
  ): Observable<void> {
    return new Observable<void>((observer) => {
      const polling$ = interval(100).pipe(
        takeWhile(
          () =>
            !elRef.nativeElement.querySelector('[data-identity="sign-up-cta"]'),
          true
        ),
        switchMap(() => {
          const ctaElement = elRef.nativeElement.querySelector(
            '[data-identity="sign-up-cta"]'
          );
          if (ctaElement) {
            renderer.listen(ctaElement, "click", (event) => {
              event.preventDefault();
              this.handleAsyncLinkClick().subscribe({
                complete: () => observer.complete(),
              });
            });
            observer.next();
            observer.complete();
          }
          return [];
        })
      );

      polling$.subscribe({
        complete: () => observer.complete(),
      });
    });
  }

  private handleAsyncLinkClick(): Observable<Window> {
    return from(
      window.open("https://app.finn-app.com/onboarding-intro", "_system")
    );
  }
}
