import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslatePipe } from "@ngx-translate/core";
import { Observable, map, switchMap, take, tap } from "rxjs";

import { ToasterService } from "../../services/toaster.service";
import { isPlatformNative } from "../../utils/helpers";
import { MembershipBenefitsType } from "../../models/knack.model";
import { UserService } from "../../services/user.service";
import { CountryInfo } from "../../models/country.model";
import { AndroidOnboardingService } from "src/app/pages/onboarding-intro/services/android-onboarding.service";
import { SafeHtml } from "@angular/platform-browser";
import { PlatformService } from "../../services/platform.service";

@Component({
  selector: "app-membership-sign-up-modal",
  templateUrl: "./membership-sign-up-modal.component.html",
  styleUrls: ["./membership-sign-up-modal.component.scss"],
})
export class MembershipSignUpModalComponent implements OnInit {
  @Input() fees: number;
  @Input() benefitsType: string;

  userCountry$: Observable<CountryInfo>;
  showEarlySalaryBenefit: boolean;
  showRealTimeTransferBenefit: boolean;
  showExtraBenefits: boolean;
  androidMembershipWebOnlyText: SafeHtml;

  constructor(
    private modalController: ModalController,
    private toasterService: ToasterService,
    private translatePipe: TranslatePipe,
    private userService: UserService,
    private androidOnboardingService: AndroidOnboardingService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private platformService: PlatformService
  ) {}

  ngOnInit() {
    this.userCountry$ = this.userService.userCountryWatch$;

    this.showEarlySalaryBenefit =
      this.benefitsType === MembershipBenefitsType.EarlySalary;

    this.showRealTimeTransferBenefit =
      this.benefitsType === MembershipBenefitsType.Realtime;

    this.showExtraBenefits =
      this.benefitsType === MembershipBenefitsType.BenefitListVariantAll;

    this.androidOnboardingService
      .getAndroidMembershipText()
      .pipe(
        take(1),
        tap((signUpText: SafeHtml) => {
          this.androidMembershipWebOnlyText = signUpText;
        }),
        switchMap((signUpText) =>
          this.androidOnboardingService
            .addDynamicLinkHandler(this.elRef, this.renderer)
            .pipe(map(() => signUpText))
        )
      )
      .subscribe();
  }

  closeModal(): void {
    this.modalController.dismiss();
  }

  signUp(): void {
    // user can only register in the web app
    if (isPlatformNative()) {
      this.toasterService.message(
        this.translatePipe.transform("MEMBERSHIP.WEB_ONLY")
      );
      return;
    }

    this.modalController.dismiss({ action: "signup" });
  }
}
