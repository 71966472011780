import { Injectable } from "@angular/core";
import { AngularFireRemoteConfig } from "@angular/fire/compat/remote-config";

import {
  FirebaseConfig,
  FirebaseConfigOriginal,
} from "@awesome-cordova-plugins/firebase-config";
import { environment } from "src/environments/environment";
import { isPlatformNative } from "./helpers";

@Injectable({
  providedIn: "root",
})
export class FirebaseRemoteConfigAdapter implements RemoteConfigInterface {
  remoteConfig: FirebaseConfigOriginal;
  isNative: boolean;

  constructor(private angularRemoteConfig: AngularFireRemoteConfig) {
    this.isNative = isPlatformNative();
  }

  async init(): Promise<void> {
    if (this.isNative) {
      this.remoteConfig = FirebaseConfig;
      await this.remoteConfig.fetch();
    } else {
      await this.angularRemoteConfig.fetch();
    }
  }

  async fetch(): Promise<void> {
    if (this.isNative) {
      await this.remoteConfig.fetch(environment.minimumFetchIntervalMillis);
    } else {
      await this.angularRemoteConfig.fetch();
    }
  }

  async activate(): Promise<void> {
    if (this.isNative) {
      await this.remoteConfig.activate();
    } else {
      await this.angularRemoteConfig.activate();
    }
  }

  async fetchAndActivate(): Promise<void> {
    if (this.isNative) {
      await this.remoteConfig.fetchAndActivate();
    } else {
      await this.angularRemoteConfig.fetchAndActivate();
    }
  }

  async getBoolean(key: string): Promise<boolean> {
    return this.isNative
      ? await this.remoteConfig.getBoolean(key)
      : await this.angularRemoteConfig.getBoolean(key);
  }

  async getString(key: string): Promise<string> {
    return this.isNative
      ? await this.remoteConfig.getString(key)
      : await this.angularRemoteConfig.getString(key);
  }

  async getNumber(key: string): Promise<number> {
    return this.isNative
      ? await this.remoteConfig.getNumber(key)
      : await this.angularRemoteConfig.getNumber(key);
  }

  async getValue(
    key: string,
    type: RemoteConfigValueType
  ): Promise<RemoteConfigValue> {
    const typeMethodMap: Record<
      RemoteConfigValueType,
      (key: string) => Promise<RemoteConfigValue>
    > = {
      boolean: this.getBoolean.bind(this),
      string: this.getString.bind(this),
      number: this.getNumber.bind(this),
    };

    const method = typeMethodMap[type];

    if (!method) {
      throw new Error(`Invalid type provided: ${type}`);
    }

    return method(key);
  }
}

export type RemoteConfigValueType = "boolean" | "string" | "number";
export type RemoteConfigValue = boolean | string | number;

interface RemoteConfigInterface {
  fetch(): Promise<void>;
  activate(): Promise<void>;
  fetchAndActivate(): Promise<void>;
  getBoolean(key: string): Promise<boolean>;
  getString(key: string): Promise<string>;
  getNumber(key: string): Promise<number>;
}
