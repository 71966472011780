// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  box-sizing: border-box;
  position: relative;
}

.collapsed {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 72px;
}

.read-more-container {
  width: 100%;
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  color: var(--ion-status-color-error, #ff0000);
  font-weight: 400;
  padding: 16px 16px 44px 16px;
}

.toggle-cont {
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -20px;
}
.toggle-cont .toggle-button {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  --border-radius: 40px;
  --background: var(--ion-color-white, #ffffff);
  --background-activated: var(--ion-color-white, #ffffff);
  --background-hover: var(--ion-color-page, #f9f9f9);
  box-shadow: 0 1px 7px rgba(170, 170, 170, 0.431372549);
  font-size: 13px;
}
.toggle-cont .toggle-button.active ion-icon {
  transform: rotate(180deg) scale(2);
}
.toggle-cont .toggle-button ion-icon {
  color: var(--ion-color-grey, #333333);
  transform: scale(2);
  font-size: 9px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/read-more/read-more.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,6CAAA;EACA,gBAAA;EACA,4BAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,aAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,mBAAA;EACA,qBAAA;EACA,6CAAA;EACA,uDAAA;EACA,kDAAA;EACA,sDAAA;EACA,eAAA;AACJ;AAEM;EACE,kCAAA;AAAR;AAII;EACE,qCAAA;EACA,mBAAA;EACA,cAAA;AAFN","sourcesContent":[":host {\n  display: block;\n  box-sizing: border-box;\n  position: relative;\n}\n\n.collapsed {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  min-height: 72px;\n}\n\n.read-more-container {\n  width: 100%;\n  font-size: 13px;\n  line-height: 18px;\n  text-align: left;\n  font-style: normal;\n  color: var(--ion-status-color-error, #ff0000);\n  font-weight: 400;\n  padding: 16px 16px 44px 16px;\n}\n\n.toggle-cont {\n  width: 100%;\n  margin: 0px auto;\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  bottom: -20px;\n\n  .toggle-button {\n    height: 40px;\n    width: 40px;\n    border-radius: 40px;\n    --border-radius: 40px;\n    --background: var(--ion-color-white, #ffffff);\n    --background-activated: var(--ion-color-white, #ffffff);\n    --background-hover: var(--ion-color-page, #f9f9f9);\n    box-shadow: 0 1px 7px #aaaaaa6e;\n    font-size: 13px;\n\n    &.active {\n      ion-icon {\n        transform: rotate(180deg) scale(2);\n      }\n    }\n\n    ion-icon {\n      color: var(--ion-color-grey, #333333);\n      transform: scale(2);\n      font-size: 9px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
