import { Component, OnInit } from "@angular/core";
import { PopoverService } from "src/app/shared/services/popover.service";

@Component({
  selector: "app-logout-confirmation",
  templateUrl: "./logout-confirmation.component.html",
  styleUrls: ["./logout-confirmation.component.scss"],
})
export class LogoutConfirmationComponent implements OnInit {
  constructor(private popoverService: PopoverService) {}

  ngOnInit() {}

  close(): void {
    this.popoverService.dismissPopover({ action: "close" });
  }

  logout(): void {
    this.popoverService.dismissPopover({ action: "logout" });
  }
}
