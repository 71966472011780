import { Component, OnInit } from "@angular/core";
import { PopoverService } from "../../services/popover.service";
import { CookieConsentService } from "../../services/cookie-consent.service";
import { Subject, catchError, from, map, of, switchMap, takeUntil } from "rxjs";
import { StorageService } from "../../services/storage.service";
import { User } from "../../models/user.model";
import { NOT_ALLOWED_TO_DELETE_ACCOUNT_STATES } from "../../consts";

@Component({
  selector: "app-header-actions",
  templateUrl: "./header-actions.component.html",
  styleUrls: ["./header-actions.component.scss"],
})
export class HeaderActionsComponent implements OnInit {
  isCookieConsentAvailable: boolean;
  showDeleteAccountOption: boolean;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private popoverService: PopoverService,
    private cookieConsentService: CookieConsentService,
    private storageService: StorageService
  ) {
    this.showDeleteAccountOption = true;
  }

  ngOnInit() {
    this.isCookieConsentAvailable =
      !!this.cookieConsentService.isCookieConsentAvailable();

    from(this.storageService.get("user"))
      .pipe(
        map((user: User | null) => {
          if (
            user &&
            NOT_ALLOWED_TO_DELETE_ACCOUNT_STATES.includes(user.userState.page)
          ) {
            this.showDeleteAccountOption = false;
          }
        }),
        catchError((error) => {
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  logout(): void {
    this.popoverService.dismissPopover({ action: "logout" });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  deleteAccount(): void {
    this.popoverService.dismissPopover({ action: "deleteAccount" });
  }

  showCookieSettings(): void {
    this.popoverService.dismissPopover({ action: "cookieConsent" });
  }
}
