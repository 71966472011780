import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-upload-progress",
  templateUrl: "./upload-progress.component.html",
  styleUrls: ["./upload-progress.component.scss"],
})
export class UploadProgressComponent implements OnInit {
  @Input() fileName: string;
  @Input() hasError: boolean;
  @Input() hideRetryButton: boolean;

  @Output()
  retryUpload = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  retry() {
    this.retryUpload.emit(true);
  }
}
