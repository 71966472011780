// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal {
  --width: 320px;
  --height: auto;
  --border-radius: 8px;
}
ion-modal ion-datetime {
  height: 420px;
  width: auto;
  --background: var(--ion-color-white, #ffffff);
  color: var(--ion-color-gray, #333333);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/custom-calendar-modal/custom-calendar-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,oBAAA;AACF;AACE;EACE,aAAA;EACA,WAAA;EAEA,6CAAA;EACA,qCAAA;AAAJ","sourcesContent":["ion-modal {\n  --width: 320px;\n  --height: auto;\n  --border-radius: 8px;\n\n  ion-datetime {\n    height: 420px;\n    width: auto;\n\n    --background: var(--ion-color-white, #ffffff);\n    color: var(--ion-color-gray, #333333);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
