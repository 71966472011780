import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-upcoming-charges-notification",
  templateUrl: "./upcoming-charges-notification.component.html",
  styleUrls: ["./upcoming-charges-notification.component.scss"],
})
export class UpcomingChargesNotificationComponent implements OnInit {
  @Input() icon: string = "assets/icon/notification-bell.svg";
  @Input() styleClass: string = "upcoming-charges-notification-white";

  @Output() showUpcomingCharges = new EventEmitter()

  constructor() {}

  ngOnInit() {}

  onClick(): void {
    this.showUpcomingCharges.emit();
  }
}
