import { Component, Input, OnInit } from "@angular/core";
import { KnackTransaction } from "../../models/knack.model";
import { CompositeTransaction } from "../../models/transaction.model";
import { AmsService } from "../../services/ams.service";

@Component({
  selector: "app-recent-transactions",
  templateUrl: "./recent-transactions.component.html",
  styleUrls: ["./recent-transactions.component.scss"],
})
export class RecentTransactionsComponent implements OnInit {
  @Input() transactions: KnackTransaction[] | CompositeTransaction[];

  showCompositeTxn: boolean = false;

  constructor(private amsService: AmsService) {}

  ngOnInit() {
    this.showCompositeTxn = this.amsService.getShowCompositeTxn();
  }

  get compositeTransactions(): CompositeTransaction[] {
    return this.transactions as CompositeTransaction[];
  }

  get knackTransactions(): KnackTransaction[] {
    return this.transactions as KnackTransaction[];
  }
}
