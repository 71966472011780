import { Component, Input, OnInit } from '@angular/core';
import { PopoverService } from 'src/app/shared/services/popover.service';

@Component({
  selector: 'app-cancel-membership-confirmation',
  templateUrl: './cancel-membership-confirmation.component.html',
  styleUrls: ['./cancel-membership-confirmation.component.scss'],
})
export class CancelMembershipConfirmationComponent implements OnInit {

  @Input() renewalDate: string;
  constructor(private popoverService: PopoverService) { }

  ngOnInit() {}

  closePopup(): void{
    this.popoverService.dismissPopover({});
  }

  confirmCancel(): void{
    this.popoverService.dismissPopover({ cancel: true});
  }
}