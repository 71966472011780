import { WHATSAPP_CHAT_LINK } from "../shared/consts";
import {
  CountryCodeEnum,
  CountryInfo,
  CountryLocale,
} from "../shared/models/country.model";

export const ID_VALIDATION_RULES = {
  phoneNumber: /^0\d{7,13}$/,
};

export const ID_COUNTRY_INFO: CountryInfo = {
  country: CountryCodeEnum.id,
  phone: "id",
  language: "id-id",
  phoneCode: "+62",
  flagIcon: "assets/countries/indonesian_flag.svg",
  validationRules: {
    phoneNumber: /^0\d{7,13}$/,
    bankAcount: /^\d{8,15}$/,
  },
  currency: {
    currencyCode: "Rp",
    digitsInfo: "1.0-0",
    currencySymbol: "Rp",
  },
  phoneNumberPlaceholder: "08128882888",
  locale: CountryLocale.id,
  contactUsLink: WHATSAPP_CHAT_LINK,
  zipCodeLength: 5,
  timeZoneOffset: "UTC+7",
  timeZone: "Asia/Jakarta",
};

export interface ID_QUESTIONS {
  formallyEmployed: boolean;
  usePartnerBankBri: boolean;
  accessToMobileBanking: boolean;
  passedProbationPeriod: boolean;
}
