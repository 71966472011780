export interface ChatConfig {
  iconSrc: string;
  headerIconSrc: string;
  altText: string;
  className: string;
  chatApp: string;
}

export enum ChatType {
  LINE = "line",
  FACEBOOK = "facebook",
  WHATSAPP = "whatsapp",
}
