import { ACCOUNT_DEACTIVATION_KEYS } from "../consts";
import { WithdrawalTimeline } from "./withdrawal.model";

export interface GetKnackUserResponse {
  success: boolean;
  data: KnackUser;
}

export interface KnackUser {
  ionicId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  nextPayday: number;
  calculatedNextPayday?: number | Date;
  withdrawBeforeDays: number;
  employer: string;
  active: boolean;
  canWithdraw: {
    value: boolean;
    error: string;
    cutoffDay?: number;
    deactivationReason?: ACCOUNT_DEACTIVATION_KEYS;
  };
  availableAmounts: number[];
  availableAmountsSum?: number;
  amountsMax?: number;
  bankName: string;
  bankAccount: string;
  cached: boolean;
  bankAccountId: string;
  nextPaydayOptions: number[];
  fastWithdrawalEnabled?: boolean;
  fastWithdrawalFee: number;
  processingFee: number;
  multipleWithdrawalEnabled?: boolean;
  daysWorked?: number;
  multipleWithdrawalTimeline?: WithdrawalTimeline[];
  withdrawableAmount?: number;
  maxAmount: number;
  amountWithdrawnForTimePeriod: number;
  prevPayday: number;
  membership: KnackMembershipDetials;
  membershipFee?: number;
  membershipEnabled?: boolean;
  chargeForMembership?: boolean;
  paydayCustomLogicName?: string;
}

export interface KnackMembershipDetials {
  membershipVariantId: string;
  accessToSalaryAdvances: FeatureAccessType;
  accessToFinancialEducation: FeatureAccessType;
  accessToSsoKnowledge: FeatureAccessType;
  accessToPartTimeJobs: FeatureAccessType;
  accessToUpskilling: FeatureAccessType;
  accessToFinancialHealthScore: FeatureAccessType;
  accessToThaivivat: FeatureAccessType;
  accessToFastbudgetpro: FeatureAccessType;
  bannerDashboardId: string;
  bannerAdvanceScreenId: string;
  popupMembershipBenefitsId: string;
}

export interface GetKnackTransactionResponse {
  success: boolean;
  data: KnackTransactionResponseData;
}

export interface KnackTransactionResponseData {
  ionicId: string;
  transactions: KnackTransaction[];
}

export interface KnackTransaction {
  dateTime: number;
  amount: number;
  tipAmount: number;
  fees: number;
  totalAmount: number;
  withdrawalSpeed: string;
  disburseStatus: string;
  paybackStatus: string;
  bankName: string;
  bankAccount: string;
  fastWithdrawalFee?: number;
  processingFee?: number;
  currencyCode?: string;
  formattedValues?: any;
  totalFees?: number;
}

export interface KnackTransactionRequest {
  ionicId: string;
  advanceAmount: number;
  fees: number;
  tipAmount: number;
  totalAmount: number;
  bankAccountId: string;
  tcAccepted: boolean;
  systemSuggestedPayday: string;
  userReportedPaydayInfo: string;
  lastSystemSuggestedPayday: string;
  fastWithdrawalEnabled?: boolean;
  fastWithdrawalFee?: number;
  processingFee?: number;
  employerChange: EmployerChange;
  isCustomTip?: boolean;
  withdrawalSpeed?: string;
  loanPurpose?: string;
  acceptedDisclosureAgreement?: boolean;
}

export interface LocalWithdrawalRequest {
  withdrawalRequestData?: KnackTransactionRequest;
  tipPercent?: number;
  variantId?: number;
}

export interface EmployerChange {
  status: string;
  newEmployer: string;
}

export enum EmployerStatus {
  StillWorkingForOldCompany,
  ChangedEmployer,
  CurrentlyUnemployed,
}

export enum KnackBannerType {
  ReferFriend = "REFER_FRIEND",
  EarlySalary = "THREE_DAYS_EARLY",
  RealTime = "REALTIME_TRANSFERS",
  InsuranceAndBudgeting = "INSURANCE_BUDGETING",
}

export enum MembershipBenefitsType {
  Standard = "PARTTIME_UPSKILL_EDUCATION",
  EarlySalary = "PARTTIME_UPSKILL_EDUCATION_3DAYSEARLY",
  Realtime = "PARTTIME_UPSKILL_EDUCATION_REALTIME_TRANSFERS",
  BenefitListVariantAll = "INSURANCE_REALTIME_TRANSFERS_BUDGETING_MORE",
}

export enum FeatureAccessType {
  NoAccess,
  MemberOnly,
  Unrestricted,
}
