import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-deletion-confirmation",
  templateUrl: "./deletion-confirmation.component.html",
  styleUrls: ["./deletion-confirmation.component.scss"],
})
export class DeletionConfirmationComponent implements OnInit {
  backgroundImage: string;

  constructor(private modalController: ModalController) {
    this.backgroundImage = "assets/icon/background-outstanding-payment.svg";
  }

  ngOnInit() {}

  closeModal(): void {
    this.modalController.dismiss();
  }

  confirmDelete(): void {
    this.modalController.dismiss({ action: "delete" });
  }
}
